import React, { Component } from 'react';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select, { createFilter } from 'react-select';
import userService from "../../services/user.services";
import { toast, ToastContainer } from 'react-toastify';
import AuthService from '../../services/auth.service';
import { Row, Button } from 'reactstrap';
import productJson from '../../api/productMasterJson';

const customStyles = {
    control: base => ({
        ...base,
        height: 48,
        minHeight: 48,
        maxMenuHeight: 200
    })
};

const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

class AddProductMaster extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fieldvalue: '',
            submitted: false,
            successful: false,
            message: "",
            brandData: [],
            optionsBrand: [],
            brandSelectedValue: '',
            subBrandData: [],
            optionsSubBrand: [],
            subBrandSelectedValue: '',
            colorData: [],
            optionsColor: [],
            colorSelectedValue: '',
            collarData: [],
            optionsCollar: [],
            collarSelectedValue: '',
            companyCodeData: [],
            optionsCompanyCode: [],
            companyCodeSelectedValue: '',
            fitsData: [],
            optionsFits: [],
            fitsSelectedValue: '',
            patternData: [],
            optionsPattern: [],
            patternSelectedValue: '',
            productGroupData: [],
            optionsProductGroup: [],
            productGroupSelectedValue: '',
            productTypeData: [],
            optionsProductType: [],
            productTypeSelectedValue: '',
            qualityData: [],
            optionsQuality: [],
            qualitySelectedValue: '',
            sleevData: [],
            optionsSleev: [],
            sleevSelectedValue: '',
            categoryData: [],
            optionsCategory: [],
            categorySelectedValue: '',

        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getBrandDropdownData() {
        userService.getBrandDropdownData().then(response =>
            this.setState({
                brandData: JSON.parse(response.data.jsonObject),
                optionsBrand: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }

    getSleevDropdownData() {
        userService.getSleevDropdownData().then(response =>
            this.setState({
                sleevData: JSON.parse(response.data.jsonObject),
                optionsSleev: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }


    getSubBrandDropdownData() {
        userService.getSubBrandDropdownData().then(response =>
            this.setState({
                subBrandData: JSON.parse(response.data.jsonObject),
                optionsSubBrand: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }

    getColorDropdownData() {
        userService.getColorDropdownData().then(response =>
            this.setState({
                colorData: JSON.parse(response.data.jsonObject),
                optionsColor: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }

    getCollarDropdownData() {
        userService.getCollarDropdownData().then(response =>
            this.setState({
                collarData: JSON.parse(response.data.jsonObject),
                optionsCollar: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }


    getCompanyCodeDropdownData() {
        userService.getCompanyCodeDropdownData().then(response =>
            this.setState({
                companyCodeData: JSON.parse(response.data.jsonObject),
                optionsCompanyCode: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }

    getFitsDropdownData() {
        userService.getFitsDropdownData().then(response =>
            this.setState({
                fitsData: JSON.parse(response.data.jsonObject),
                optionsFits: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }




    getPatternDropdownData() {
        userService.getPatternDropdownData().then(response =>
            this.setState({
                patternData: JSON.parse(response.data.jsonObject),
                optionsPattern: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }


    getProductGroupDropdownData() {
        userService.getProductGroupDropdownData().then(response =>
            this.setState({
                productGroupData: JSON.parse(response.data.jsonObject),
                optionsProductGroup: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }

    getProductTypeDropdownData() {
        userService.getProductTypeDropdownData().then(response =>
            this.setState({
                productTypeData: JSON.parse(response.data.jsonObject),
                optionsProductType: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }


    getQualityDropdownData() {
        userService.getQualityDropdownData().then(response =>
            this.setState({
                qualityData: JSON.parse(response.data.jsonObject),
                optionsQuality: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }

    getCategoryDropdownData() {
        userService.getCategoryDropdownData().then(response =>
            this.setState({
                categoryData: JSON.parse(response.data.jsonObject),
                optionsCategory: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }



    handleSubmit(event) {
        event.preventDefault();

        this.state.fieldvalue["seasonId"] = localStorage.getItem('seasonId');
        this.setState({
            message: "",
            successful: false,
            submitted: true,

        });

        if (this.state.fieldvalue.productNm) {
            userService.saveProductMast(this.state.fieldvalue).then(
                response => {
                    if (response.data['status'] === 'SUCCESS') {
                        toast.success(response.data['message']);
                        this.props.history.push('/productMaster')

                    } else if (response.data['status'] === 'Error') {
                        toast.error(response.data['message']);
                    }
                },
            ).catch(error => {
                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')
                } else {
                    console.log('error ', error)
                }
            })
        }
    }

    editProductMast(productId) {
        userService.editProductMast(productId).then(response => {
            setTimeout(() => {
                this.setState({
                    fieldvalue: response.data, sleevSelectedValue: JSON.parse((response.data.sleevJsonString)),
                    brandSelectedValue: JSON.parse((response.data.brandJsonString)), subBrandSelectedValue: JSON.parse((response.data.subBrandJsonString)),
                    categorySelectedValue: JSON.parse((response.data.categoryJsonString)), patternSelectedValue: JSON.parse((response.data.patternJsonString)),
                    fitsSelectedValue: JSON.parse((response.data.fitsJsonString)), colorSelectedValue: JSON.parse((response.data.colorJsonString)),
                    collarSelectedValue: JSON.parse((response.data.collarJsonString)), companyCodeSelectedValue: JSON.parse((response.data.companyCodeJsonString)),
                    productTypeSelectedValue: JSON.parse((response.data.productTypeJsonString)), qualitySelectedValue: JSON.parse((response.data.qualityJsonString)),
                    productGroupSelectedValue: JSON.parse((response.data.productGroupJsonString))

                })
            }, 500);
        })
    }

    componentWillMount() {
        this.getSleevDropdownData();
        this.getBrandDropdownData();
        this.getSubBrandDropdownData();
        this.getColorDropdownData();
        this.getCollarDropdownData();
        this.getCompanyCodeDropdownData();
        this.getFitsDropdownData();
        this.getPatternDropdownData();
        this.getProductGroupDropdownData();
        this.getProductTypeDropdownData();
        this.getCategoryDropdownData();
        this.getQualityDropdownData();


        if (parseInt(this.props.match.params.productId) > 0) {
            this.editProductMast(parseInt(this.props.match.params.productId));
        } else {
            this.addProductEmptyJson();
            //  this.setState({ fieldvalue: productJson["0"] });
        }

    }

    addProductEmptyJson() {
        userService.addProductEmptyJson(localStorage.getItem('seasonId')).then(response =>
            this.setState({
                fieldvalue: response.data
            })
        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {

                console.log('error ', error)
            }

        })
    }

    getProductDetails(productNm, seasonId) {
        userService.getProductDetails(productNm, seasonId).then(response => {
            if (response.data.productId != null) {
                this.editProductMast(response.data.productId);
            } else {
                this.setState({
                    fieldvalue: response.data, sleevSelectedValue: '', brandSelectedValue: '', subBrandSelectedValue: '',
                    categorySelectedValue: '', patternSelectedValue: '', fitsSelectedValue: '', colorSelectedValue: '',
                    collarSelectedValue: '', companyCodeSelectedValue: '', productTypeSelectedValue: '', qualitySelectedValue: '',
                    productGroupSelectedValue: ''

                })
            }
        }

        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {

                console.log('error ', error)
            }

        })
    }

    handleChangeEvent(field, e) {
        let fieldvalue = this.state.fieldvalue;
        fieldvalue[field] = e.target.value;

        if (field == 'productNm') {
            //  let splitString = e.target.value.trim().split(/\s+/);

            fieldvalue['shortProductNm'] = e.target.value.substr(0, e.target.value.length - 1).trim();
            fieldvalue['sleev'] = e.target.value.charAt(e.target.value.length - 1).trim();

            this.getProductDetails(e.target.value, localStorage.getItem('seasonId'));

        }
        this.setState({ fieldvalue });
    }

    getAllDropdownFunc(value, field) {
        let fieldvalue = this.state.fieldvalue;
        fieldvalue[field] = value.value;

        if (field == 'sleev') {
            this.setState({ fieldvalue, sleevSelectedValue: value });
        } else if (field == 'brand') {
            this.setState({ fieldvalue, brandSelectedValue: value });
        } else if (field == 'subBrand') {
            this.setState({ fieldvalue, subBrandSelectedValue: value });
        } else if (field == 'category') {
            this.setState({ fieldvalue, categorySelectedValue: value });
        } else if (field == 'pattern') {
            this.setState({ fieldvalue, patternSelectedValue: value });
        } else if (field == 'fits') {
            this.setState({ fieldvalue, fitsSelectedValue: value });
        } else if (field == 'color') {
            this.setState({ fieldvalue, colorSelectedValue: value });
        } else if (field == 'collar') {
            this.setState({ fieldvalue, collarSelectedValue: value });
        } else if (field == 'companyCode') {
            this.setState({ fieldvalue, companyCodeSelectedValue: value });
        } else if (field == 'productType') {
            this.setState({ fieldvalue, productTypeSelectedValue: value });
        } else if (field == 'quality') {
            this.setState({ fieldvalue, qualitySelectedValue: value });
        } else if (field == 'productGroup') {
            this.setState({ fieldvalue, productGroupSelectedValue: value });
        }



    }

    productListing() {
        this.props.history.push('/productMaster')
    }


    render() {

        const { submitted, fieldvalue, sleevSelectedValue, brandSelectedValue, subBrandSelectedValue, categorySelectedValue,
            colorSelectedValue, collarSelectedValue, patternSelectedValue, fitsSelectedValue, companyCodeSelectedValue,
            productTypeSelectedValue, qualitySelectedValue, productGroupSelectedValue } = this.state;
        return (
            <div className="container">
                <ToastContainer autoClose={1500} draggable={false} />
                <div className="col-md-12 ">
                    <Row style={{ paddingTop: "10px" }}>
                        <div className="col-md-11">
                            <h3 style={{ "text-align": "left", color: "navy" }}> Product </h3>
                        </div>
                        <div className="col-md-1">
                            <Button outline color="secondary" onClick={() => this.productListing()}>Listing</Button>
                        </div>
                    </Row>

                    <Form onSubmit={this.handleSubmit}>
                        <div className="row">

                            <div className={'form-group col-md-3 ' + (submitted && !fieldvalue.productNm ? ' has-error' : '')}>
                                <label style={{ fontWeight: "bold" }}>Product <span style={{ color: "red" }}>*</span> </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Product Name"
                                    value={this.state.fieldvalue.productNm}
                                    onBlur={this.handleChangeEvent.bind(this, "productNm")}
                                    autoComplete="off"
                                    validations={[required]} />

                                {submitted && !fieldvalue.productNm &&
                                    <div className="alert alert-danger" role="alert">
                                        This field is required!
                                    </div>
                                }
                            </div>

                            <div className="form-group col-md-3">
                                <label style={{ fontWeight: "bold" }}>Product Group </label>
                                <Select name="productGroup" styles={customStyles}
                                    defaultValue="Select Product Group"
                                    options={this.state.optionsProductGroup}
                                    value={productGroupSelectedValue}
                                    onChange={(e) => this.getAllDropdownFunc(e, 'productGroup')}
                                />
                            </div>

                            <div className={'form-group col-md-3 '}>
                                <label style={{ fontWeight: "bold" }}>Short Product </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Short Product Name"
                                    value={this.state.fieldvalue.shortProductNm}
                                    autoComplete="off"
                                    disabled
                                />
                            </div>

                            <div className={'form-group col-md-3' + (submitted && !fieldvalue.sleev ? ' has-error' : '')}>
                                <label style={{ fontWeight: "bold" }}>Sleev<span style={{ color: "red" }}>*</span></label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Sleev"
                                    value={this.state.fieldvalue.sleev}
                                    autoComplete="off"
                                    disabled
                                />
                                {submitted && !fieldvalue.sleev &&
                                    <div className="alert alert-danger" role="alert">
                                        This field is required!
                                    </div>
                                }

                            </div>



                            <div className={'form-group col-md-3 '}>
                                <label style={{ fontWeight: "bold" }}>Brand </label>
                                <Select name="brand" styles={customStyles}
                                    defaultValue="Select Brand"
                                    options={this.state.optionsBrand}
                                    value={brandSelectedValue}
                                    onChange={(e) => this.getAllDropdownFunc(e, 'brand')}
                                />
                                {submitted && !fieldvalue.brand &&
                                    <div className="alert alert-danger" role="alert">
                                        This field is required!
                                    </div>
                                }

                            </div>

                            <div className={'form-group col-md-3 '}>
                                <label style={{ fontWeight: "bold" }}>SubBrand </label>
                                <Select name="subBrand" styles={customStyles}
                                    defaultValue="Select SubBrand"
                                    options={this.state.optionsSubBrand}
                                    value={subBrandSelectedValue}
                                    onChange={(e) => this.getAllDropdownFunc(e, 'subBrand')}
                                />
                                {submitted && !fieldvalue.subBrand &&
                                    <div className="alert alert-danger" role="alert">
                                        This field is required!
                                    </div>
                                }

                            </div>

                            <div className={'form-group col-md-3 '}>
                                <label style={{ fontWeight: "bold" }}>Category </label>
                                <Select name="category" styles={customStyles}
                                    defaultValue="Select Category"
                                    options={this.state.optionsCategory}
                                    value={categorySelectedValue}
                                    onChange={(e) => this.getAllDropdownFunc(e, 'category')}
                                />
                                {submitted && !fieldvalue.category &&
                                    <div className="alert alert-danger" role="alert">
                                        This field is required!
                                    </div>
                                }
                            </div>

                            <div className={'form-group col-md-3 '}>
                                <label style={{ fontWeight: "bold" }}>Pattern </label>
                                <Select name="pattern" styles={customStyles}
                                    defaultValue="Select Pattern"
                                    options={this.state.optionsPattern}
                                    value={patternSelectedValue}
                                    onChange={(e) => this.getAllDropdownFunc(e, 'pattern')}
                                />
                            </div>

                            <div className={'form-group col-md-3 '}>
                                <label style={{ fontWeight: "bold" }}>Fits </label>
                                <Select name="fits" styles={customStyles}
                                    defaultValue="Select Fits"
                                    options={this.state.optionsFits}
                                    value={fitsSelectedValue}
                                    onChange={(e) => this.getAllDropdownFunc(e, 'fits')}
                                />
                                {submitted && !fieldvalue.fits &&
                                    <div className="alert alert-danger" role="alert">
                                        This field is required!
                                    </div>
                                }
                            </div>

                            <div className={'form-group col-md-3 '}>
                                <label style={{ fontWeight: "bold" }}>Color </label>
                                <Select name="color" styles={customStyles}
                                    defaultValue="Select Color"
                                    options={this.state.optionsColor}
                                    value={colorSelectedValue}
                                    onChange={(e) => this.getAllDropdownFunc(e, 'color')}
                                />
                            </div>

                            <div className={'form-group col-md-3 '}>
                                <label style={{ fontWeight: "bold" }}>Collar </label>
                                <Select name="collar" styles={customStyles}
                                    defaultValue="Select Collar"
                                    options={this.state.optionsCollar}
                                    value={collarSelectedValue}
                                    onChange={(e) => this.getAllDropdownFunc(e, 'collar')}
                                />
                            </div>

                            <div className={'form-group col-md-3 '}>
                                <label style={{ fontWeight: "bold" }}>Company </label>
                                <Select name="companyCode" styles={customStyles}
                                    defaultValue="Select Company"
                                    options={this.state.optionsCompanyCode}
                                    value={companyCodeSelectedValue}
                                    onChange={(e) => this.getAllDropdownFunc(e, 'companyCode')}
                                />
                                {submitted && !fieldvalue.companyCode &&
                                    <div className="alert alert-danger" role="alert">
                                        This field is required!
                                    </div>
                                }
                            </div>

                            <div className={'form-group col-md-3 '}>
                                <label style={{ fontWeight: "bold" }}>ProductType </label>
                                <Select name="productType" styles={customStyles}
                                    defaultValue="Select ProductType"
                                    options={this.state.optionsProductType}
                                    value={productTypeSelectedValue}
                                    onChange={(e) => this.getAllDropdownFunc(e, 'productType')}
                                />
                                {submitted && !fieldvalue.productType &&
                                    <div className="alert alert-danger" role="alert">
                                        This field is required!
                                    </div>
                                }
                            </div>

                            <div className={'form-group col-md-3 '}>
                                <label style={{ fontWeight: "bold" }}>Fabric </label>
                                <Select name="quality" styles={customStyles}
                                    defaultValue="Select Quality"
                                    options={this.state.optionsQuality}
                                    value={qualitySelectedValue}
                                    onChange={(e) => this.getAllDropdownFunc(e, 'quality')}
                                />
                            </div>

                            <div className="form-group col-md-3">
                                <label style={{ fontWeight: "bold" }}>Available Sizes </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Available Sizes"
                                    value={this.state.fieldvalue.availableSizes}
                                    onChange={this.handleChangeEvent.bind(this, "availableSizes")}
                                    autoComplete="off" />
                                {submitted && !fieldvalue.availableSizes &&
                                    <div className="alert alert-danger" role="alert">
                                        This field is required!
                                    </div>
                                }
                            </div>

                            <div className="form-group col-md-3">
                                <label style={{ fontWeight: "bold" }}>MRP </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="MRP"
                                    value={this.state.fieldvalue.mrp}
                                    onChange={this.handleChangeEvent.bind(this, "mrp")}
                                    autoComplete="off" />
                            </div>


                            <div className="form-group col-md-3">
                                <label style={{ fontWeight: "bold" }}>Cost </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Cost"
                                    value={this.state.fieldvalue.cost}
                                    onChange={this.handleChangeEvent.bind(this, "cost")}
                                    autoComplete="off" />
                            </div>




                            <div className="form-group col-md-3">
                                <label style={{ fontWeight: "bold" }}>Parent Product </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Parent Product"
                                    value={this.state.fieldvalue.parentProductNm}
                                    onChange={this.handleChangeEvent.bind(this, "parentProductNm")}
                                    autoComplete="off" />
                            </div>

                            <div className="form-group col-md-3">
                                <label style={{ fontWeight: "bold" }}>Supplier Name </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Parent Product"
                                    value={this.state.fieldvalue.suppierName}
                                    onChange={this.handleChangeEvent.bind(this, "suppierName")}
                                    autoComplete="off" />
                            </div>

                            <div className="form-group col-md-3">
                                <label style={{ fontWeight: "bold" }}>Balance Po Qty</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Parent Product"
                                    value={this.state.fieldvalue.balancePoQty}
                                    onChange={this.handleChangeEvent.bind(this, "balancePoQty")}
                                    autoComplete="off" />
                            </div>


                        </div>

                        <div className='row'>
                            <div className="form-group col-md-6">
                                <button className="btn btn-outline-success">Save</button>
                            </div>
                        </div>

                    </Form>



                </div>

            </div>
        )
    }
}

export default AddProductMaster;