import DataGrid, { Column, Editing, Export, GroupItem, GroupPanel, Grouping, Pager, Paging, SearchPanel, Selection, Summary } from 'devextreme-react/data-grid';
import React, { Component } from 'react'
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { exportDataGrid as exportDataGridPdf } from 'devextreme/pdf_exporter';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';
import { jsPDF } from 'jspdf';
import userServices from '../../services/user.services';
import AuthService from '../../services/auth.service';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom/cjs/react-router-dom';


const exportFormats = ['pdf', 'Excel'];

 class ProductWiseOrderStatus extends Component {
    _exporter;
    constructor(props){
        super(props);
        this.onExporting = this.onExporting.bind(this);
        this.deleteSelected = this.deleteSelected.bind(this);
        this.togglePleaseWait = this.togglePleaseWait.bind(this);
        this.toggle = this.toggle.bind(this);
        this.getProductWiseOrderQtyAndStockQty = this.getProductWiseOrderQtyAndStockQty.bind(this);

        this.state = {
            pageSizes: [10, 25, 50, 100],
            modalPleaseWait: false,
            listingData: [],
            selectedData: [],
            accessOrderProduct: false,
            modal: false
        }
    }

    togglePleaseWait() {
        this.setState(prevState => ({
            modalPleaseWait: !prevState.modalPleaseWait
        }));
    }

    toggle() {
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
      }
    
    getProductWiseOrderQtyAndStockQty(state=null) {
        this.togglePleaseWait();
        userServices.getProductWiseOrderQtyAndStockQty(localStorage.getItem('seasonId'), state !== null ? state : this.state.accessOrderProduct).then(
            response => {

                if (response.data['status'] === 'SUCCESS') {
                    this.setState({ listingData: JSON.parse(response.data['jsonObject']) })
                } else if (response.data['status'] === 'Error') {
                    toast.error(response.data['message']);
                }
                this.togglePleaseWait();
            },

        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }

    componentDidMount() {
        this.getProductWiseOrderQtyAndStockQty()
	}

    onExporting(e) {

        if (e.format == "Excel") {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Main sheet');

            exportDataGrid({
                component: e.component,
                worksheet,
                autoFilterEnabled: true,
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
                });
            });
            e.cancel = true;
        } else {
            const doc = new jsPDF();

            exportDataGridPdf({
                jsPDFDocument: doc,
                component: e.component,
                indent: 5,
            }).then(() => {
                doc.save('Preview.pdf');
            });
        }


    }
    
    onSelectionChange(data){
        this.setState({selectedData: data.selectedRowsData});
    }

    deleteSelected() {
        if(this.state.selectedData.length > 0){
            this.togglePleaseWait();
            const newdata = []
            this.state.selectedData.map(item => {
                newdata.push({productSeasonId: item.productSeasonId})
            })
            userServices.deleteProductSeason({"productSeasonArr":newdata}).then(
                response => {
    
                    if (response.data['status'] === 'SUCCESS') {
                        toast.success('Product deleted successfully');
                        const filteredDt = this.state.listingData.filter(item1 => !this.state.selectedData.some(item2 => item1.productId === item2.productId));
                        this.setState({listingData: filteredDt, selectedData: []})
                    } else if (response.data['status'] === 'Error') {
                        toast.error(response.data['message']);
                    }
                    this.togglePleaseWait();
                    this.toggle()
                },
    
            ).catch(error => {
                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')
                } else {
                    console.log('error ', error)
                }
            })
        }
    }

    onAllowAccessQtyChange(data) {
        const dt = data[0]
        if(data.length > 0 && dt.data.allowAccessQty !== dt.key.allowAccessQty){
            userServices.updateAllowAccessQty(dt.key.productSeasonId, dt.data.allowAccessQty).then(
                response => {
    
                    if (response.data['status'] === 'SUCCESS') {
                        console.log(response.data);
                    } else if (response.data['status'] === 'Error') {
                        toast.error(response.data['message']);
                    }
                },
    
            ).catch(error => {
                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')
                } else {
                    console.log('error ', error)
                }
            })
        }
    }


  render() {
    return (
        <Container style={{ border: "0.10px solid", color: 'grey', marginTop: '10px', minHeight: "80vh" }}>
        <Row style={{ paddingTop: "10px" }}>
            <Col md={10}>
                <h3 style={{ "text-align": "left", color: "navy" }}>Product Wise Order Status</h3>
            </Col>
        </Row>
        <Row style={{ padding: "5px" }}>
            <Col className="d-flex justify-content-end align-items-center p-0" md={2}>
                <label className="m-0" style={{ fontWeight: "bold" }}>Exceed Order Product </label>
                <input onClick={(e) =>  {
                    this.setState({
                    accessOrderProduct: e.target.checked
                })
                this.getProductWiseOrderQtyAndStockQty(e.target.checked)
                }} className='ml-1' type='checkbox' />
            </Col>
            <Col className="d-flex ml-3" md={1}>
            <Button onClick={this.toggle} color="danger">Deactivate</Button>
            </Col>
        </Row>
        <Row>
            <Col xl={10}></Col>
            <Col xl={12}>
                <DataGrid
                    id="gridContainer"
                    dataSource={this.state.listingData}
                    showBorders={true}
                    allowColumnReordering={true}
                    onExporting={this.onExporting}
                    allowColumnResizing={true}
                    columnResizingMode='widget'
                    columnMinWidth={50}
                    columnAutoWidth={true}
                    onSelectionChanged={(data) => this.onSelectionChange(data)}
                >
                    <GroupPanel visible={true} />
                    <Selection mode="multiple" showCheckBoxesMode='always' />
                    <SearchPanel visible={true} highlightCaseSensitive={true} />
                    <Grouping autoExpandAll={true} />
                    <Editing
                    mode="cell"
                    allowUpdating={true}
                    onChangesChange={(e) => this.onAllowAccessQtyChange(e)}
                    />
                    <Column allowEditing={false} dataField="productNm" dataType="string" />
                    <Column allowEditing={false} dataField="totOrderQty" dataType="string" />
                    <Column allowEditing={false} dataField="totStockQty" dataType="string" />
                    <Column dataField="allowAccessQty" dataType="string" />
                    <Pager allowedPageSizes={this.state.pageSizes} showPageSizeSelector={true} />
                    <Paging defaultPageSize={10} />
                    <Export enabled={true} formats={exportFormats} allowExportSelectedData={true} />

                </DataGrid>
            </Col>
        </Row>

        <Modal isOpen={this.state.modalPleaseWait} toggle={this.togglePleaseWait} className="modal-login modal-dialog-centered">
            <ModalHeader toggle={this.togglePleaseWait}>
            </ModalHeader>
            <ModalBody>
                <h1 className="mb-0">Please Wait...</h1>
            </ModalBody>
        </Modal>

        <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-delete modal-lg modal-dialog-centered">
        <ModalHeader toggle={this.toggle}></ModalHeader>
            <ModalBody>
                do you want deactive product for this season
            </ModalBody>
            <ModalFooter className="justify-content-center pt-4">
            <Link className="action-button" onClick={()=>this.deleteSelected()}>Yes</Link>
            <Link className="action-button no"  onClick={this.toggle} >No</Link>
            </ModalFooter>
        </Modal>
        </Container>
    )
  }
}

export default ProductWiseOrderStatus;