import React, { Component } from 'react';
import userService from "../../services/user.services";
import { toast, ToastContainer } from 'react-toastify';
import AuthService from '../../services/auth.service';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import seasonJson from '../../api/seasonJson';
import { Button, Row } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";


const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};



class AddSeasonUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fieldvalue: "",
            submitted: false,
            successful: false,
            message: "",
        }

        this.handleSubmit = this.handleSubmit.bind(this);

    }



    seasonUserListing() {
        this.props.history.push('/seasonUserRights')
    }

    handleChangeEvent(field, e) {
        let fieldvalue = this.state.fieldvalue;
        fieldvalue[field] = e.target.value;
        this.setState({ fieldvalue });
    }

    componentWillMount() {
        if (parseInt(this.props.match.params.seasonId) > 0) {
            this.editSeason(parseInt(this.props.match.params.seasonId));
        } else {
            this.setState({
                fieldvalue: seasonJson["0"]
            })
        }
    }

    

    editSeason(seasonId) {
        userService.editSeason(seasonId).then(response => {
            setTimeout(() => {
                this.setState({
                    fieldvalue: response.data


                    
                })
            }, 500);
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            submitted: true
        });

        userService.addSeason(this.state.fieldvalue).then(
            response => {
                if (response.data['status'] === 'SUCCESS') {
                    toast.success(response.data['message']);
                    this.props.history.push('/seasonMast')
                } else if (response.data['status'] === 'Error') {
                    toast.error(response.data['message']);
                }
            },

        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {
                console.log('error ', error)
            }
        })


    }

    render() {

        const { submitted, fieldvalue } = this.state;

        return (
            <div className="container">
                <ToastContainer autoClose={1500} draggable={false} />
                <div className="col-md-12 ">
                    <Row style={{ paddingTop: "10px" }}>
                        <div className="col-md-11">
                            <h3 style={{ "text-align": "left", color: "navy" }}> Season User Rights </h3>
                        </div>
                        <div className="col-md-1">
                            <Button outline color="secondary" onClick={() => this.seasonUserListing()}>Listing</Button>
                        </div>
                    </Row>

                    <Form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className={'form-group col-md-6 ' + (submitted && !fieldvalue.season ? ' has-error' : '')}>
                                <label style={{ fontWeight: "bold" }}>Name <span style={{ color: "red" }}>*</span> </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Season Name"
                                    value={this.state.fieldvalue.seasonName}
                                    onChange={this.handleChangeEvent.bind(this, "seasonName")}
                                    autoComplete="off"
                                    validations={[required]} />

                                {submitted && !fieldvalue.seasonName &&
                                    <div className="alert alert-danger" role="alert">
                                        This field is required!
                                    </div>
                                }
                            </div>
                        </div>






                        <div className="row">
                            <div className="form-group col-md-12">

                                <button className="btn btn-outline-success" >Save</button>

                            </div>
                        </div>

                    </Form>


                </div >

            </div >
        )
    }

}

export default AddSeasonUser;
