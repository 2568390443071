import React, { Component } from 'react';


import { Col, Container, Row, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import userService from "../../services/user.services";
import AuthService from '../../services/auth.service';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { matchSorter } from 'match-sorter';

class ProductMaster extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productList: [],
            productJsonData: null,
            disabledButton: true,
            searchProduct: '',
            productArrays: [],
            productnm: '',
            brand: '',
            mrp: '',
            productNewList: [],
            stateNewList: [],
            subBrandNewList: [],
            brandNewList: [],
            modal: false,
            subBrand: ''
        };
        this.toggle = this.toggle.bind(this);

    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }


    getProductList() {

        userService.getProductDataList(localStorage.getItem('seasonId'), localStorage.getItem('bookingAtStudio')).then(response =>
            this.setState({
                productList: JSON.parse(response.data['jsonObject']),
                productArrays: JSON.parse(response.data['jsonObject'])
            }),
        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {

                console.log('error ', error)
            }

        })

    }

    componentWillMount() {
        this.getProductList();
    }


    editParty(data) {
        this.toggle();
    }


    deleteProductMast(e, productId, statusFlg) {
        userService.deleteProductMast(productId, statusFlg).then(response => {
            if (response.data['status'] == 'SUCCESS') {
                this.getProductList();
                toast.success(response.data['message'])
            }
        }).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ');
                AuthService.logout();
                this.props.history.push('/');
            } else {
                console.log('error ', error)
            }

        })
    }


    productExcelUpload(e) {

        this.toggle();
        let files = e.target.files[0];

        let formData = new FormData();
        formData.append('file', files);
        formData.append('seasonId', localStorage.getItem('seasonId'));


        setTimeout(() => {
            userService.uploadProductExcelFile(formData)
                .then(response => {
                    if (response.data['status'] === 'SUCCESS') {
                        this.toggle();
                        // toast.success("Product Updated Successfully");
                    //    this.addProductInProductSeason();
                    } else {
                        toast.warn(response.data['message']);
                        this.toggle();
                    }
                    //     this.toggle();
                }
                ).catch(error => {

                    if (error.response.status === 403) {
                        alert('Your Login Session Is Expire ,Please Login ')
                        AuthService.logout()
                        this.props.history.push('/')

                    } else {

                        console.log('error ', error)
                    }

                })
        }, 30);

    }

    addProductInProductSeason() {
        userService.addProductInProductSeason(localStorage.getItem('seasonId'))
            .then(response => {
                if (response.data['status'] === 'SUCCESS') {
                    toast.success("Product Updated Successfully");

                } else {
                    toast.warn(response.data['message']);
                }
                this.toggle();
            }
            ).catch(error => {

                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')

                } else {
                    this.toggle();
                    alert('Error occured, please contact support');
                    console.log('error ', error)
                }

            })
    }

    addProductMaster() {
        this.props.history.push('/addProduct');
    }



    render() {

        const columns = [

            {
                width: 150,
                sortable: false,
                Header: 'Product Name',
                accessor: 'productNm',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["productNm"] }),
                filterAll: true,
            },
            {
                width: 150,
                Header: 'Product Group',
                accessor: 'productGroup',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["productGroup"] }),
                filterAll: true,
            },
            {
                width: 150,
                Header: 'Brand',
                accessor: 'brand',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["brand"] }),
                filterAll: true,
            },
            {
                width: 100,
                Header: 'SubBrand',
                accessor: 'subBrand',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["subBrand"] }),
                filterAll: true,
            },
            {
                Header: 'Category',
                accessor: 'category',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["category"] }),
                filterAll: true,
            },
            {
                Header: 'Pattern',
                accessor: 'pattern',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["pattern"] }),
                filterAll: true,
            },
            {
                Header: 'Fits',
                accessor: 'fits',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["fits"] }),
                filterAll: true,
            },

            {
                width: 150,
                Header: 'Color',
                accessor: 'color',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["color"] }),
                filterAll: true,
            },
            {
                Header: 'Collar',
                accessor: 'collar',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["collar"] }),
                filterAll: true,
            },
            {
                width: 200,
                Header: 'Available Sizes',
                accessor: 'availablesizes'
            },
            {
                width: 150,
                Header: 'Fabric',
                accessor: 'quality',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["quality"] }),
                filterAll: true,
            },
            {
                Header: 'MRP',
                accessor: 'mrp',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["mrp"] }),
                filterAll: true,
            },
            {
                width: 200,
                Header: 'CompanyCode',
                accessor: 'companyCode',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["companyCode"] }),
                filterAll: true,
            },

            // {
            //     Header: 'Status',
            //     accessor: 'status',
            //     Cell: props => {
            //         return (
            //             <div style={{ paddingLeft: "10px" }}>
            //                 <Link>{props.original.status ? 'Deactive' : 'Active'}</Link>
            //             </div>
            //         )
            //     },
            // },

            {
                Header: 'Edit',
                accessor: 'action1',
                Cell: props => {
                    return (
                        <div style={{ paddingLeft: "10px" }}>
                            <Link className="btn btn-outline-info" to={`/editProduct/${props.original.productId}`} href="#">
                                <i class="fa fa-edit"></i></Link>
                        </div>
                    )
                },
            },

            {
                Header: 'Action',
                accessor: 'action2',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <Button style={{ width: "80px" }} outline color={props.original.statusFlg ? "danger" : "info"} onClick={(e) => this.deleteProductMast(e, props.original.productId, props.original.statusFlg)}
                                href="#">{props.original.statusFlg ? 'Deactive' : 'Active'}</Button>
                        </div>
                    )
                },
            }


        ]

        return (
            <div className='container'>
                <ToastContainer draggable={false} />

                <div className="section-ptb" style={{ padding: "10px" }}>
                    <Container>

                        <Tabs>
                            <TabList>
                                <Tab>Product List</Tab>
                                <Tab>Product Excel Upload</Tab>
                            </TabList>

                            <TabPanel>

                                <Row>
                                    <Col lg={12}>


                                        <Row>

                                            <Col lg={2}>
                                                <Button outline color="secondary" onClick={() => this.addProductMaster()}><i class="fa fa-plus"></i> Add</Button>
                                            </Col>
                                        </Row>
                                        <br></br>

                                        <ReactTable id="table-to-xls" className="orderList-table"
                                            data={this.state.productList}
                                            columns={columns}
                                            filterable defaultFilterMethod={(filter, row) =>
                                                String(row[filter.id]) === filter.value}
                                            minRows={1}
                                            defaultPageSize={10}
                                            pageSizeOptions={[10, 25, 50, 100, 150, 250, 500]}
                                        />

                                    </Col>
                                </Row>

                            </TabPanel>

                            <TabPanel>
                                <div class="tab-pane fade show active" id="transaction-list" role="tabpanel" aria-labelledby="transaction-list-tab">
                                    <h1>Excel Upload</h1>
                                    <div>
                                        <div onSubmit={this.onFormSubmit}>
                                            <input type="file" name="file" onChange={(e) => this.productExcelUpload(e)} />
                                        </div>
                                    </div>

                                </div>

                            </TabPanel>

                            <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-login modal-dialog-centered">
                                <ModalHeader toggle={this.toggle}>
                                </ModalHeader>
                                <ModalBody>

                                    <h1 className="mb-0">Please Wait...</h1>

                                </ModalBody>
                            </Modal>

                        </Tabs>


                    </Container>
                </div>
            </div>
        )
    }

}
export default ProductMaster;