import React, { Component } from "react";
import { Link } from 'react-router-dom';

import AuthService from "../services/auth.service";



export default class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    // reset login status

    this.state = {
      username: '',
      password: '',
      submitted: false,
      loading: false,
      message: '',
      companyAdmin: false

    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true,
      submitted: true
    });

    if (this.state.username, this.state.password) {

      AuthService.login(this.state.username, this.state.password).then(
        (data) => {
          if (data.accessToken && data.id != null) {

            this.props.history.push({ pathname: "/home", state: { currentUser: this.state.username } });
            window.location.reload();

          } else {
            //alert('User Is Not Register');
            this.setState({
              loading: false,
              message: 'User already registered. Activation Pending.'
            });
          }

        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.setState({
            loading: false,
            message: resMessage
          });
        }
      ).catch(error => {

      })

    }
  }

  render() {



    const { loggingIn } = this.props;
    const { username, password, submitted } = this.state;
    return (


      <div className="card" style={{ paddingTop: "70px", paddingBottom: "70px" }}>
        <div className="col-md-12">
          <h3 style={{ "text-align": "center", "fontWeight": "bold" }}>  Login </h3>
          <div className="col-md-4 offset-md-4">
            <form name="form" onSubmit={this.handleSubmit} >
              <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                <label htmlFor="username">Username</label>
                <input type="text" className="form-control" name="username" value={username}
                  onChange={this.handleChange} autoComplete="off" />
                {submitted && !username &&
                  <div className="alert alert-danger" role="alert">
                    This field is required!
                  </div>
                }
              </div>
              <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                <label htmlFor="password">Password</label>
                <input type="password" className="form-control" name="password" value={password}
                  onChange={this.handleChange}

                />
                {submitted && !password &&
                  <div className="alert alert-danger" role="alert">
                    This field is required!
                  </div>
                }
              </div>
              <div className="form-group">
                <button className="btn btn-primary">Login</button>
                {loggingIn &&
                  <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                }
                {/* <Link to="/register" className="btn btn-link">Register</Link> */}
              </div>

              {this.state.message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {this.state.message}
                  </div>
                </div>
              )}

            </form>
          </div>
        </div>



      </div>
    );
  }
}

