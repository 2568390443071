import React, { Component } from 'react'
import { Col, Container, Row, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

export default class OrderStatusReport extends Component {

    constructor(props) {

        super(props);

        this.state = {

        };
        this.oustandingReport = this.oustandingReport.bind(this);
        this.orderStatusReport = this.orderStatusReport.bind(this);
        this.orderRptStatusReport = this.orderRptStatusReport.bind(this);
        this.orderWpRptStatusReport = this.orderWpRptStatusReport.bind(this);
    }

    orderStatusReport() {
        this.props.getOrderStatusReport("partyOrderStatusReports");
    }

    oustandingReport() {
        this.props.getOrderStatusReport("partyDetailsReports");
    }

    orderRptStatusReport() {
        this.props.getOrderStatusReport("orderReport");
    }

    orderWpRptStatusReport() {
        this.props.getOrderStatusReport("orderReport");
    }

    render() {

        return (
            <Container>
                <br />


                <Row>
                    <Col md={3}>
                        <Button outline color="success" onClick={this.orderStatusReport}>Order Status Report</Button>
                    </Col>
                    <Col md={3}>
                        <Button outline color="success" onClick={this.oustandingReport}>Outstanding Report</Button>
                    </Col>
                    <Col md={3}>
                        <Button outline color="success" onClick={this.orderRptStatusReport}>Order Form Report</Button>
                    </Col>
                    <Col md={3}>
                        <Button outline color="success" onClick={this.orderWpRptStatusReport}>Whatsapp Order Form Report</Button>
                    </Col>
                </Row>

                <br />
            </Container>
        )
    }
}
