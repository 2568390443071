import axios from "axios";
import authHeader from './auth-header';

 // const API_URL = "http://localhost:8080/api/auth/";
  const API_URL = 'http://bookyoursero.com/api/auth/';

class AuthService {
  login(username, password, seasonId, bookingStudioFlg) {
    return axios
      .post(API_URL + "signin", {
        username,
        password,
        seasonId,
        bookingStudioFlg
      })
      .then(response => {
        if (response.data.accessToken && response.data.id != null) {
          localStorage.setItem("user", JSON.stringify(response.data));
          localStorage.setItem("seasonId", JSON.stringify(response.data.seasonId));
          localStorage.setItem("bookingAtStudio", JSON.stringify(response.data.isBookingAtStudio));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("seasonId");
    // localStorage.removeItem("isAdmin");
    // localStorage.removeItem("isCompanyAdmin");
    localStorage.clear();


  }


  register(userName,
    email,
    password,
    companyName,
    gstn,
    firstName,
    lastName,
    mobileNo,
    addressOne,
    addressTwo,
    addressThree,
    pinCode,
    city,
    stateNm,
    country
  ) {


    return axios.post(API_URL + "signup", {
      userName,
      email,
      password,
      companyName,
      gstn,
      firstName,
      lastName,
      mobileNo,
      addressOne,
      addressTwo,
      addressThree,
      pinCode,
      city,
      stateNm,
      country
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }

  // getAdmin() {
  //   return JSON.parse(localStorage.getItem('isAdmin'));;
  // }

  // getCompanyAdmin() {
  //   return JSON.parse(localStorage.getItem('isCompanyAdmin'));;
  // }

  changePassword(password, userId) {

    const userParam = {
      headers: authHeader(),
    }

    return axios.post(API_URL + "changePassword", { password: password, userId: userId }, userParam)
  }


  createUser(userName, password) {


    const userParam = {
      headers: authHeader(),
    }

    return axios.post(API_URL + "createUser", { userName: userName, password: password }, userParam)
  }

  forgotPassword(username) {

    return axios.post(API_URL + "forgotPassword", { username: username }, {})

  }

  registrationMail(companyId) {

    const compConfig = {
      headers: authHeader(),
      params: {
        companyId: companyId
      },
    }

    return axios.get(API_URL + 'registrationMail', compConfig);
  }


  menuListByRole() {
    const compConfig = {
      headers: authHeader()
    }
    return axios.get(API_URL + 'menuListByRole', compConfig);

  }

  getActiveSeasonDropdownData() {
    const compConfig = {
      headers: authHeader()
    }
    return axios.get(API_URL + 'getActiveSeasonDropdownData', compConfig);

  }

  getuserWiseSeasonData(userName) {


    const compConfig = {
      headers: authHeader(),
      params: {
        userName: userName
      },
    }
    return axios.get(API_URL + 'getUserWiseSeasonData', compConfig);



  }


  checkBookingAtStudio(seasonId, userName) {
    const compConfig = {
      headers: authHeader(),
      params: {
        seasonId: seasonId,
        userName: userName
      },
    }
    return axios.get(API_URL + 'checkBookingAtStudio', compConfig);

  }

}

export default new AuthService();
