import React, { Component } from 'react';
import { Col, Container, Row, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import userService from "../../services/user.services";
import AuthService from '../../services/auth.service';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { matchSorter } from 'match-sorter';
import '../../customCss.css'

class PartyList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            partyList: [],
            partyJsonData: null,
            disabledButton: true,
            searchPartyCode: '',
            partyArrays: [],
            searchCity: '',
            searchPriority: '',
            searchState: '',
            partyCode: '',
            city: '',
            priority: '',
            stateNm: '',
            partyCodeNewList: [],
            stateNewList: [],
            cityNewList: [],
            priorityNewList: [],
            modal: false,
            modalDelete: false,
        };

        this.toggle = this.toggle.bind(this);
        this.toggleDelete = this.toggleDelete.bind(this);


    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    toggleDelete() {
        this.setState(prevState => ({
            modalDelete: !prevState.modalDelete
        }));
    }


    getPartyList() {
        userService.partyList().then(response =>
            this.setState({
                partyList: JSON.parse(response.data['jsonObject']),
                partyArrays: JSON.parse(response.data['jsonObject'])
            }),
        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {

                console.log('error ', error)
            }

        })

    }

    componentWillMount() {
        this.getPartyList();
    }


    deletePartyMast(e, partyId, statusFlg) {
        userService.deletePartyMast(partyId, statusFlg).then(response => {
            if (response.data['status'] == 'SUCCESS') {
                this.getPartyList();
                toast.success(response.data['message'])
            }
        }).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ');
                AuthService.logout();
                this.props.history.push('/');
            } else {
                console.log('error ', error)
            }

        })
    }

    partyExcelUpload(e) {

        this.toggle();

        let files = e.target.files[0];
        let formData = new FormData();
        formData.append('file', files);

        setTimeout(() => {
            userService.uploadPartyExcelFile(formData)
                .then(response => {

                    if (response.data['status'] === 'SUCCESS') {
                        toast.success("Party Updated Successfully");
                    } else {
                        toast.warn("Error Occured");
                    }
                    this.toggle();
                }
                ).catch(error => {

                    if (error.response.status === 403) {
                        alert('Your Login Session Is Expire ,Please Login ')
                        AuthService.logout()
                        this.props.history.push('/')

                    } else {

                        console.log('error ', error)
                    }

                })
        }, 30);

    }


    addParty() {
        this.props.history.push('/addParty');
    }

    deactiveAllPartyModal() {
        this.toggleDelete();
    }

    deactiveAllParty(e) {
        userService.deactiveAllParty().then(response => {
            if (response.data['status'] == 'SUCCESS') {
                this.getPartyList();
                this.toggleDelete();
                toast.success(response.data['message'])
            }
        }).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ');
                AuthService.logout();
                this.props.history.push('/');
            } else {
                console.log('error ', error)
            }

        })
    }




    render() {

        const columns = [
            {
                sortable: false,
                Header: 'Party Code',
                accessor: 'partyCode',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["partyCode"] }),
                filterAll: true,
            },
            {
                Header: 'Party Name',
                accessor: 'partyName',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["partyName"] }),
                filterAll: true,
            },

            {
                Header: 'Priority',
                accessor: 'priority',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["priority"] }),
                filterAll: true,

            },
            {
                Header: 'City',
                accessor: 'city',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["city"] }),
                filterAll: true,
            },
            {
                Header: 'Area',
                accessor: 'area',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["area"] }),
                filterAll: true,
            },
            {
                Header: 'State',
                accessor: 'state',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["state"] }),
                filterAll: true,
            },
            {
                Header: 'Edit',
                accessor: 'action1',
                Cell: props => {
                    return (
                        <div style={{ paddingLeft: "50px" }}>
                            <Link className="btn btn-outline-info" to={`/editParty/${props.original.partyId}`} href="#">
                                <i class="fa fa-edit"></i></Link>
                        </div>
                    )
                },
            },
            //  
            {
                Header: 'Action',
                accessor: 'action2',
                Cell: props => {
                    return (
                        <div style={{ paddingLeft: "50px" }}>
                            <Button style={{ width: "80px" }} outline color={props.original.statusFlg ? "danger" : "info"} onClick={(e) => this.deletePartyMast(e, props.original.partyId, props.original.statusFlg)}
                                href="#">{props.original.statusFlg ? 'Deactive' : 'Active'}</Button>
                        </div>
                    )
                },
            }


        ]

        return (
            <div className='container'>
                <ToastContainer autoClose={1000} draggable={false} />

                <div className="section-ptb" style={{ padding: "10px" }}>
                    <Container>

                        <Tabs>
                            <TabList>
                                <Tab>Party List</Tab>
                                <Tab>Party Excel Upload</Tab>
                            </TabList>

                            <TabPanel>

                                <Row>
                                    <Col lg={12}>

                                        {/* <Row>
                                            <Col md={2}>
                                                <Button style={{ paddingTop: "10px" }} color="success" onClick={() => this.addParty()}><i class="fa fa-plus"></i> Add</Button>
                                            </Col>
                                            <Col md={2}>
                                                <Button style={{ paddingTop: "10px" }} color="success" onClick={() => this.deactiveAllParty()}> Deactive All</Button>
                                            </Col>
                                        </Row> */}

                                        <div>
                                            <Button outline color="secondary" onClick={() => this.addParty()}><i class="fa fa-plus"></i> Add</Button>{' '}
                                            <Button outline color="danger" onClick={() => this.deactiveAllPartyModal()}> Deactive All</Button>{' '}
                                        </div>
                                        <br></br>
                                        <ReactTable id="table-to-xls" className="orderList-table"
                                            data={this.state.partyList}
                                            columns={columns}
                                            filterable defaultFilterMethod={(filter, row) =>
                                                String(row[filter.id]) === filter.value}
                                            minRows={1}
                                            defaultPageSize={10}
                                            pageSizeOptions={[5, 25, 50, 100, 150, 250, 500]}
                                        />

                                        {/* <ReactTable className="partyList-table"
                                            data={this.state.partyList}
                                            columns={columns}
                                            minRows={1}
                                            defaultPageSize={5}
                                            pageSizeOptions={[5, 25, 50, 100, 150, 250, 500]}
                                        /> */}


                                    </Col>
                                </Row>

                                {/* modal-delete */}
                                <Modal isOpen={this.state.modalDelete} toggle={this.toggleDelete} className="modal-login modal-md modal-dialog-centered">
                                    <ModalHeader toggle={this.toggleDelete}>
                                        <h4 className="mb-0">Do you want deactive all party</h4>
                                    </ModalHeader>

                                    <ModalBody>
                                        <div className="success-screen">
                                            <div className="row">
                                                <div className="col-md-4" style={{ paddingLeft: "15px" }}>
                                                    <Button style={{ paddingTop: "5px" }} outline color="success" onClick={(e) => this.deactiveAllParty(e)}>Yes</Button>&nbsp;&nbsp;
                                                    <Button style={{ paddingTop: "5px" }} outline color="danger" onClick={this.toggleDelete}>No</Button>
                                                </div>

                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>

                            </TabPanel>

                            <TabPanel>
                                <div class="tab-pane fade show active" id="transaction-list" role="tabpanel" aria-labelledby="transaction-list-tab">
                                    <h1>Excel Upload</h1>
                                    <div>
                                        <div onSubmit={this.onFormSubmit}>
                                            <input type="file" name="file" onChange={(e) => this.partyExcelUpload(e)} />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-login modal-dialog-centered">
                                <ModalHeader toggle={this.toggle}>
                                </ModalHeader>
                                <ModalBody>

                                    <h1 className="mb-0">Please Wait...</h1>

                                </ModalBody>
                            </Modal>



                        </Tabs>


                    </Container>
                </div>
            </div>
        )
    }

}
export default PartyList;