import React, { Component } from "react";
import userJson from '../../api/userJson';
import { Button, Row } from 'reactstrap';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import userService from "../../services/user.services";
import { toast, ToastContainer } from 'react-toastify';
import AuthService from '../../services/auth.service';
import Select from 'react-select';

const customStyles = {
    control: base => ({
        ...base,
        height: 48,
        minHeight: 48,
        maxMenuHeight: 200
    })
};

class AddUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            password: '',
            fieldvalue: "",
            options: [],
            roleData: "",
            roleSelectedValue: "",
            roleId: ""
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();     
        userService.saveUser(this.state.fieldvalue).then(
            response => {
                if (response.data['status'] === 'SUCCESS') {

                    this.props.history.push({
                        pathname: '/editUser/' + response.data['jsonObject'].userId
                    })

                    toast.success(response.data['message']);
                    this.setState({ fieldvalue: response.data })
                    this.editUserMast(response.data['jsonObject'].userId);

                } else if (response.data['status'] === 'Error') {
                    toast.error(response.data['message']);
                }
            },
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }

    editUserMast(userId) {
        userService.editUserMast(userId).then(response => {
            setTimeout(() => {
                this.setState({
                    fieldvalue: response.data, roleSelectedValue: JSON.parse((response.data.roleMastJson))
                })
            }, 500);
        })
    }

    handleChangeEvent(field, e) {
        let fieldvalue = this.state.fieldvalue;
        fieldvalue[field] = e.target.value;
        this.setState({ fieldvalue });
    }

    componentWillMount() {

        this.getRoleMastData();
        if (parseInt(this.props.match.params.userId) > 0) {
            this.editUserMast(parseInt(this.props.match.params.userId));
        } else {
            this.setState({ fieldvalue: userJson["0"], roleMastId: userJson["0"].roleMastJson })
        }
    }

    userListing() {
        this.props.history.push('/user')
    }

    getRoleMastData() {
        userService.getRoleMastData().then(response =>
            this.setState({
                roleData: JSON.parse(response.data.jsonObject),
                options: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }

        })
    }


    getRole = value => {
        let fieldvalue = this.state.fieldvalue;
        fieldvalue["roleMastId"] = value.value;
        this.setState({ fieldvalue, roleSelectedValue: value, roleId: value.value });
    }

    render() {

        return (

            <div className="container">
                 <div className="row">
                <ToastContainer autoClose={1500} draggable={false} />

                <div className="col-md-12 ">
                    <Row style={{ paddingTop: "10px" }}>
                        <div className="col-md-11">
                            <h3 style={{ "text-align": "left", color: "navy" }}> User </h3>
                        </div>
                        <div className="col-md-1">
                            <Button outline color="secondary" onClick={() => this.userListing()}>Listing</Button>
                        </div>
                    </Row>

                    <Form onSubmit={this.handleSubmit}>

                        <div className="row">
                            <div className="form-group col-md-6">
                                <label style={{ fontWeight: "bold" }}>UserName <span style={{ color: "red" }}>*</span> </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="User Name"
                                    value={this.state.fieldvalue.userName}
                                    onChange={this.handleChangeEvent.bind(this, "userName")}
                                    autoComplete="off" />
                            </div>

                        </div>

                        <div className="row">
                            <div className="form-group col-md-6">
                                <label style={{ fontWeight: "bold" }}>Role <span style={{ color: "red" }}>*</span> </label>
                                <Select name="roleMastId" styles={customStyles}
                                    defaultValue="Select Role"
                                    options={this.state.options}
                                    value={this.state.roleSelectedValue}
                                    onChange={this.getRole}
                                ></Select>
                            </div>

                        </div>

                        <div className="row">
                            <div className="form-group col-md-6">
                                <label style={{ fontWeight: "bold" }}>Password <span style={{ color: "red" }}>*</span> </label>
                                <Input
                                    type="password"
                                    className="form-control"
                                    placeholder="Password"
                                    value={this.state.fieldvalue.password}
                                    onChange={this.handleChangeEvent.bind(this, "password")}
                                    autoComplete="off" />
                            </div>

                        </div>


                        <div className="form-group">
                            <button className="btn btn-outline-success">Save</button>
                        </div>



                    </Form>




                </div>
                </div>
            </div>
        )
    }
}

export default AddUser;