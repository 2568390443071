export default [
    {
        "partyId": "",
        "partyName": "",
        "partyCode": "",
        "mobile": "",
        "contact": "",
        "contactTwo": "",
        "contactThree": "",
        "email": "",
        "priority": "",
        "country": "",
        "state": "",
        "gstNo": "",
        "transportDetails": "",
        "bookingDest": "",
        "bankDetails": "",
        "bankMobile": "",
        "bankemail": "",
        "specialInst": "",
        "remark": ""
    }
]