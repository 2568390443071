import React, { useState } from "react";
import { Col, Container, Row, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import userService from "../../services/user.services";
import AuthService from '../../services/auth.service';

function PartyStockJsonUpload() {

    const [uploadFile, setUploadFile] = useState();
    const [modalPleaseWait, setModalPleaseWait] = useState(false);

    const setModalIsOpenToTrue = () => {
        setModalPleaseWait(true)
    }

    const setModalIsOpenToFalse = () => {
        setModalPleaseWait(false)
    }

    const submitForm = (event) => {
        setModalIsOpenToTrue();
        event.preventDefault();
        let formData = new FormData();
        formData.append('file', uploadFile);
        formData.append('seasonId', localStorage.getItem('seasonId'));

        userService.partyStockJsonFileUpload(formData)
            .then(response => {

                if (response.data['status'] === 'SUCCESS') {
                    toast.success("Json Updated Successfully");
                } else {
                    toast.warn(response.data['message']);
                }
                setModalIsOpenToFalse();
            }
            ).catch(error => {
                setModalIsOpenToFalse();
                toast.warn('Error occured, contact admin');
                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')

                } else {
                    console.log('error ', error)
                }

            })



    }

    return (
        <Container style={{ border: "0.10px solid", color: 'grey', marginTop: '10px' }}>
            <ToastContainer autoClose={5} draggable={false} />

            <div className="col-md-12 " >
                <Row style={{ paddingTop: "10px" }}>
                    <div className="col-md-10">
                        <h3 style={{ "text-align": "left", color: "navy" }}>Json Upload </h3>
                    </div>
                </Row>

            </div>

            <Row style={{ padding: "10px" }}>
                <div>
                    <form onSubmit={submitForm}>
                        <Row>
                            <div className="col-md-12">
                                <input type="file" onChange={(e) => setUploadFile(e.target.files[0])} />
                                <Button outline color="success" type="submit">Upload</Button>
                            </div>
                        </Row>
                    </form>
                </div>
            </Row>

            <Modal isOpen={modalPleaseWait} toggle={setModalIsOpenToTrue} className="modal-login modal-dialog-centered">
                <ModalHeader toggle={setModalIsOpenToTrue}>
                </ModalHeader>
                <ModalBody>
                    <h1 className="mb-0">Please Wait...</h1>
                </ModalBody>
            </Modal>

        </Container>
    );

}

export default PartyStockJsonUpload;