import React, { Component } from "react";
import { Col, Container, Row, Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import userService from "../../services/user.services";
import AuthService from '../../services/auth.service';

class StockStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        }

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    stockExcelUpload(e) {

        this.toggle();
        let files = e.target.files[0];

        let formData = new FormData();
        formData.append('file', files);
        formData.append('seasonId', localStorage.getItem('seasonId'));

        setTimeout(() => {
            userService.uploadStockStatusExcelFile(formData)
                .then(response => {
                    if (response.data['status'] === 'SUCCESS') {
                        toast.success("Stock Updated Successfully");
                    } else {
                        toast.warn(response.data['message']);
                    }
                    this.toggle();
                }
                ).catch(error => {

                    if (error.response.status === 403) {
                        alert('Your Login Session Is Expire ,Please Login ')
                        AuthService.logout()
                        this.props.history.push('/')

                    } else {

                        console.log('error ', error)
                    }

                })
        }, 30);

    }

    render() {

        return (
            <Container style={{ border: "0.10px solid", color: 'grey', marginTop: '10px' }}>
                <ToastContainer autoClose={5} draggable={false} />

                <div className="col-md-12 " >
                    <Row style={{ paddingTop: "10px" }}>
                        <div className="col-md-10">
                            <h3 style={{ "text-align": "left", color: "navy" }}>Stock Excel Upload </h3>
                        </div>
                    </Row>


                    <Row style={{ padding: "10px" }}>
                        <div>
                            <div>
                                <input type="file" name="file" onChange={(e) => this.stockExcelUpload(e)} />
                            </div>
                        </div>
                    </Row>

                    <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-login modal-dialog-centered">
                        <ModalHeader toggle={this.toggle}>
                        </ModalHeader>
                        <ModalBody>
                            <h1 className="mb-0">Please Wait...</h1>
                        </ModalBody>
                    </Modal>

                </div>
            </Container>
        )
    }

}

export default StockStatus;