import React, { Component } from 'react';
import { DataGrid, Column, FilterRow } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import userService from "../../services/user.services";
import { toast, ToastContainer } from 'react-toastify';
import AuthService from '../../services/auth.service';



class PartyWiseProductStock extends Component {
    constructor(props) {
        super(props);
        this.getClothPartyWiseChildData(props.data.key);
        this.state = {
            partyWiseclothChildList: [],
            sizeArray: '',
            sizeNm1: '',
            sizeNm2: '',
            sizeNm3: '',
            sizeNm4: '',
            sizeNm5: '',
            sizeNm6: '',
            sizeNm7: '',
            sizeNm8: '',
            sizeNm9: '',
            sizeNm10: '',
            sizeNm11: '',
            sizeNm12: '',

            productNm: props.data.key
        }


    }

    getClothPartyWiseChildData(productNm) {

        console.log('xproductNm   ', productNm);

        userService.getClothPartyWiseChildData(productNm, localStorage.getItem('seasonId')).then(
            response => {

                if (response.data['status'] === 'SUCCESS') {
                    let sizeArrayNewP = JSON.parse(response.data['jsonObject'])['sizeArray'];
                    this.setState({
                        partyWiseclothChildList: JSON.parse(response.data['jsonObject'])['childList'],
                        sizeNm1: sizeArrayNewP[0],
                        sizeNm2: sizeArrayNewP[1],
                        sizeNm3: sizeArrayNewP[2],
                        sizeNm4: sizeArrayNewP[3],
                        sizeNm5: sizeArrayNewP[4],
                        sizeNm6: sizeArrayNewP[5],
                        sizeNm7: sizeArrayNewP[6],
                        sizeNm8: sizeArrayNewP[7],
                        sizeNm9: sizeArrayNewP[8],
                        sizeNm10: sizeArrayNewP[9],
                        sizeNm11: sizeArrayNewP[10],
                        sizeNm12: sizeArrayNewP[11]

                    });

                } else if (response.data['status'] === 'Error') {
                    toast.error(response.data['message']);
                }
            },

        )
    }


    render() {


        return (
            <React.Fragment>
                <div className="master-detail-caption">
                    {`Party wise order details`}
                </div>
                <DataGrid
                    dataSource={this.state.partyWiseclothChildList}
                    showBorders={true}
                    columnAutoWidth={true}
                >
                    <FilterRow visible={true} applyFilter={'auto'} />
                    <Column dataField="partyCode" />
                    <Column dataField="priority" />
                    <Column dataField="orderNo" />
                    <Column dataField="size1" caption={this.state.sizeNm1} />
                    <Column dataField="size2" caption={this.state.sizeNm2} />
                    <Column dataField="size3" caption={this.state.sizeNm3} />
                    <Column dataField="size4" caption={this.state.sizeNm4} />
                    <Column dataField="size5" caption={this.state.sizeNm5} />
                    <Column dataField="size6" caption={this.state.sizeNm6} />
                    <Column dataField="size7" caption={this.state.sizeNm7} />
                    <Column dataField="size8" caption={this.state.sizeNm8} />
                    <Column dataField="size9" caption={this.state.sizeNm9} />
                    <Column dataField="size10" caption={this.state.sizeNm10} />
                    {/* <Column dataField="size11" caption={this.state.sizeNm11} />
                    <Column dataField="size12" caption={this.state.sizeNm12} /> */}
                    <Column dataField="total" />


                </DataGrid>
            </React.Fragment>
        );
    }


}









export default PartyWiseProductStock;
