export default [
    {
        "fromDate": '',
        "toDate": '',
        "pfromDate": '',
        "ptoDate": '',
        "pFromOrderDate": '',
        "pToOrderDate": '',
        "product": '',
        "party": '',
        "order": '',
        "partyId": '',
        "orderMtId": '',
        "seasonId": localStorage.getItem('seasonId'),
        "dealerFlg": false,
        "mrpFlg": false,
        "mobileNo": '',
        "companyCode": '',



    }
]

