export default [
    {
        productId: '',
        productNm: '',
        productType: '',
        brand: '',
        subBrand: '',
        category: '',
        pattern: '',
        fits: '',
        color: '',
        collar: '',
        productGroup: '',
        companyCode: '',
        parentProductNm: '',
        shortProductNm: '',
        mrp: '',
        cost: '',
        quality: '',
        fits: '',
        availableSizes: '',
        productTypeJsonString: '',
        brandJsonString: '',
        subBrandJsonString: '',
        categoryJsonString: '',
        collarJsonString: '',
        companyCodeJsonString: '',
        colorJsonString: '',
        patternJsonString: '',
        qualityJsonString: '',
        fitsJsonString: '',
        sleevJsonString: '',
        productGroupJsonString: '',
        seasonId: ''



    }
]