import React, { Component } from "react";
import { toast, ToastContainer } from 'react-toastify';
import userService from "../../services/user.services";
import AuthService from '../../services/auth.service';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { matchSorter } from 'match-sorter';

class UserMaster extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userList: [],
            userJsonData: null,
            userArrays: [],
            searchUserName: '',
            userName: '',
            userNameNewList: [],

        }
    }



    getUserList() {
        userService.userList().then(response =>
            this.setState({
                userList: JSON.parse(response.data['jsonObject']),
                userArrays: JSON.parse(response.data['jsonObject'])
            }),
        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {

                console.log('error ', error)
            }

        })

    }

    componentWillMount() {
        this.getUserList();
    }

    addUser() {
        this.props.history.push('/addUser')
    }

    deleteUser(e, userId, statusFlg) {
        userService.deleteUser(userId, statusFlg).then(response => {
            if (response.data['status'] == 'SUCCESS') {
                this.getUserList();
                toast.success(response.data['message'])
            }
        }).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ');
                AuthService.logout();
                this.props.history.push('/');
            } else {
                console.log('error ', error)
            }

        })
    }

    render() {

        const columns = [
            {
                sortable: false,
                Header: 'UserName',
                accessor: 'userName',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["userName"] }),
                filterAll: true,
            },

            {
                sortable: false,
                Header: 'Role',
                accessor: 'roleMast',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["roleMast"] }),
                filterAll: true,
            },
            {
                Header: 'Status',
                accessor: 'status',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["status"] }),
                filterAll: true,
            },
            {
                Header: 'Edit',
                accessor: 'action1',
                Cell: props => {
                    return (
                        <div>
                            <Link className="btn btn-outline-info" to={`/editUser/${props.original.userId}`} href="#">
                                <i class="fa fa-edit"></i></Link>
                        </div>
                    )
                },
            },
            {
                Header: 'Action',
                accessor: 'action2',
                Cell: props => {
                    return (
                        <div>
                            <Link style={{ width: "80px" }} className={props.original.statusFlg ? 'btn btn-outline-info' : 'btn btn-outline-danger'} onClick={(e) => this.deleteUser(e, props.original.userId, props.original.statusFlg)}
                                href="#">{props.original.statusFlg ? 'Active' : 'Deactive'}</Link>
                        </div>
                    )
                },
            }


        ]

        return (

            <div className="container">
                <br />
                <Row>

                    <Col lg={12}>
                        <Row>
                            <Col lg={2}>
                                <div className="mb-2">
                                    <Button outline color="secondary" onClick={() => this.addUser()}><i class="fa fa-plus"></i> Add</Button>
                                </div>
                            </Col>
                            {/* <Col lg={3}>
                                <div className="mb-3">
                                    <form>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Search UserName" value={this.state.searchUserName} onChange={(e) => this.onSearchUserName(e.target.value, 'userName')}></input>
                                        </div>
                                    </form>
                                </div>
                            </Col> */}
                        </Row>


                    </Col>

                </Row>

                <ReactTable className="partyList-table"
                    data={this.state.userList}
                    columns={columns}
                    filterable defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value}
                    minRows={1}
                    defaultPageSize={10}
                    pageSizeOptions={[5, 25, 50, 100, 150, 250, 500]}
                />



            </div>
        )
    }
}

export default UserMaster;