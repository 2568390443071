import React, { Component } from 'react';
import userService from "../../services/user.services";
import { toast, ToastContainer } from 'react-toastify';
import AuthService from '../../services/auth.service';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import partyJson from '../../api/partyJson';
import { Button, Row } from 'reactstrap';
// import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

class AddPartyMaster extends Component {

    constructor(props) {
        super(props);
        this.state = {
            partyName: '',
            partyCode: '',
            fieldvalue: "",
            country: '',
            region: '',
            submitted: false,
            successful: false,
            message: "",

        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    selectCountry(val) {
        this.setState({ country: val });
    }
    selectRegion(val) {
        this.setState({ region: val });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            message: "",
            successful: false,
            submitted: true
        });

        if (this.state.fieldvalue.partyName, this.state.fieldvalue.partyCode) {
            userService.saveParty(this.state.fieldvalue).then(
                response => {
                    if (response.data['status'] === 'SUCCESS') {

                        //this.props.history.push({pathname: '/editParty/' + response.data['jsonObject'].partyId})
                        toast.success(response.data['message']);
                        this.props.history.push('/partyMaster')


                        // this.setState({ fieldvalue: response.data })
                        // this.editPartyMast(response.data['jsonObject'].partyId);

                    } else if (response.data['status'] === 'Error') {
                        toast.error(response.data['message']);
                    }
                },
            ).catch(error => {
                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')
                } else {
                    console.log('error ', error)
                }
            })
        }
    }

    editPartyMast(partyId) {
        userService.editPartyMast(partyId).then(response => {
            setTimeout(() => {
                this.setState({
                    fieldvalue: response.data
                })
            }, 500);
        })
    }

    handleChangeEvent(field, e) {
        let fieldvalue = this.state.fieldvalue;
        fieldvalue[field] = e.target.value;
        this.setState({ fieldvalue });
    }

    componentWillMount() {

        if (parseInt(this.props.match.params.partyId) > 0) {
            this.editPartyMast(parseInt(this.props.match.params.partyId));
        } else {
            this.setState({ fieldvalue: partyJson["0"] })
        }
    }

    PartyListing() {
        this.props.history.push('/partyMaster')
    }

    render() {

        const { country, region, submitted, fieldvalue } = this.state;
        return (
            <div className="container">
                <ToastContainer autoClose={1500} draggable={false} />
                <div className="col-md-12 ">
                    <Row style={{ paddingTop: "10px" }}>
                        <div className="col-md-11">
                            <h3 style={{ "text-align": "left", color: "navy" }}> Party </h3>
                        </div>
                        <div className="col-md-1">
                            <Button size="sm" outline color="secondary" onClick={() => this.PartyListing()}>Listing</Button>
                        </div>
                    </Row>

                    <Form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className={'form-group col-md-6 ' + (submitted && !fieldvalue.partyName ? ' has-error' : '')}>
                                {/* <div className="form-group col-md-6"> */}
                                <label style={{ fontWeight: "bold" }}>Party Name <span style={{ color: "red" }}>*</span> </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Party Name"
                                    value={this.state.fieldvalue.partyName}
                                    onChange={this.handleChangeEvent.bind(this, "partyName")}
                                    autoComplete="off"
                                    validations={[required]} />

                                {submitted && !fieldvalue.partyName &&
                                    <div className="alert alert-danger" role="alert">
                                        This field is required!
                                    </div>
                                }
                            </div>

                            <div className={'form-group col-md-6 ' + (submitted && !fieldvalue.partyCode ? ' has-error' : '')}>
                                <label style={{ fontWeight: "bold" }}>Party Code <span style={{ color: "red" }}>*</span></label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Party Code"
                                    value={this.state.fieldvalue.partyCode}
                                    onChange={this.handleChangeEvent.bind(this, "partyCode")}
                                    autoComplete="off"
                                    validations={[required]} />

                                {submitted && !fieldvalue.partyCode &&
                                    <div className="alert alert-danger" role="alert">
                                        This field is required!
                                    </div>
                                }
                            </div>

                            <div className="form-group col-md-6">
                                <label style={{ fontWeight: "bold" }}>Mobile </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Contact No"
                                    value={this.state.fieldvalue.mobile}
                                    onChange={this.handleChangeEvent.bind(this, "mobile")}
                                    autoComplete="off" />
                            </div>

                            <div className="form-group col-md-2">
                                <label style={{ fontWeight: "bold" }}>Contact Person 1 </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Contact Person 1"
                                    value={this.state.fieldvalue.contact}
                                    onChange={this.handleChangeEvent.bind(this, "contact")}
                                    autoComplete="off" />
                            </div>

                            <div className="form-group col-md-2">
                                <label style={{ fontWeight: "bold" }}>Contact Person 2 </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Contact Person 2"
                                    value={this.state.fieldvalue.contactTwo}
                                    onChange={this.handleChangeEvent.bind(this, "contactTwo")}
                                    autoComplete="off" />
                            </div>

                            <div className="form-group col-md-2">
                                <label style={{ fontWeight: "bold" }}>Contact Person 3 </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Contact Person 3"
                                    value={this.state.fieldvalue.contactThree}
                                    onChange={this.handleChangeEvent.bind(this, "contactThree")}
                                    autoComplete="off" />
                            </div>

                            <div className="form-group col-md-6">
                                <label style={{ fontWeight: "bold" }}>Email </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Email"
                                    value={this.state.fieldvalue.email}
                                    onChange={this.handleChangeEvent.bind(this, "email")}
                                    autoComplete="off" />
                            </div>

                            <div className="form-group col-md-6">
                                <label style={{ fontWeight: "bold" }}>Priority </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Priority"
                                    value={this.state.fieldvalue.priority}
                                    onChange={this.handleChangeEvent.bind(this, "priority")}
                                    autoComplete="off" />
                            </div>

                            <div className="form-group col-md-6">
                                <label style={{ fontWeight: "bold" }}>Country </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Country"
                                    value={this.state.fieldvalue.country}
                                    onChange={this.handleChangeEvent.bind(this, "country")}
                                    autoComplete="off" />
                            </div>

                            <div className="form-group col-md-6">
                                <label style={{ fontWeight: "bold" }}>State </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="State"
                                    value={this.state.fieldvalue.state}
                                    onChange={this.handleChangeEvent.bind(this, "state")}
                                    autoComplete="off" />
                            </div>

                            <div className="form-group col-md-6">
                                <label style={{ fontWeight: "bold" }}>City </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="city"
                                    value={this.state.fieldvalue.city}
                                    onChange={this.handleChangeEvent.bind(this, "city")}
                                    autoComplete="off" />
                            </div>

                            <div className="form-group col-md-6">
                                <label style={{ fontWeight: "bold" }}>Area </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="area"
                                    value={this.state.fieldvalue.area}
                                    onChange={this.handleChangeEvent.bind(this, "area")}
                                    autoComplete="off" />
                            </div>


                            <div className="form-group col-md-6">
                                <label style={{ fontWeight: "bold" }}>GST No </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="GST No"
                                    value={this.state.fieldvalue.gstNo}
                                    onChange={this.handleChangeEvent.bind(this, "gstNo")}
                                    autoComplete="off" />
                            </div>

                            <div className="form-group col-md-6">
                                <label style={{ fontWeight: "bold" }}>Transport Details</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Transport Details"
                                    value={this.state.fieldvalue.transportDetails}
                                    onChange={this.handleChangeEvent.bind(this, "transportDetails")}
                                    autoComplete="off" />
                            </div>

                            <div className="form-group col-md-6">
                                <label style={{ fontWeight: "bold" }}>Booking Destination</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Booking Destination"
                                    value={this.state.fieldvalue.bookingDest}
                                    onChange={this.handleChangeEvent.bind(this, "bookingDest")}
                                    autoComplete="off" />
                            </div>

                            <div className="form-group col-md-6">
                                <label style={{ fontWeight: "bold" }}>Booking Details</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Booking Details"
                                    value={this.state.fieldvalue.bankDetails}
                                    onChange={this.handleChangeEvent.bind(this, "bankDetails")}
                                    autoComplete="off" />
                            </div>

                            <div className="form-group col-md-6">
                                <label style={{ fontWeight: "bold" }}>Bank Mobile</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Bank Mobile"
                                    value={this.state.fieldvalue.bankMobile}
                                    onChange={this.handleChangeEvent.bind(this, "bankMobile")}
                                    autoComplete="off" />
                            </div>

                            <div className="form-group col-md-6">
                                <label style={{ fontWeight: "bold" }}>Bank Email</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Bank Email"
                                    value={this.state.fieldvalue.bankemail}
                                    onChange={this.handleChangeEvent.bind(this, "bankemail")}
                                    autoComplete="off" />
                            </div>

                            <div className="form-group col-md-6">
                                <label style={{ fontWeight: "bold" }}>Special Instructions</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Special Instructions"
                                    value={this.state.fieldvalue.specialInst}
                                    onChange={this.handleChangeEvent.bind(this, "specialInst")}
                                    autoComplete="off" />
                            </div>

                            <div className="form-group col-md-6">
                                <label style={{ fontWeight: "bold" }}>Remark</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Remark"
                                    value={this.state.fieldvalue.remark}
                                    onChange={this.handleChangeEvent.bind(this, "remark")}
                                    autoComplete="off" />
                            </div>

                            {/* <div className="form-group col-md-6">
                                <CountryDropdown
                                    value={country}
                                    onChange={(val) => this.selectCountry(val)} />
                            </div>

                            <div className="form-group col-md-6">
                                <RegionDropdown
                                    country={country}
                                    value={region}
                                    onChange={(val) => this.selectRegion(val)} />
                            </div> */}



                        </div>

                        <div className='row'>
                            <div className="form-group col-md-6">
                                <button className="btn btn-outline-success">Save</button>
                            </div>
                        </div>


                    </Form>

                </div>
            </div>
        )
    }
}

export default AddPartyMaster;