import React, { Component } from "react";
import { Col, Container, Row, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import userService from "../../services/user.services";
import AuthService from '../../services/auth.service';
import reportParam from '../../api/reportsParam';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import ReactTable from 'react-table';
import { matchSorter } from 'match-sorter';
import { toast, ToastContainer } from 'react-toastify';
import DataGrid, {
    Column, Grouping, GroupPanel, Pager, Paging, SearchPanel,
    Selection, Export, Summary, GroupItem, TotalItem, MasterDetail, FilterRow
} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridPdf } from 'devextreme/pdf_exporter';
import PhoneInput from 'react-phone-input-2';
import ProductStock from './productStock.js';
//import '../../customCss.css'
import Select, { createFilter } from 'react-select';
import OrderStatusReport from "./OrderStatusReport";
import OrderSummarySubbrandWise from "./OrderSummarySubbrandWise";

const exportFormats = ['pdf', 'Excel'];




class ReportsNew extends Component {
    _exporter;
    constructor(props) {
        super(props);
        this.onFromDateChange = this.onFromDateChange.bind(this);
        this.onToDateChange = this.onToDateChange.bind(this);
        this.generateReports = this.generateReports.bind(this);
        this.toggle4 = this.toggle4.bind(this);
        this.toggleParty = this.toggleParty.bind(this);
        this.toggleOrder = this.toggleOrder.bind(this);
        this.partySave = this.partySave.bind(this);
        this.orderSave = this.orderSave.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        this.onExporting = this.onExporting.bind(this);
        this.togglePleaseWait = this.togglePleaseWait.bind(this);
        this.orderLock = this.orderLock.bind(this);
        this.getOrderStatusReport = this.getOrderStatusReport.bind(this);




        this.state = {
            collapsed: false,
            fieldvalue: "",
            reportData: "",
            reportHeading: "",
            reportListingData: [],
            sizeArray: [],
            productNm: '',
            modal4: false,
            productSizeWiseList: [],
            productType: '',
            brand: '',
            show: false,
            modalParty: false,
            modalOrder: false,
            partyList: [],
            partyJsonData: null,
            orderList: [],
            orderJsonData: [],
            reportFilter: [],
            selectAllPartyFlg: false,
            selectAllOrderFlg: false,
            betweenDateFilterFlg: false,
            orderFilterFlg: false,
            partyFilterFlg: false,
            partyDropdownFilterFlg: false,
            brandDropdownFilterFlg: false,
            pageSizes: [10, 25, 50, 100],
            dealerMrpFliterFlg: false,
            mobileNoFilterFlg: false,
            isMobileDisabled: true,
            value: '',
            options: [],
            options2: [],
            companyCode: '',
            partySelectedValue: '',
            brandSelectedValue: '',
            orderListSize: 0,
            modalPleaseWait: false,
            generateBtnFlg: false,
            ordStatusReportNm: '',
        }
    }

    togglePleaseWait() {
        this.setState(prevState => ({
            modalPleaseWait: !prevState.modalPleaseWait
        }));
    }

    onContentReady(e) {
        if (!this.state.collapsed) {
            e.component.expandRow(['EnviroCare']);
            this.setState({
                collapsed: true,
            });
        }
    }

    onExporting(e) {

        if (e.format == "Excel") {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Main sheet');

            exportDataGrid({
                component: e.component,
                worksheet,
                autoFilterEnabled: true,
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
                });
            });
            e.cancel = true;
        } else {
            const doc = new jsPDF();

            exportDataGridPdf({
                jsPDFDocument: doc,
                component: e.component,
                indent: 5,
            }).then(() => {
                doc.save('Preview.pdf');
            });
        }


    }

    export = () => {
        this._exporter.save();
    }

    toggle4() {
        this.setState(prevState => ({
            modal4: !prevState.modal4
        }));
    }

    toggleParty() {

        if (this.state.modalParty == false) {
            if (this.state.reportData.xml === 'partyDetailsReports') {
                this.getPartyReportList();
            } else {
                this.getPartyList();
            }
        } else {
            this.setState(prevState => ({
                modalParty: !prevState.modalParty
            }));
        }

    }

    toggleOrder() {

        if (this.state.modalOrder == false) {
            this.getOrderList();
        } else {
            this.setState(prevState => ({
                modalOrder: !prevState.modalOrder,
            }))
        }
    }

    convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    onFromDateChange(dateVal) {

        let fieldvalue = this.state.fieldvalue;
        fieldvalue["fromDate"] = dateVal;
        fieldvalue["pfromDate"] = this.convert(dateVal);
        fieldvalue["pFromOrderDate"] = this.convert(dateVal);
        fieldvalue["product"] = '';
        this.setState({ fieldvalue });
    }

    onToDateChange(dateVal) {
        let fieldvalue = this.state.fieldvalue;
        fieldvalue["toDate"] = dateVal;
        fieldvalue["ptoDate"] = this.convert(dateVal);
        fieldvalue["pToOrderDate"] = this.convert(dateVal);
        fieldvalue["product"] = '';
        this.setState({ fieldvalue });
    }


    handleChangeMobileEvent(field, e) {
        userService.isValidMobileNo(e.target.value).then(
            response => {
                let fieldvalue = this.state.fieldvalue;
                if (response.data['status'] === 'SUCCESS') {
                    fieldvalue[field] = e.target.value;
                } else if (response.data['status'] === 'Error') {
                    fieldvalue[field] = '91';
                    toast.error(response.data['message']);

                }
                this.setState({ fieldvalue });
            },

        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }


    handleChangeEvent(field, e) {

        let fieldvalue = this.state.fieldvalue;
        fieldvalue[field] = e.target.value;
        this.setState({ fieldvalue });
    }

    generateListingReport() {
        this.togglePleaseWait();
        let fieldvalue = this.state.fieldvalue;
        fieldvalue["companyCode"] = this.state.companyCode;

        userService.generateListingReport(fieldvalue, this.state.reportData, 'allListingReport', localStorage.getItem('bookingAtStudio')).then(
            response => {

                if (response.data['status'] === 'SUCCESS') {
                    this.setState({ reportListingData: JSON.parse(response.data['jsonObject']) })
                } else if (response.data['status'] === 'Error') {
                    toast.error(response.data['message']);
                }
                this.togglePleaseWait();
            },

        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }

    generateReports(event) {

        event.preventDefault();
        let fieldvalue = this.state.fieldvalue;
        fieldvalue["companyCode"] = this.state.companyCode;
        this.setState({ generateBtnFlg: true, ordStatusReportNm: 'orderStatusReports' });

        if (this.state.reportData.reportType == 'report') {
            this.togglePleaseWait();
            userService.generateReports(fieldvalue, this.state.reportData, "generateRpt").then(
                response => {
                    if (response.data['status'] === 'SUCCESS') {
                       
                            this.reportPreview(response.data['jsonObject']);
                       
                    } else if (response.data['status'] === 'Error') {
                        toast.error(response.data['message']);
                    }

                    this.togglePleaseWait();
                },


            ).catch(error => {
                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')
                } else {
                    console.log('error ', error)
                }
            })
        } else {
            this.generateListingReport();
        }
    }

    reportPreview(repornm) {
        var link = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
        link.href = '../pdf/' + repornm;
        link.target = '_blank';
        var event = new MouseEvent('click', {
            'view': window,
            'bubbles': false,
            'cancelable': true
        });
        link.dispatchEvent(event);

        userService.downloadOrderReport(repornm).then(response => {
        }).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }

        })
    }


    handleSubmit(repornm) {
        let link = document.createElement('a');

        link.href = '../pdf/' + repornm
        link.download = repornm
        link.dispatchEvent(new MouseEvent('click'))

        // document.body.appendChild(link);
        // link.click();
        // link.parentNode.removeChild(link);

        userService.downloadOrderReport(repornm).then(response => {
        }).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }

        })
    }

    getReportDetails(menunm) {
        let betweenDateFlg = false;
        let partyFlg = false;
        let orderFlg = false;
        let dealerMrpFlg = false;
        let mobileNoFlg = false;
        let partyDropdownFlg = false;
        let brandDropdownFlg = false;


        userService.getReportDetails(menunm).then(response => {
            if (JSON.parse(response.data['jsonObject']).reportFilter != undefined) {
                let fliterNm = JSON.parse(response.data['jsonObject']).reportFilter.split(",");
                for (let i = 0; i < fliterNm.length; i++) {
                    switch (fliterNm[i]) {

                        case 'betweenPeriod':
                            betweenDateFlg = true;
                            break;

                        case 'party':
                            partyFlg = true;
                            break;

                        case 'order':
                            orderFlg = true;
                            break;

                        case 'dealerMrp':
                            dealerMrpFlg = true;
                            break;

                        case 'mobileNo':
                            mobileNoFlg = true;
                            break;

                        case 'partyDropdown':
                            partyDropdownFlg = true;
                            break;

                        case 'brand':
                            brandDropdownFlg = true;
                            break;


                        default:
                            console.log("FILTER NOT FOUND");

                    }
                }
            }

            this.setState({
                reportData: JSON.parse(response.data.jsonObject), fieldvalue: reportParam["0"],
                reportFilter: JSON.parse(response.data['jsonObject']).reportFilter,
                betweenDateFilterFlg: betweenDateFlg, partyFilterFlg: partyFlg, orderFilterFlg: orderFlg,
                dealerMrpFliterFlg: dealerMrpFlg, mobileNoFilterFlg: mobileNoFlg,
                companyCode: JSON.parse(response.data.jsonObject).companyCode,
                partyDropdownFilterFlg: partyDropdownFlg,
                brandDropdownFilterFlg: brandDropdownFlg,
            })
        })

    }

    reportDetails() {
        this.getReportDetails(this.props.history.location.pathname.replace(/\//g, ''));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        window.location.reload();
    }
    componentWillMount() {

        this.getPartyData();
        this.reportDetails();
        this.getBrandData();
        // this.getPartyList();
        // if (this.state.reportData.xml === 'partyDetailsReports') {
        //     this.getPartyReportList();
        // } else {
        //     this.getPartyList();
        // }
        // setTimeout(() => {
        //     this.getOrderList();
        // }, 500);

    }


    popSizeWiseQty(e, productNm, productType, brand) {

        let fieldvalue = this.state.fieldvalue;
        fieldvalue["product"] = productNm;
        this.setState({ fieldvalue });

        userService.generateListingReport(this.state.fieldvalue, this.state.reportData, 'allListingReport', localStorage.getItem('bookingAtStudio')).then(
            response => {
                if (response.data['status'] === 'SUCCESS') {
                    this.setState({ productSizeWiseList: JSON.parse(response.data['jsonObject']), productType: productType, brand: brand })
                    this.toggle4();
                } else if (response.data['status'] === 'Error') {
                    toast.error(response.data['message']);
                }
            },

        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }


    selectAllRow = (e) => {
        let partys = this.state.partyList;
        partys.map((party) => {
            party.selectRowFlg = e.target.checked;
        });
        this.setState({ ...this.state.partyList, selectAllPartyFlg: e.target.checked });

    }

    selectRow = (e, partyId) => {

        let partyArr = this.state.partyList.filter(party => party.partyId == partyId);

        partyArr.map((party) => {
            party.selectRowFlg = e.target.checked;
        })
        this.setState({ ...this.state.partyList, partyArr });

    }

    selectDealerMrp = (e, field) => {
        let fieldvalue = this.state.fieldvalue;
        fieldvalue[field] = e.target.checked;
        this.setState({ fieldvalue });
    }

    partySave() {
        let partyArr = this.state.partyList.filter(party => party.selectRowFlg === true);

        let partyString = '';
        let partyIdString = '';
        partyArr.map((party) => {
            if (partyString != '') {
                partyString += "," + party.partyCode;
                partyIdString += "," + party.partyId;
            } else {
                partyString = party.partyCode;
                partyIdString = party.partyId;
            }
        })

        let fieldvalue = this.state.fieldvalue;
        fieldvalue["party"] = partyString;
        fieldvalue["partyId"] = partyIdString;
        fieldvalue["order"] = '';
        fieldvalue["orderMtId"] = '';
        this.setState({ fieldvalue, selectAllPartyFlg: false });

        this.toggleParty();

    }

    getPartyList() {
        userService.allPartyReportList().then(response =>

            this.setState(prevState => ({
                partyList: JSON.parse(response.data['jsonObject']),
                modalParty: !prevState.modalParty
            }))

        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }

    getPartyReportList() {
        userService.getPartyReportList().then(response =>
            this.setState(prevState => ({
                partyList: JSON.parse(response.data['jsonObject']),
                modalParty: !prevState.modalParty
            }))
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }

    getOrderList() {
        userService.getOrderReportList(this.state.fieldvalue).then(response => {
            this.setState(prevState => ({
                orderList: JSON.parse(response.data['jsonObject']),
                orderListSize: JSON.parse(response.data['jsonObject']).length,
                modalOrder: !prevState.modalOrder,
            }))
        }
        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {
                console.log('error ', error)
            }
        })
    }


    selectAllOrderRow = (e) => {
        //   let orders = this.state.orderList;
        this.state.orderList.map((order) => {
            order.selectRowFlg = e.target.checked;
        });
        this.setState({ ...this.state.orderList, selectAllOrderFlg: e.target.checked });

    }

    selectOrderRow = (e, orderMtId) => {

        this.state.orderList.filter(order => order.orderMtId == orderMtId).map((order) => {
            order.selectRowFlg = e.target.checked;
        })
        this.setState({ ...this.state.orderList });
    }

    orderSave() {
        let orderArr = this.state.orderList.filter(order => order.selectRowFlg === true);

        let orderString = '';
        let orderMtIdString = '';
        let isMobileDis = true;
        orderArr.map((order) => {
            if (orderString != '') {
                orderString += "," + order.orderNo;
                orderMtIdString += "," + order.orderMtId;
            } else {
                orderString = order.orderNo;
                orderMtIdString = order.orderMtId;
            }
        })


        if (orderString != '') {
            isMobileDis = false;
        } else {
            isMobileDis = true;
        }

        let fieldvalue = this.state.fieldvalue;
        fieldvalue["order"] = orderString;
        fieldvalue["orderMtId"] = orderMtIdString;
        this.setState({ fieldvalue, isMobileDisabled: isMobileDis, selectAllOrderFlg: false });

        this.toggleOrder();
    }

    orderLock() {
        let orderArr = this.state.orderList.filter(order => order.selectRowFlg === true);

        if (orderArr.length > 0) {
            userService.orderLockFromReportFilter(orderArr).then(response => {
                if (response.data['jsonObject'] != 'SUCCESS') {

                    orderArr.map((order) => {
                        order.orderLockStatus = 'Lock';
                    })

                    this.setState({ ...this.state.orderList });

                    toast.success('Order Lock Successfully');
                } else {
                    toast.error('Error occured, Order not lock');
                }
            },
            ).catch(error => {
                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')
                } else {
                    console.log('error ', error)
                }

            })
        } else {
            alert('Please select records');
        }

    }

    refreshOrder() {
        let orders = this.state.orderList;
        orders.map((order) => {
            order.selectRowFlg = false;
        });
        this.setState({ ...this.state.orderList, selectAllOrderFlg: false });
    }

    refreshParty() {
        let partys = this.state.partyList;
        partys.map((party) => {
            party.selectRowFlg = false;
        });
        this.setState({ ...this.state.partyList, selectAllPartyFlg: false });
    }

    refreshOrderText() {
        let fieldvalue = this.state.fieldvalue;
        fieldvalue["order"] = '';
        this.setState({ fieldvalue });
    }

    refreshPartyText() {
        let fieldvalue = this.state.fieldvalue;
        fieldvalue["party"] = '';
        this.setState({ fieldvalue });
    }

    refreshPartyDropdown() {
        let fieldvalue = this.state.fieldvalue;
        fieldvalue["party"] = '';
        fieldvalue["partyId"] = '';
        this.setState({ fieldvalue, partySelectedValue: '' });
    }

    getPartyData() {
        userService.getPartyData().then(response =>
            this.setState({
                options: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }

        })
    }

    getBrandData() {
        userService.getBrandData().then(response =>
            this.setState({
                options2: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }

        })
    }

    getPartyCodeDt = value => {
        let fieldvalue = this.state.fieldvalue;
        fieldvalue["partyId"] = value.value;
        fieldvalue["party"] = value.value;
        this.setState({ fieldvalue, partySelectedValue: value });

    }

    getOrderSizeWiseData(val){
        this.togglePleaseWait()
        userService.getOrderSizeWiseData(localStorage.getItem('seasonId'),val).then(
            response => {
                if (response.data['status'] === 'SUCCESS') {
                    console.log(JSON.parse(response.data['jsonObject']));
                    this.setState({ reportListingData: JSON.parse(response.data['jsonObject']).mainList, sizeArray: JSON.parse(response.data['jsonObject']).sizeArray})
                    this.togglePleaseWait()
                } else if (response.data['status'] === 'Error') {
                    toast.error(response.data['message']);
                }
            },

        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }


    getBrandNmDt = value => {
        let fieldvalue = this.state.fieldvalue;
        fieldvalue["brandNm"] = value.value;
        this.setState({ fieldvalue, brandSelectedValue: value });
        this.getOrderSizeWiseData(value.value)
    }

    


    getOrderStatusReport = (param) => {

        this.setState({ generateBtnFlg: false, ordStatusReportNm: param });
        this.togglePleaseWait();

        if (param !== 'partyOrderStatusReports') {
            userService.generateReports(this.state.fieldvalue, param, "orderStatusRpt").then(
                response => {
                    if (response.data['status'] === 'SUCCESS') {
                        this.reportPreview(response.data['jsonObject']);
                    } else if (response.data['status'] === 'Error') {
                        toast.error(response.data['message']);
                    }

                    this.togglePleaseWait();
                },


            ).catch(error => {
                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')
                } else {
                    console.log('error ', error)
                }
            })
        } else {

            userService.generateListingReport(this.state.fieldvalue, param, 'orderStatusRpt', localStorage.getItem('bookingAtStudio')).then(
                response => {

                    if (response.data['status'] === 'SUCCESS') {
                        this.setState({ reportListingData: JSON.parse(response.data['jsonObject']) })
                    } else if (response.data['status'] === 'Error') {
                        toast.error(response.data['message']);
                    }
                    this.togglePleaseWait();
                },

            ).catch(error => {
                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')
                } else {
                    console.log('error ', error)
                }
            })
        }

    }

    render() {
        const { betweenDateFilterFlg, partyFilterFlg, orderFilterFlg, pageSizes, dealerMrpFliterFlg,
            mobileNoFilterFlg, isMobileDisabled, partyDropdownFilterFlg, brandDropdownFilterFlg } = this.state

        let savlaSum = 0;
        let smartSum = 0;
        let sagiSum = 0;
        let fashionSum = 0;
        let spanSum = 0;
        let totalSum = 0;

        let orderPartyWisedata = this.state.reportListingData;
        if (orderPartyWisedata.length > 0) {
            for (let i = 0; i < orderPartyWisedata.length; i++) {
                savlaSum += orderPartyWisedata[i].savla;
                smartSum += orderPartyWisedata[i].smart;
                sagiSum += orderPartyWisedata[i].sagi;
                fashionSum += orderPartyWisedata[i].fashion;
                spanSum += orderPartyWisedata[i].span;
                totalSum += orderPartyWisedata[i].total;
            }
        }


        const columns = [

            {

                sortable: false,
                Header: 'Company',
                accessor: 'companyCode',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["companyCode"] }),
                filterAll: true,

            },
            {
                Header: 'Order No',
                accessor: 'orderNo',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["orderNo"] }),
                filterAll: true,

            },
            {
                Header: 'Party',
                accessor: 'party',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["party"] }),
                filterAll: true,

            },
            {
                Header: 'Ready Product Stock',
                accessor: 'readyProductFlg',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["readyProductFlg"] }),
                filterAll: true,
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <input type="checkbox" defaultChecked={props.original.readyProductFlg} checked={props.original.readyProductFlg}
                                value={props.original.readyProductFlg} disabled
                            />
                        </div>
                    )
                },

            },
            {
                Header: 'Quantity',
                accessor: 'totQty',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["totQty"] }),
                filterAll: true,

            }

        ]

        const columnsCloth = [

            {
                width: 150,
                sortable: false,
                Header: 'Company',
                accessor: 'companyCode',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["companyCode"] }),
                filterAll: true,
            },
            {
                width: 150,
                Header: 'Brand',
                accessor: 'brand',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["brand"] }),
                filterAll: true,
            },
            {
                width: 100,
                Header: 'SubBrand',
                accessor: 'subBrand',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["subBrand"] }),
                filterAll: true,
            },
            {
                Header: 'Product',
                accessor: 'product',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["product"] }),
                filterAll: true,
            },
            {
                width: 200,
                Header: 'Order Booked',
                accessor: 'qty',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["qty"] }),
                filterAll: true,
            },

            {
                Header: 'Action',
                accessor: 'action2',
                Cell: props => {
                    return (
                        <div style={{ paddingLeft: "10px" }}>
                            <button onClick={(e) => this.popSizeWiseQty(e, props.original.product, props.original.productType, props.original.brand)}
                                href="#">Size Wise Qty</button>
                        </div>
                    )
                },
            }
        ]


        const columnOrderPartySummary = [

            {

                sortable: false,
                Header: 'Party Code',
                accessor: 'partyCode',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["partyCode"] }),
                filterAll: true,

            },
            {
                Header: 'Priority',
                accessor: 'priority',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["priority"] }),
                filterAll: true,
                Footer: "Total"
            },
            {
                Header: 'Savla',
                accessor: 'savla',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["savla"] }),
                filterAll: true,
                Footer: savlaSum

            },
            {
                Header: 'Sagi',
                accessor: 'sagi',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["sagi"] }),
                filterAll: true,
                Footer: sagiSum
            },
            {
                Header: 'Smart',
                accessor: 'smart',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["smart"] }),
                filterAll: true,
                Footer: smartSum
            },
            {
                Header: 'FL',
                accessor: 'fashion',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["fashion"] }),
                filterAll: true,
                Footer: fashionSum
            },
            {
                Header: 'Span',
                accessor: 'span',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["span"] }),
                filterAll: true,
                Footer: spanSum
            },
            {
                Header: 'Total',
                accessor: 'total',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["total"] }),
                filterAll: true,
                Footer: totalSum
            }

        ]


        const columnPartyFilter = [

            {
                sortable: false,
                width: 100,
                Header: <input type="checkbox" defaultChecked={this.state.selectAllPartyFlg} checked={this.state.selectAllPartyFlg}
                    value={this.state.selectAllPartyFlg} onClick={(e) => this.selectAllRow(e)} />,
                accessor: 'selectRowFlg',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <input type="checkbox" defaultChecked={props.original.selectRowFlg} checked={props.original.selectRowFlg}
                                value={props.original.selectRowFlg} onClick={(e) => this.selectRow(e, props.original.partyId)}
                            />
                        </div>
                    )
                },
            },
            {

                sortable: false,
                Header: 'Party Code',
                accessor: 'partyCode',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["partyCode"] }),
                filterAll: true,
            },
        ]

        const columnOrderFilter = [

            {
                sortable: false,
                width: 50,
                Header: <input type="checkbox" defaultChecked={this.state.selectAllOrderFlg} checked={this.state.selectAllOrderFlg}
                    value={this.state.selectAllOrderFlg} onClick={(e) => this.selectAllOrderRow(e)} />,
                accessor: 'selectRowFlg',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <input type="checkbox" defaultChecked={props.original.selectRowFlg} checked={props.original.selectRowFlg}
                                value={props.original.selectRowFlg} onClick={(e) => this.selectOrderRow(e, props.original.orderMtId)}
                            />
                        </div>
                    )
                },
            },
            {
                sortable: false,
                Header: 'Order',
                accessor: 'orderNo',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["orderNo"] }),
                filterAll: true,
            },
            {
                sortable: false,
                Header: 'Status',
                accessor: 'status',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["status"] }),
                filterAll: true,
            },
            {
                sortable: false,
                Header: 'Lock/Unlock Status',
                accessor: 'orderLockStatus',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["orderLockStatus"] }),
                filterAll: true,
            },

            {
                sortable: false,
                Header: 'Created By',
                accessor: 'createdBy',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["createdBy"] }),
                filterAll: true,
            },
            {
                sortable: false,
                Header: 'Modify By',
                accessor: 'modifyBy',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["modifyBy"] }),
                filterAll: true,
            },

        ]

        return (

            <Container style={{ border: "0.10px solid", color: 'grey', marginTop: '10px', minHeight: "80vh" }}>

                <Form onSubmit={this.generateReports}>
                    <Row style={{ paddingTop: "10px" }}>
                        <Col md={10}>
                            <h3 style={{ "text-align": "left", color: "navy" }}> {this.state.reportData.reportHeading} </h3>
                        </Col>
                        <Col md={2}>
                            {this.state.reportData.xml !== 'orderBookSizeWise'
                            ?
                            <Button outline color="success">Generate Report</Button>
                            :
                            null
                            }
                        </Col>
                    </Row>

                    <hr style={{ color: "red" }}></hr>


                    <Row>
                        <Col md={2} ></Col>


                        <Col md={8}>
                            {betweenDateFilterFlg &&
                                <Row style={{ padding: "5px" }}>
                                    <Col md={2} sm={2} xs={2}>
                                        <label style={{ fontWeight: "bold" }}>Between Period </label>
                                    </Col>
                                    <Col md={4} sm={4} xs={4}>
                                        {<DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            value={this.state.fieldvalue.fromDate}
                                            selected={this.state.fieldvalue.fromDate}
                                            onChange={this.onFromDateChange}
                                        />}
                                    </Col>

                                    <Col md={4} sm={4} xs={4}>
                                        {<DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            value={this.state.fieldvalue.toDate}
                                            selected={this.state.fieldvalue.toDate}
                                            onChange={this.onToDateChange}
                                        />}
                                    </Col>

                                </Row>
                            }

                            {partyFilterFlg &&
                                <Row style={{ padding: "5px" }}>
                                    <Col md={2}>
                                        <label style={{ fontWeight: "bold" }}>Party </label>
                                    </Col>

                                    <Col md={8}>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            value={this.state.fieldvalue.party}
                                            onChange={this.handleChangeEvent.bind(this, "party")}
                                            autoComplete="off"
                                            disabled
                                        />

                                    </Col>

                                    <Col md={2}>
                                        <Button outline color="secondary" size="sm" onClick={this.toggleParty}><i class="fa fa-list"></i></Button>{' '}
                                        <Button onClick={this.refreshPartyText.bind(this)} outline color="info"><i class="fa fa-refresh" aria-hidden="true"></i> </Button>
                                    </Col>


                                </Row>
                            }

                            {partyDropdownFilterFlg &&
                                <Row style={{ padding: "5px" }}>
                                    <Col md={2}>
                                        <label style={{ fontWeight: "bold" }}>Party </label>
                                    </Col>

                                    <Col md={8}>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <Select className="react-select" classNamePrefix="react-select" name="partyId"
                                                    defaultValue="Select Party"
                                                    options={this.state.options}
                                                    value={this.state.partySelectedValue}
                                                    onChange={this.getPartyCodeDt}></Select>

                                            </div>
                                        </div>
                                    </Col>


                                    <Col md={2}>
                                        <Button outline color="secondary" size="sm" disabled><i class="fa fa-list"></i></Button>{' '}
                                        <Button onClick={this.refreshPartyDropdown.bind(this)} outline color="info"><i class="fa fa-refresh" aria-hidden="true"></i> </Button>
                                    </Col>


                                </Row>

                            }

                            {brandDropdownFilterFlg &&
                                <Row style={{ padding: "5px" }}>
                                    <Col className="d-flex justify-content-end align-items-center p-0" md={2}>
                                        <label className="m-0" style={{ fontWeight: "bold" }}>Brand </label>
                                    </Col>
                                    <Col md={8}>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <Select className="react-select" classNamePrefix="react-select" name="brandNm"
                                                    placeholder="Select Brand..."
                                                    options={this.state.options2}
                                                    value={this.state.brandSelectedValue}
                                                    onChange={this.getBrandNmDt}></Select>

                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            }

                            {orderFilterFlg &&
                                <Row style={{ padding: "5px" }}>
                                    <Col md={2}>
                                        <label style={{ fontWeight: "bold" }}>Order </label>
                                    </Col>

                                    <Col md={8}>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            value={this.state.fieldvalue.order}
                                            onChange={this.handleChangeEvent.bind(this, "order")}
                                            autoComplete="off"
                                            disabled
                                        />

                                    </Col>

                                    <Col md={2} >
                                        <Button outline color="secondary" size="sm" onClick={this.toggleOrder}><i class="fa fa-list"></i></Button>{' '}
                                        <Button onClick={this.refreshOrderText.bind(this)} outline color="info"><i class="fa fa-refresh" aria-hidden="true"></i> </Button>
                                    </Col>
                                </Row>
                            }

                            {mobileNoFilterFlg &&
                                <Row style={{ padding: "5px" }}>
                                    <Col md={2}>
                                        <label style={{ fontWeight: "bold" }}>Mobile No </label>
                                    </Col>

                                    <Col md={8}>
                                        <PhoneInput width={'100%'}
                                            country={'in'}
                                            value={this.state.fieldvalue.mobileNo}
                                            //   onChange={mobileno => this.setState({ mobileno })}
                                            onBlur={this.handleChangeMobileEvent.bind(this, "mobileNo")}
                                            disabled={isMobileDisabled}

                                        />

                                    </Col>

                                    <Col md={2} >
                                        <Button outline color="secondary" size="sm" onClick={this.toggleOrder}><i class="fa fa-list"></i></Button>{' '}
                                        <Button onClick={this.refreshOrderText.bind(this)} outline color="info"><i class="fa fa-refresh" aria-hidden="true"></i> </Button>
                                    </Col>
                                </Row>
                            }



                            {dealerMrpFliterFlg &&
                                <Row style={{ padding: "5px" }}>
                                    <Col md={2} sm={2} xs={2}>

                                    </Col>

                                    <Col md={3}>
                                        <input type="checkbox" defaultChecked={this.state.fieldvalue.dealerFlg}
                                            value={this.state.fieldvalue.dealerFlg} onClick={(e) => this.selectDealerMrp(e, 'dealerFlg')} />&nbsp;
                                        <label style={{ fontWeight: "bold" }}>Req. Dealer Price </label>
                                    </Col>


                                    <Col md={2}>
                                        <input type="checkbox" defaultChecked={this.state.fieldvalue.mrpFlg}
                                            value={this.state.fieldvalue.mrpFlg} onClick={(e) => this.selectDealerMrp(e, 'mrpFlg')} />&nbsp;
                                        <label style={{ fontWeight: "bold" }}>Req. MRP</label>

                                    </Col>

                                </Row>
                            }



                        </Col>
                    </Row>


                </Form>


                {this.state.reportData.xml == 'companyWiseOrderSummary' &&
                    <div className="table-responsive" style={{ marginTop: "10px" }}>

                        {this.state.reportListingData.length > 0 ?
                            <Row>
                                <Col xl={10}></Col>

                                <Col xl={2}>
                                    <Button onClick={this.export} outline color="secondary" >
                                        <i class="fa fa-file-excel-o" aria-hidden="true"></i>&nbsp;&nbsp;Export to Excel</Button>
                                    {/* <button className="k-button" onClick={this.export}><i class="fa fa-file-excel-o" aria-hidden="true"></i>Export to Excel</button> */}
                                </Col>

                                <Col xl={12}>
                                    <ExcelExport
                                        data={this.state.reportListingData}
                                        fileName="CompanyWiseOrderSummary.xlsx"
                                        ref={(exporter) => { this._exporter = exporter; }}
                                    >

                                        <ExcelExportColumn field="companyCode" title="Company" locked={true} width={100} />
                                        <ExcelExportColumn field="orderNo" title="Order No" />
                                        <ExcelExportColumn field="party" title="Party" />
                                        <ExcelExportColumn field="readyProductFlg" title="Ready Product Stock" />
                                        <ExcelExportColumn field="totQty" title="Quantity" />

                                    </ExcelExport>


                                    <ReactTable id="table-to-xls" className="orderList-table"
                                        data={this.state.reportListingData}
                                        columns={columns}
                                        filterable defaultFilterMethod={(filter, row) =>
                                            String(row[filter.id]) === filter.value}
                                        minRows={1}
                                        defaultPageSize={10}
                                        pageSizeOptions={[5, 25, 50, 100, 150, 250, 500]}
                                    />
                                </Col>
                            </Row>
                            : null}
                    </div>
                }


                {this.state.reportData.xml == 'clothRequirement' &&
                    <div className="table-responsive" style={{ marginTop: "10px" }}>
                        {this.state.reportListingData.length > 0 ?
                            <Row>

                                <Col xl={10}></Col>
                                <Col xl={12}>

                                    <DataGrid
                                        id="gridContainer"
                                        dataSource={this.state.reportListingData}
                                        keyExpr="product"
                                        showBorders={true}
                                        allowColumnReordering={true}
                                        onExporting={this.onExporting}
                                    >
                                        <FilterRow visible={true} applyFilter={'auto'} />
                                        <GroupPanel visible={true} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                                        <Grouping autoExpandAll={false} />

                                        <Column dataField="companyCode" />
                                        <Column dataField="brand" dataType="string" />
                                        <Column dataField="subBrand" dataType="string" />
                                        <Column dataField="pattern" dataType="string" />
                                        <Column dataField="product" dataType="string" />
                                        <Column dataField="supplierName" caption="Supplier Name" />
                                        <Column dataField="balancePoQty" caption="Balance Po Qty" />
                                        <Column dataField="qty" caption="Order Booked" />
                                        <Column dataField="selfQty" caption="Self Stock" />
                                        <Column dataField="netBal" caption="Net Balance" />
                                        <Column dataField="inHandQty" caption="Cloth in Hand" />

                                        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                                        <Paging defaultPageSize={10} />
                                        <Export enabled={true} formats={exportFormats} allowExportSelectedData={true} />
                                        <MasterDetail
                                            enabled={true}
                                            component={ProductStock}
                                        />

                                        <Summary>
                                            <GroupItem
                                                column="qty"
                                                summaryType="sum"
                                                showInGroupFooter={false}
                                                alignByColumn={true}
                                                displayFormat="{0}" />
                                            <GroupItem
                                                column="selfQty"
                                                summaryType="sum"
                                                showInGroupFooter={false}
                                                alignByColumn={true}
                                                displayFormat="{0}" />
                                            <GroupItem
                                                column="netBal"
                                                summaryType="sum"
                                                showInGroupFooter={false}
                                                alignByColumn={true}
                                                displayFormat="{0}" />
                                            <GroupItem
                                                column="inHandQty"
                                                summaryType="sum"
                                                showInGroupFooter={false}
                                                alignByColumn={true}
                                                displayFormat="{0}" />
                                        </Summary>

                                    </DataGrid>
                                </Col>
                            </Row>
                            : null}
                    </div>
                }



                {this.state.reportData.xml == 'orderDetailSummaryPartyWise' &&
                    <div className="table-responsive" style={{ marginTop: "10px" }}>

                        {this.state.reportListingData.length > 0 ?
                            <Row>
                                <Col xl={10}></Col>
                                <Col xl={12}>

                                    <DataGrid
                                        id="gridContainer"
                                        dataSource={this.state.reportListingData}
                                        showBorders={true}
                                        allowColumnReordering={true}
                                        onExporting={this.onExporting}
                                    >
                                        <Selection mode="multiple" />
                                        <GroupPanel visible={true} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                                        <Grouping autoExpandAll={false} />

                                        <Column dataField="partyCode" />
                                        <Column dataField="priority" dataType="string" />
                                        <Column dataField="sero" dataType="string" />
                                        <Column dataField="hv" dataType="string" />
                                        <Column dataField="freezone" dataType="string" />
                                        <Column dataField="freezoneBoys" dataType="string" />
                                        <Column dataField="fairdeal" dataType="string" />
                                        <Column dataField="seroTrousers" dataType="string" />
                                        <Column dataField="total" dataType="string" />
                                        <MasterDetail
                                            enabled={true}
                                            component={OrderSummarySubbrandWise}
                                        />
                                        <Summary>
                                            <TotalItem
                                                column="partyCode"
                                                summaryType="count" />
                                            <TotalItem
                                                column="savla"
                                                summaryType="sum"
                                            />
                                            <TotalItem
                                                column="sagi"
                                                summaryType="sum"
                                            />
                                            <TotalItem
                                                column="smart"
                                                summaryType="sum"
                                            />
                                            <TotalItem
                                                column="fashion"
                                                summaryType="sum"
                                            />
                                            <TotalItem
                                                column="span"
                                                summaryType="sum"
                                            />
                                            <TotalItem
                                                column="total"
                                                summaryType="sum"
                                            />
                                        </Summary>

                                        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                                        <Paging defaultPageSize={10} />
                                        <Export enabled={true} formats={exportFormats} allowExportSelectedData={true} />

                                    </DataGrid>
                                </Col>
                            </Row>
                            : null}
                        {/* {this.state.reportListingData.length > 0 ?
                            <Row>
                                <Col xl={10}></Col>
                                <Col xl={12}>

                                    <DataGrid
                                        id="gridContainer"
                                        dataSource={this.state.reportListingData}
                                        showBorders={true}
                                        allowColumnReordering={true}
                                        onExporting={this.onExporting}
                                    >
                                        <Selection mode="multiple" />
                                        <GroupPanel visible={true} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                                        <Grouping autoExpandAll={false} />

                                        <Column dataField="partyCode" />
                                        <Column dataField="priority" dataType="string" />
                                        <Column dataField="savla" dataType="string" />
                                        <Column dataField="sagi" dataType="string" />
                                        <Column dataField="smart" dataType="string" />
                                        <Column dataField="fashion" dataType="string" />
                                        <Column dataField="span" dataType="string" />
                                        <Column dataField="total" dataType="string" />

                                        <Summary>
                                            <TotalItem
                                                column="partyCode"
                                                summaryType="count" />
                                            <TotalItem
                                                column="savla"
                                                summaryType="sum"
                                            />
                                            <TotalItem
                                                column="sagi"
                                                summaryType="sum"
                                            />
                                            <TotalItem
                                                column="smart"
                                                summaryType="sum"
                                            />
                                            <TotalItem
                                                column="fashion"
                                                summaryType="sum"
                                            />
                                            <TotalItem
                                                column="span"
                                                summaryType="sum"
                                            />
                                            <TotalItem
                                                column="total"
                                                summaryType="sum"
                                            />
                                        </Summary>

                                        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                                        <Paging defaultPageSize={10} />
                                        <Export enabled={true} formats={exportFormats} allowExportSelectedData={true} />

                                    </DataGrid>
                                </Col>
                            </Row>
                            : null} */}
                    </div>
                }




                {this.state.reportData.xml == 'orderDetailSummaryGroupWise' &&
                    <div className="table-responsive" style={{ marginTop: "10px" }}>

                        {this.state.reportListingData.length > 0 ?
                            <Row>
                                <Col xl={10}></Col>
                                <Col xl={12}>

                                    <DataGrid
                                        id="gridContainer"
                                        dataSource={this.state.reportListingData}
                                        showBorders={true}
                                        allowColumnReordering={true}
                                        onExporting={this.onExporting}
                                    >
                                        <GroupPanel visible={true} />
                                        <Selection mode="multiple" />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                                        <Grouping autoExpandAll={true} />

                                        <Column dataField="company" groupIndex={0} />
                                        <Column dataField="subBrand" dataType="string" />
                                        <Column dataField="pcs" dataType="string" />

                                        <Summary>

                                            <GroupItem
                                                column="pcs"
                                                summaryType="sum"
                                                displayFormat="{0}"
                                                showInGroupFooter={true} />
                                        </Summary>

                                        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                                        <Paging defaultPageSize={10} />
                                        <Export enabled={true} formats={exportFormats} allowExportSelectedData={true} />

                                    </DataGrid>
                                </Col>
                            </Row>
                            : null}
                    </div>
                }




                {this.state.reportData.xml == 'orderStatusReports' &&
                    <>
                        <OrderStatusReport getOrderStatusReport={this.getOrderStatusReport} />

                        {this.state.reportListingData.length > 0 && this.state.generateBtnFlg && this.state.ordStatusReportNm !== 'partyOrderStatusReports' ?
                            <Row>
                                <Col xl={10}></Col>
                                <Col xl={12}>

                                    <DataGrid
                                        id="gridContainer"
                                        dataSource={this.state.reportListingData}
                                        showBorders={true}
                                        allowColumnReordering={true}
                                        onExporting={this.onExporting}
                                    >
                                        <GroupPanel visible={true} />
                                        <Selection mode="multiple" />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                                        <Grouping autoExpandAll={true} />

                                        <Column dataField="brand" groupIndex={0} />
                                        <Column dataField="subBrand" dataType="string" />
                                    <Column dataField="pcs" dataType="string" caption="Order Booked" />
                                    <Column dataField="readyPcs" dataType="string" caption="Ready" />
                                    <Column dataField="processPcs" dataType="string" caption="In Process" />
                                    <Column dataField="cuttingPcs" dataType="string" caption="Cutting" />
                                    <Column dataField="openPcs" dataType="string" caption="Open" />

                                        <Summary>

                                            <GroupItem
                                                column="pcs"
                                                summaryType="sum"
                                                displayFormat="{0}"
                                            showInGroupFooter={true} />
                                        <GroupItem
                                                column="readyPcs"
                                                summaryType="sum"
                                                displayFormat="{0}"
                                            showInGroupFooter={true} />
                                        <GroupItem
                                                column="processPcs"
                                                summaryType="sum"
                                                displayFormat="{0}"
                                            showInGroupFooter={true} />
                                        <GroupItem
                                                column="cuttingPcs"
                                                summaryType="sum"
                                                displayFormat="{0}"
                                            showInGroupFooter={true} />
                                        <GroupItem
                                                column="openPcs"
                                                summaryType="sum"
                                                displayFormat="{0}"
                                                showInGroupFooter={true} />
                                        </Summary>

                                        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                                        <Paging defaultPageSize={10} />
                                        <Export enabled={true} formats={exportFormats} allowExportSelectedData={true} />

                                    </DataGrid>
                                </Col>
                            </Row>
                            : null}



                        {this.state.reportListingData.length > 0 && !this.state.generateBtnFlg && this.state.ordStatusReportNm === 'partyOrderStatusReports' ?
                            <Row>
                                <Col xl={10}></Col>
                                <Col xl={12}>

                                    <DataGrid
                                        id="gridContainer"
                                        dataSource={this.state.reportListingData}
                                        showBorders={true}
                                        allowColumnReordering={true}
                                        onExporting={this.onExporting}
                                    >
                                        <GroupPanel visible={true} />
                                        <Selection mode="multiple" />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                                        <Grouping autoExpandAll={true} />

                                        <Column dataField="party" dataType="string" />
                                        <Column dataField="company" dataType="string" />
                                        <Column dataField="mainGroup" dataType="string" />
                                        <Column dataField="sent" dataType="string" />
                                        <Column dataField="pack" dataType="string" />
                                        <Column dataField="ready" dataType="string" />
                                        <Column dataField="proforma" dataType="string" />
                                        <Column dataField="semi" dataType="string" />
                                        <Column dataField="cutting" dataType="string" />

                                        <Summary>

                                            <GroupItem
                                                column="pcs"
                                                summaryType="sum"
                                                displayFormat="{0}"
                                                showInGroupFooter={true} />
                                        </Summary>

                                        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                                        <Paging defaultPageSize={10} />
                                        <Export enabled={true} formats={exportFormats} allowExportSelectedData={true} />

                                    </DataGrid>
                                </Col>
                            </Row>
                            : null}
                    </>
                }

                        {this.state.reportData.xml == 'orderBookSizeWise' && this.state.reportListingData.length > 0 &&
                            <Row>
                                <Col xl={10}></Col>
                                <Col xl={12}>

                                    <DataGrid
                                        id="gridContainer"
                                        dataSource={this.state.reportListingData}
                                        showBorders={true}
                                        allowColumnReordering={true}
                                        onExporting={this.onExporting}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        columnMinWidth={50}
                                        columnAutoWidth={true}
                                    >
                                        <GroupPanel visible={true} />
                                        {/* <Selection mode="multiple" /> */}
                                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                                        <Grouping autoExpandAll={true} />

                                        <Column dataField="productNm" dataType="string" />
                                        <Column dataField="color" dataType="string" />
                                        <Column dataField="pattern" dataType="string" />
                                        <Column dataField="mrp" dataType="number" />
                                        <Column dataField="brand" dataType="string" />
                                        <Column dataField="subbrand" dataType="string" />
                                        <Column dataField="fits" dataType="string" />
                                        <Column dataField="quality" dataType="string" />
                                        <Column dataField="total" dataType="number" />
                                        {this.state.sizeArray.map((item, i) => (
                                            <Column dataField={`size${i+1}`} caption={item} dataType="number" />
                                        ))}
                                        
                                        <Summary>

                                            <GroupItem
                                                column="pcs"
                                                summaryType="sum"
                                                displayFormat="{0}"
                                                showInGroupFooter={true} />
                                        </Summary>

                                        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                                        <Paging defaultPageSize={10} />
                                        <Export enabled={true} formats={exportFormats} allowExportSelectedData={true} />

                                    </DataGrid>
                                </Col>
                            </Row>
                        }


                <Modal isOpen={this.state.modalParty} toggle={this.toggleParty} className="modal-md modal-dialog-centered">
                    <ModalHeader toggle={this.toggleParty}></ModalHeader>
                    <Row style={{ marginLeft: "15px", marginRight: "15px", marginTop: "15px" }}>
                        <Col md={10}>
                            <h3 style={{ "text-align": "left", color: "#BFBFBD" }}>Party</h3>
                        </Col>
                    </Row>



                    <ModalBody>
                        <div className="success-screen">
                            <div className="row">
                                <div className="col-md-12">
                                    <ReactTable id="table-to-xls" className="orderList-table"
                                        data={this.state.partyList}
                                        columns={columnPartyFilter}
                                        filterable defaultFilterMethod={(filter, row) =>
                                            String(row[filter.id]) === filter.value}
                                        showPagination={false}
                                        defaultPageSize={this.state.partyList.length > 15 ? this.state.partyList.length : 15}
                                        style={{
                                            height: "400px"
                                        }}
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-12 text-right">
                                    <Button size="sm" outline color="secondary" onClick={this.refreshParty.bind(this)}><i className="fa fa-refresh"></i></Button>&nbsp;
                                    <Button size="sm" outline color="success" onClick={this.partySave}>Save</Button>&nbsp;
                                    <Button size="sm" outline color="secondary" onClick={this.partySave}>Close</Button>&nbsp;
                                </div>
                            </div>
                            <br />
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.modalOrder} toggle={this.toggleOrder} className="modal-lg modal-dialog-centered">
                    <ModalHeader toggle={this.toggleOrder}></ModalHeader>
                    <Row style={{ marginLeft: "15px", marginRight: "15px", marginTop: "15px" }}>
                        <Col md={10}>
                            <h3 style={{ "text-align": "left", color: "#BFBFBD" }}>Order</h3>
                        </Col>
                        {/* <Col md={2} >
                            <Button size="sm" onClick={this.orderSave}>Save</Button>
                        </Col> */}
                    </Row>

                    <ModalBody>
                        <div className="success-screen">
                            <div className="row">
                                <div className="col-md-12">
                                    <ReactTable
                                        data={this.state.orderList}
                                        columns={columnOrderFilter}
                                        filterable defaultFilterMethod={(filter, row) =>
                                            String(row[filter.id]) === filter.value}
                                        showPagination={true}
                                        // defaultPageSize={this.state.orderListSize > 15 ? this.state.orderListSize : 15}
                                        // style={{
                                        //     height: "400px"
                                        // }}
                                        defaultPageSize={10}
                                        pageSizeOptions={[5, 25, 50, 100, 150, 250, 500]}
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-12 text-right">
                                    <Button size="sm" outline color="secondary" onClick={this.orderLock}>Lock</Button>&nbsp;
                                    <Button size="sm" outline color="secondary" onClick={this.refreshOrder.bind(this)}><i className="fa fa-refresh"></i></Button>&nbsp;
                                    <Button size="sm" outline color="success" onClick={this.orderSave}>Save</Button>&nbsp;
                                    <Button size="sm" outline color="secondary" onClick={this.orderSave}>Close</Button>&nbsp;
                                </div>
                            </div>
                            <br />
                        </div>
                    </ModalBody>
                </Modal>



                <Modal isOpen={this.state.modalPleaseWait} toggle={this.togglePleaseWait} className="modal-login modal-dialog-centered">
                    <ModalHeader toggle={this.togglePleaseWait}>
                    </ModalHeader>
                    <ModalBody>
                        <h1 className="mb-0">Please Wait...</h1>
                    </ModalBody>
                </Modal>



            </Container>

        )
    }
}

export default ReportsNew;