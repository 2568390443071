import React, { Component } from 'react';
import { DataGrid, Column, MasterDetail } from 'devextreme-react/data-grid';
import userService from "../../services/user.services";
import { toast, ToastContainer } from 'react-toastify';
import AuthService from '../../services/auth.service';

export default class OrderSummarySubbrandWise extends Component {

    constructor(props) {
        super(props);

        console.log('xxx   ', props.data.key)
        this.getOrderSubbrandList(props.data.key.startDate, props.data.key.endDate, props.data.key.partyCond);
        this.state = {
            orderSubbrandList: [],
            productNm: props.data.key
        }

    }


    getOrderSubbrandList(startDate, endDate, partyCond) {

        userService.getOrderSubbrandList(startDate, endDate, partyCond).then(
            response => {

                if (response.data['status'] === 'SUCCESS') {
                    this.setState({ orderSubbrandList: JSON.parse(response.data['jsonObject']) })

                } else if (response.data['status'] === 'Error') {
                    toast.error(response.data['message']);
                }
            },

        )
    }
    render() {
        return (
            <React.Fragment>
                <div className="master-detail-caption">
                    {`Party Subbrand Wise Details`}
                </div>
                <DataGrid
                    dataSource={this.state.orderSubbrandList}
                    showBorders={true}
                    columnAutoWidth={false}
                >
                    <Column dataField="brand" width={'250px'} />
                    <Column dataField="subbrand" width={'250px'} />
                    <Column dataField="totalPcs" width={'100px'} />

                </DataGrid>
            </React.Fragment>
        )
    }
}
