import React, { Component } from 'react';
import { Col, Container, Row, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import userService from "../../services/user.services";
import ReactTable from 'react-table';
import { matchSorter } from 'match-sorter';
import AuthService from '../../services/auth.service';
import Select, { createFilter } from 'react-select';


const customStyles = {
    control: base => ({
        ...base,
        height: 48,
        minHeight: 48,
        maxMenuHeight: 200
    })
};

class SeasonWiseUserRights extends Component {

    constructor(props) {
        super(props);
        this.state = {
            seasonUserList: [],
            optionsSeason: [],
            value: null,
            seasonId: '',
            modal: false,
            userList: [],
            selectAllUserFlg: false,
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {

        if (this.state.modal == false) {
            this.getUserList();
        } else {
            this.setState(prevState => ({
                modal: !prevState.modal,
            }))
        }

    }

    getUserList() {
        userService.userList().then(response =>
            this.setState(prevState => ({
                userList: JSON.parse(response.data['jsonObject']),
                modal: !prevState.modal
            }))

        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {

                console.log('error ', error)
            }

        })

    }

    getActiveSeasonDropdownData() {
        AuthService.getActiveSeasonDropdownData().then(response =>
            this.setState({
                optionsSeason: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }

    getSeasonUserList(seasonId) {
        userService.getSeasonUserList(seasonId).then(response =>
            this.setState({
                seasonUserList: JSON.parse(response.data['jsonObject'])
            }),
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }

        })

    }

    componentWillMount() {
        this.getActiveSeasonDropdownData();
    }



    getAllDropdownFunc = value => {

        this.getSeasonUserList(value.value);
        this.setState({ seasonId: value.value, value: value, selectAllUserFlg: false });

    }

    addUser() {

        if (this.state.seasonId != '') {
            this.toggle();
        } else {
            alert('Please select season')
        }
    }

    refreshUser() {
        let users = this.state.userList;
        users.map((user) => {
            user.selectRowFlg = false;
        });
        this.setState({ ...this.state.userList, selectAllUserFlg: false });
    }


    selectAllRow = (e) => {
        let users = this.state.userList;
        users.map((user) => {
            user.selectRowFlg = e.target.checked;
        });
        this.setState({ ...this.state.userList, selectAllUserFlg: e.target.checked });
    }

    selectRow = (e, userId) => {
        let userArr = this.state.userList.filter(user => user.userId == userId);
        userArr.map((user) => {
            user.selectRowFlg = e.target.checked;
        })
        this.setState({ ...this.state.userList });
    }

    selectUserBookingRow = (e, userId) => {
        let userArr = this.state.userList.filter(user => user.userId == userId);
        userArr.map((user) => {
            user.bookingStudioFlg = e.target.checked;
        })
        this.setState({ ...this.state.userList });
    }


    selectBookingStudioRow = (e, rightsId) => {
        let rightsArr = this.state.seasonUserList.filter(rights => rights.id == rightsId);
        rightsArr.map((rights) => {
            rights.bookingStudioFlg = e.target.checked;
        })
        this.setState({ ...this.state.seasonUserList });


        userService.bookingStudioSave(rightsArr).then(
            response => {
                if (response.data['status'] === 'SUCCESS') {
                    // toast.success(response.data['message'])
                } else if (response.data['status'] === 'Error') {
                    toast.error(response.data['message']);
                }
            },

        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }





    seasonUserSave = () => {
        this.toggle();
        let users = this.state.userList.filter(user => user.selectRowFlg == true);

        userService.seasonUserSave(users, this.state.seasonId).then(
            response => {
                if (response.data['status'] === 'SUCCESS') {
                    toast.success(response.data['message']);
                    this.getSeasonUserList(this.state.seasonId);
                } else if (response.data['status'] === 'Error') {
                    toast.error(response.data['message']);
                }
            },

        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }

    deleteSeasonUserRights(e, rightsId) {
        userService.deleteSeasonUserRights(rightsId).then(response => {
            if (response.data['status'] == 'SUCCESS') {
                //   this.getProductList();
                toast.success(response.data['message']);

                let newUserData = this.state.seasonUserList.filter((user) => user.id !== rightsId);
                this.setState({
                    ...this.state,
                    seasonUserList: newUserData,

                })
            }
        }).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ');
                AuthService.logout();
                this.props.history.push('/');
            } else {
                console.log('error ', error)
            }

        })
    }

    render() {

        const userColumns = [
            {
                sortable: false,
                width: 50,
                Header: <input type="checkbox" defaultChecked={this.state.selectAllUserFlg} checked={this.state.selectAllUserFlg}
                    value={this.state.selectAllUserFlg} onClick={(e) => this.selectAllRow(e)} />,
                accessor: 'selectRowFlg',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <input type="checkbox" defaultChecked={props.original.selectRowFlg} checked={props.original.selectRowFlg}
                                value={props.original.selectRowFlg} onClick={(e) => this.selectRow(e, props.original.userId)}
                            />
                        </div>
                    )
                },
            },
            {
                sortable: false,
                Header: 'User Name',
                accessor: 'userName',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["userName"] }),
                filterAll: true,
            },
            {
                sortable: false,
                Header: 'Allow Booking At Studio',
                accessor: 'bookingStudioFlg',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <input type="checkbox" defaultChecked={props.original.bookingStudioFlg} checked={props.original.bookingStudioFlg}
                                value={props.original.bookingStudioFlg} onClick={(e) => this.selectUserBookingRow(e, props.original.userId)}
                            />
                        </div>
                    )
                },
            },
        ]


        const seasonUserColumns = [

            {
                sortable: false,
                Header: 'Season',
                accessor: 'season',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["season"] }),
                filterAll: true,
            },
            {
                sortable: false,
                Header: 'User Name',
                accessor: 'user',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["user"] }),
                filterAll: true,
            },
            {
                sortable: false,
                Header: 'Booking At Studio',
                accessor: 'bookingStudioFlg',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <input type="checkbox" defaultChecked={props.original.bookingStudioFlg} checked={props.original.bookingStudioFlg}
                                value={props.original.bookingStudioFlg} onClick={(e) => this.selectBookingStudioRow(e, props.original.id)}
                            />
                        </div>
                    )
                },
            },

            {
                Header: 'Action',
                accessor: 'action2',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <Button style={{ width: "80px" }} outline color="danger" onClick={(e) => this.deleteSeasonUserRights(e, props.original.id)}
                                href="#">Delete</Button>
                        </div>
                    )
                },
            }
        ]


        return (
            <Container style={{ border: "0.10px solid", color: 'grey', marginTop: '10px' }}>
                <ToastContainer autoClose={1000} draggable={false} />

                <Row>
                    <div className="col-md-4">
                        <h3 style={{ "text-align": "left", color: "navy" }}>Season User Rights </h3>
                    </div>
                </Row>

                <Row style={{ marginTop: '15px' }}>

                    <div className="form-group col-md-4">
                        <div className='row'>
                            <div className='col-md-3'>
                                <label style={{ fontWeight: "bold" }}>Season</label>
                            </div>
                            <div className='col-md-9'>
                                <Select name="seasonId" styles={customStyles}
                                    defaultValue="Select Season"
                                    options={this.state.optionsSeason}
                                    value={this.state.value}
                                    onChange={this.getAllDropdownFunc}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-1">
                        <Button size="sm" outline color="secondary" onClick={() => this.addUser()}>Add User</Button>
                    </div>

                </Row>

                <div className="row">
                    <div className="col-md-12">
                        <ReactTable
                            data={this.state.seasonUserList}
                            columns={seasonUserColumns}
                            filterable defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}
                            showPagination={true}
                            defaultPageSize={10}
                            pageSizeOptions={[10, 25, 50, 100, 150, 250, 500]}
                        />
                    </div>
                </div>
                <br />

                <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-login modal-dialog-centered">
                    <ModalHeader toggle={this.toggle}>
                        User List
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-12">
                                <ReactTable
                                    data={this.state.userList}
                                    columns={userColumns}
                                    filterable defaultFilterMethod={(filter, row) =>
                                        String(row[filter.id]) === filter.value}
                                    showPagination={false}
                                    defaultPageSize={this.state.userList.length > 15 ? this.state.userList.length : 15}
                                    style={{
                                        height: "400px"
                                    }}
                                />
                            </div>
                        </div>
                        <br />

                        <div className="row">
                            <div className="col-md-12 text-right">
                                <Button size="sm" outline color="secondary" onClick={this.refreshUser.bind(this)}><i className="fa fa-refresh"></i></Button>&nbsp;
                                <Button size="sm" outline color="success" onClick={this.seasonUserSave}>Save</Button>&nbsp;
                            </div>
                        </div>
                        <br />
                    </ModalBody>
                </Modal>


            </Container>
        )

    }
}

export default SeasonWiseUserRights;