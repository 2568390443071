import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import userService from "../../services/user.services";
import AuthService from '../../services/auth.service';
//import stockList from './stockList.json';

export default class AddStockOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            productStockData: [],
            sizesArr: [],
            stockProductNm: '',
            statusTypes: []
        };

        this.maxNum = this.maxNum.bind(this);
        this.insertSize = this.insertSize.bind(this);
        this.getShortProductWiseData = this.getShortProductWiseData.bind(this);
        this.submit = this.submit.bind(this);
    }


    componentDidMount() {
        this.nameInput.focus();
    }


    maxNum(sid, s, size) {
        const data = [];
        this.state.productStockData.map((item) => {
            if (item.stockId === sid && item.sleev === s) {
                data.push(item);
            }
        });
        return data[0][size];
    }

    insertSize(e, id, s, stat, stockId, sl, isBlur) {
        const data = [];
        let val = parseInt(e) || 0;
        this.state.productStockData.map((item) => {
            if (item.srNo === id) {
                data.push({ ...item, [s]: val });
            } else {
                data.push(item);
            }
        });
        const data2 = [];
        data.map((item) => {
            if (item.srNo === id) {
                let s1 = item.size1 && item.size1 > 0 ? item.size1 : 0;
                let s2 = item.size2 && item.size2 > 0 ? item.size2 : 0;
                let s3 = item.size3 && item.size3 > 0 ? item.size3 : 0;
                let s4 = item.size4 && item.size4 > 0 ? item.size4 : 0;
                let s5 = item.size5 && item.size5 > 0 ? item.size5 : 0;
                let s6 = item.size6 && item.size6 > 0 ? item.size6 : 0;
                let s7 = item.size7 && item.size7 > 0 ? item.size7 : 0;
                let s8 = item.size8 && item.size8 > 0 ? item.size8 : 0;
                let s9 = item.size9 && item.size9 > 0 ? item.size9 : 0;
                data2.push({ ...item, totPcs: s1 + s2 + s3 + s4 + s5 + s6 + s7 + s8 + s9 });
            } else {
                data2.push(item);
            }
        });
        this.setState({ productStockData: data2 });
        if (isBlur) {
            if (stat !== 'Open') {
                let max = this.maxNum(stockId, sl, s);
                if (!(val <= max)) {
                    alert('Insufficient Stock');
                    this.insertSize(0, id, s, stat, stockId, sl, false);
                }
            }
        }
    }

    // handleSearch(e) {
    //     console.log(e.target.value);
    // }

    // submit() {
    //     console.log(this.state.productStockData);
    // }



    getShortProductWiseData = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            
            if (e.target.value !== '') {
                this.setState({ stockProductNm: e.target.value })
                this.stockProductList(e.target.value);
            }
        }
    }

    stockProductList = (shortProductNm) => {

        userService.getShortProductWiseData(shortProductNm, localStorage.getItem('seasonId'),
            localStorage.getItem('bookingAtStudio'),this.props.orderMtId)
            .then(response => {
                if (response.data['status'] === 'SUCCESS') {

                    let data = []
                    let data2 = ['']
                    JSON.parse(response.data['jsonObject'])['stockList'].map(item => {
                        if (!data2[data2.length - 1].includes(item.status)) {
                            data2.push(item.status);
                            data.push(item.srNo)
                        }
                    })
                    this.setState({
                        productStockData: JSON.parse(response.data['jsonObject'])['stockList'],
                        sizesArr: JSON.parse(response.data['jsonObject'])['sizeArray'],
                        statusTypes: data
                    })

                    this.nameInput.value = '';
                    this.nameInput.focus();

                } else if (response.data['status'] === 'Error') {
                    toast.warn(response.data['message'])

                    this.nameInput.value = '';
                    this.nameInput.focus();
                }
            }).catch(error => {
                toast.warn("Error occured, contact support")
                    console.log('error ', error)
             

            })
    }

    submit = () => {
        let productData = this.state.productStockData.filter(item => item.totPcs > 0)

        userService.addStockProductToOrder(productData, this.props.orderMtId, localStorage.getItem('seasonId'))
            .then(response => {

                if (response.data['status'] === 'SUCCESS') {
                 
                    this.props.addProductJsonList(JSON.parse(response.data['jsonObject']));
                    this.stockProductList(this.state.stockProductNm);
                    this.props.getOrderDtWiseSummary(this.props.orderMtId);

                    let fieldvalue = this.props.fieldvalue;

                        fieldvalue['confirmStatus'] = '';
                        fieldvalue['draftStatus'] = 'selected';

                        this.setState({ fieldvalue });

                    
                    toast.success(response.data['message']);


                } else if (response.data['status'] === 'Error') {
                    toast.warn(response.data['message'])

                }
            })
    }

    render() {
        return (
            <div>
                <div className="mt-2">
                    <div className="col-3 my-2">
                        {/* <input
                            type="text"
                            onChange={this.getShortProductWiseData}
                            className="form-control"
                            disabled={this.props.orderLockFlg}
                        /> */}

                        <input className="form-control"
                            ref={(input) => { this.nameInput = input; }}

                            onKeyDown={this.getShortProductWiseData.bind(this)}
                            autoComplete="off"
                            disabled={this.props.orderLockFlg}
                        />
                    </div>
                </div>

                {this.state.productStockData.length > 0 &&
                    <>                    <Table responsive bordered size="md">
                        <thead>
                            <tr style={{ borderBottom: "2px solid #CACACA" }}>
                                <th>Short Product</th>
                                <th>Product</th>
                                <th>Sleev</th>
                                <th>Status</th>
                                {this.state.sizesArr.map(item => <th>{item}</th>)}
                                <th>Total Pieces</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.productStockData.map(item => {
                                return (
                                    <tr style={this.state.statusTypes.includes(item.srNo) ? { borderTop: "3px solid red" } : null}>
                                        <td style={{ width: "200px", verticalAlign: "middle" }}>{item.shortProductNm}</td>
                                        <td style={{ width: "200px", verticalAlign: "middle" }}>{item.productNm}</td>
                                        <td style={{ width: "100px", verticalAlign: "middle" }}>{item.sleev}</td>
                                        <td style={{ width: "150px", verticalAlign: "middle" }}>{item.status}</td>

                                        <td style={{ width: "100px" }} className={this.state.sizesArr.length >= 1 ? 'py-1 px-1' : 'd-none'}>
                                            <input
                                                disabled={item.nonEditableFlg || (item.status === "Open" && item.avSize1Flg) || item.size1Flg}
                                                type="text"
                                                className='w-100 py-2 text-center text-dark'
                                                style={item.nonEditableFlg || (item.status === "Open" && item.avSize1Flg) || item.size1Flg ? { border: "1px solid grey", borderRadius: "5px", backgroundColor: '#e9ecef' } : { border: "1px solid grey", borderRadius: "5px" }}
                                                value={item.size1}
                                                onBlur={e => {
                                                    this.insertSize(e.target.value, item.srNo, "size1", item.status, item.stockId, item.sleev, true)
                                                }}
                                                onChange={e => {
                                                    this.insertSize(e.target.value, item.srNo, "size1", item.status, item.stockId, item.sleev, false)
                                                }} />
                                        </td>

                                        <td style={{ width: "100px" }} className={this.state.sizesArr.length >= 2 ? 'py-1 px-1' : 'd-none'}>
                                            <input
                                                disabled={item.nonEditableFlg || (item.status === "Open" && item.avSize2Flg) || item.size2Flg}
                                                type="text"
                                                className='w-100 py-2 text-center text-dark'
                                                style={item.nonEditableFlg || (item.status === "Open" && item.avSize2Flg) || item.size2Flg ? { border: "1px solid grey", borderRadius: "5px", backgroundColor: '#e9ecef' } : { border: "1px solid grey", borderRadius: "5px" }}
                                                value={item.size2}
                                                onBlur={e => {
                                                    this.insertSize(e.target.value, item.srNo, "size2", item.status, item.stockId, item.sleev, true)
                                                }}
                                                onChange={e => {
                                                    this.insertSize(e.target.value, item.srNo, "size2", item.status, item.stockId, item.sleev, false)
                                                }} />
                                        </td>

                                        <td style={{ width: "100px" }} className={this.state.sizesArr.length >= 3 ? 'py-1 px-1' : 'd-none'}>
                                            <input
                                                disabled={item.nonEditableFlg || (item.status === "Open" && item.avSize3Flg) || item.size3Flg}
                                                type="text"
                                                className='w-100 py-2 text-center text-dark'
                                                style={item.nonEditableFlg || (item.status === "Open" && item.avSize3Flg) || item.size3Flg ? { border: "1px solid grey", borderRadius: "5px", backgroundColor: '#e9ecef' } : { border: "1px solid grey", borderRadius: "5px" }}
                                                value={item.size3}
                                                onBlur={e => {
                                                    this.insertSize(e.target.value, item.srNo, "size3", item.status, item.stockId, item.sleev, true)
                                                }}
                                                onChange={e => {
                                                    this.insertSize(e.target.value, item.srNo, "size3", item.status, item.stockId, item.sleev, false)
                                                }} />
                                        </td>

                                        <td style={{ width: "100px" }} className={this.state.sizesArr.length >= 4 ? 'py-1 px-1' : 'd-none'}>
                                            <input
                                                disabled={item.nonEditableFlg || (item.status === "Open" && item.avSize4Flg) || item.size4Flg}
                                                type="text"
                                                className='w-100 py-2 text-center text-dark'
                                                style={item.nonEditableFlg || (item.status === "Open" && item.avSize4Flg) || item.size4Flg ? { border: "1px solid grey", borderRadius: "5px", backgroundColor: '#e9ecef' } : { border: "1px solid grey", borderRadius: "5px" }}
                                                value={item.size4}
                                                onBlur={e => {
                                                    this.insertSize(e.target.value, item.srNo, "size4", item.status, item.stockId, item.sleev, true)
                                                }}
                                                onChange={e => {
                                                    this.insertSize(e.target.value, item.srNo, "size4", item.status, item.stockId, item.sleev, false)
                                                }} />
                                        </td>

                                        <td style={{ width: "100px" }} className={this.state.sizesArr.length >= 5 ? 'py-1 px-1' : 'd-none'}>
                                            <input
                                                disabled={item.nonEditableFlg || (item.status === "Open" && item.avSize5Flg) || item.size5Flg}
                                                type="text"
                                                className='w-100 py-2 text-center text-dark'
                                                style={item.nonEditableFlg || (item.status === "Open" && item.avSize5Flg) || item.size5Flg ? { border: "1px solid grey", borderRadius: "5px", backgroundColor: '#e9ecef' } : { border: "1px solid grey", borderRadius: "5px" }}
                                                value={item.size5}
                                                onBlur={e => {
                                                    this.insertSize(e.target.value, item.srNo, "size5", item.status, item.stockId, item.sleev, true)
                                                }}
                                                onChange={e => {
                                                    this.insertSize(e.target.value, item.srNo, "size5", item.status, item.stockId, item.sleev, false)
                                                }} />
                                        </td>

                                        <td style={{ width: "100px" }} className={this.state.sizesArr.length >= 6 ? 'py-1 px-1' : 'd-none'}>
                                            <input
                                                disabled={item.nonEditableFlg || (item.status === "Open" && item.avSize6Flg) || item.size6Flg}
                                                type="text"
                                                className='w-100 py-2 text-center text-dark'
                                                style={item.nonEditableFlg || (item.status === "Open" && item.avSize6Flg) || item.size6Flg ? { border: "1px solid grey", borderRadius: "5px", backgroundColor: '#e9ecef' } : { border: "1px solid grey", borderRadius: "5px" }}
                                                value={item.size6}
                                                onBlur={e => {
                                                    this.insertSize(e.target.value, item.srNo, "size6", item.status, item.stockId, item.sleev, true)
                                                }}
                                                onChange={e => {
                                                    this.insertSize(e.target.value, item.srNo, "size6", item.status, item.stockId, item.sleev, false)
                                                }} />
                                        </td>

                                        <td style={{ width: "100px" }} className={this.state.sizesArr.length >= 7 ? 'py-1 px-1' : 'd-none'}>
                                            <input
                                                disabled={item.nonEditableFlg || (item.status === "Open" && item.avSize7Flg) || item.size7Flg}
                                                type="text"
                                                className='w-100 py-2 text-center text-dark'
                                                style={item.nonEditableFlg || (item.status === "Open" && item.avSize7Flg) || item.size7Flg ? { border: "1px solid grey", borderRadius: "5px", backgroundColor: '#e9ecef' } : { border: "1px solid grey", borderRadius: "5px" }}
                                                value={item.size7}
                                                onBlur={e => {
                                                    this.insertSize(e.target.value, item.srNo, "size7", item.status, item.stockId, item.sleev, true)
                                                }}
                                                onChange={e => {
                                                    this.insertSize(e.target.value, item.srNo, "size7", item.status, item.stockId, item.sleev, false)
                                                }} />
                                        </td>

                                        <td style={{ width: "100px" }} className={this.state.sizesArr.length >= 8 ? 'py-1 px-1' : 'd-none'}>
                                            <input
                                                disabled={item.nonEditableFlg || (item.status === "Open" && item.avSize8Flg) || item.size8Flg}
                                                type="text"
                                                className='w-100 py-2 text-center text-dark'
                                                style={item.nonEditableFlg || (item.status === "Open" && item.avSize8Flg) || item.size8Flg ? { border: "1px solid grey", borderRadius: "5px", backgroundColor: '#e9ecef' } : { border: "1px solid grey", borderRadius: "5px" }}
                                                value={item.size8}
                                                onBlur={e => {
                                                    this.insertSize(e.target.value, item.srNo, "size8", item.status, item.stockId, item.sleev, true)
                                                }}
                                                onChange={e => {
                                                    this.insertSize(e.target.value, item.srNo, "size8", item.status, item.stockId, item.sleev, false)
                                                }} />
                                        </td>

                                        <td style={{ width: "100px" }} className={this.state.sizesArr.length >= 9 ? 'py-1 px-1' : 'd-none'}>
                                            <input
                                                disabled={item.nonEditableFlg || (item.status === "Open" && item.avSize9Flg) || item.size9Flg}
                                                type="text"
                                                className='w-100 py-2 text-center text-dark'
                                                style={item.nonEditableFlg || (item.status === "Open" && item.avSize9Flg) || item.size9Flg ? { border: "1px solid grey", borderRadius: "5px", backgroundColor: '#e9ecef' } : { border: "1px solid grey", borderRadius: "5px" }}
                                                value={item.size9}
                                                onBlur={e => {
                                                    this.insertSize(e.target.value, item.srNo, "size9", item.status, item.stockId, item.sleev, true)
                                                }}
                                                onChange={e => {
                                                    this.insertSize(e.target.value, item.srNo, "size9", item.status, item.stockId, item.sleev, false)
                                                }} />
                                        </td>
                                        <td style={{ width: "150px" }}>{item.totPcs}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>

                        <div className='w-100 d-flex justify-content-start'>
                            <button className='btn btn-outline-primary mx-3' onClick={this.submit}>Add to Product</button>
                        </div>
                    </>

                }
            </div>
        )
    }
}