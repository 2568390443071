import React, { Component } from 'react';


class SavlaHomePage extends Component {

   
    render() {

        return (
            <div>

            </div>
        )
    }
}

export default SavlaHomePage;