import React, { Component } from "react";
import { Col, Container, Row, Button } from 'reactstrap';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import userService from "../../services/user.services";
import { toast, ToastContainer } from 'react-toastify';
import AuthService from '../../services/auth.service';
import Select, { createFilter } from 'react-select';
import ReactTable from 'react-table';
import { matchSorter } from 'match-sorter';

const customStyles = {
    control: base => ({
        ...base,
        height: 48,
        minHeight: 48,
        maxMenuHeight: 200
    })
};

class OrderXml extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            companyCodeData: [],
            optionsCompanyCode: [],
            companyCodeSelectedValue: '',
            fromOrder: '',
            toOrder: '',
            selectRadioBtn: '',
            companyCode: '',
            orderList: [],
            selectAllOrderFlg: false,
        }
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.fromOrder != '' && this.state.toOrder != '' && this.state.selectRadioBtn != '' && this.state.companyCode != '') {
            userService.getOrderListForXml(this.state.fromOrder, this.state.toOrder, this.state.selectRadioBtn,
                this.state.companyCode, localStorage.getItem('seasonId')).then(response => {
                    if (response.data['status'] === 'SUCCESS') {
                        this.setState({ orderList: JSON.parse(response.data['jsonObject']) })
                    }
                }
                ).catch(error => {

                    if (error.response.status === 403) {
                        alert('Your Login Session Is Expire ,Please Login ')
                        AuthService.logout()
                        this.props.history.push('/')

                    } else {
                        console.log('error ', error)
                    }
                })
        } else {
            alert('All records are compulsory');
        }


    }


    handleChangeEvent(field, e) {
        if (field === 'fromOrder') {
            this.setState({ fromOrder: e.target.value });
        } else if (field === 'toOrder') {
            this.setState({ toOrder: e.target.value });
        }
    }

    getCompanyCodeDropdownData() {
        userService.getCompanyCodeDropdownData().then(response =>
            this.setState({
                companyCodeData: JSON.parse(response.data.jsonObject),
                optionsCompanyCode: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }

    componentWillMount() {
        this.getCompanyCodeDropdownData();
    }

    getAllDropdownFunc(value, field) {
        this.setState({ companyCodeSelectedValue: value, companyCode: value.value });
    }

    selectHandleChangeEvent(field, e) {
        this.setState({ selectRadioBtn: e.target.value });
    }

    selectAllOrderRow = (e) => {
        let orders = this.state.orderList;
        orders.map((order) => {
            order.selectRowFlg = e.target.checked;
        });
        this.setState({ ...this.state.orderList, selectAllOrderFlg: e.target.checked });

    }

    selectOrderRow = (e, orderMtId) => {
        let orderArr = this.state.orderList.filter(order => order.orderMtId == orderMtId);
        orderArr.map((order) => {
            if (order.orderMtId == orderMtId) {
                order.selectRowFlg = e.target.checked;
            }
        })
        this.setState({ ...this.state.orderList });
    }

    generateXml(e) {

        let orderArr = this.state.orderList.filter(order => order.selectRowFlg === true);

        let orderMtIdString = '';
        orderArr.map((order) => {
            if (orderMtIdString != '') {
                orderMtIdString += "," + order.orderMtId;
            } else {
                orderMtIdString = order.orderMtId;
            }
        })

        if (orderMtIdString != '') {
            userService.genarateMultiOrderXml(orderMtIdString, this.state.companyCode).then(response => {
                if (response.data['status'] === 'SUCCESS') {
                    this.handleSubmitXml(response.data['jsonObject']);

                }
            }

            ).catch(error => {
                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')
                } else {
                    console.log('error ', error)
                }
            })
        } else {
            alert('Please select order list');
        }
    }

    updateOrderPendingFlag(orderMtIds, companyCode) {
        userService.updateOrderPendingFlag(orderMtIds).then(response => {

        }

        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }

    handleSubmitXml(repornm) {
        let link = document.createElement('a');
        link.href = '../pdf/' + repornm;
        link.download = repornm;
        link.dispatchEvent(new MouseEvent('click'));
        userService.downloadOrderReport(repornm).then(response => {
        }).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }

        })
    }

    render() {

        const columnOrderFilter = [

            {
                sortable: false,
                width: 50,
                Header: <input type="checkbox" defaultChecked={this.state.selectAllOrderFlg} checked={this.state.selectAllOrderFlg}
                    value={this.state.selectAllOrderFlg} onClick={(e) => this.selectAllOrderRow(e)} />,
                accessor: 'selectRowFlg',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <input type="checkbox" defaultChecked={props.original.selectRowFlg} checked={props.original.selectRowFlg}
                                value={props.original.selectRowFlg} onClick={(e) => this.selectOrderRow(e, props.original.orderMtId)}
                            />
                        </div>
                    )
                },
            },
            {
                sortable: false,
                Header: 'Order',
                accessor: 'orderNo',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["orderNo"] }),
                filterAll: true,
            },
            {
                sortable: false,
                Header: 'Order Date',
                accessor: 'orderDate',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["orderDate"] }),
                filterAll: true,
            },
            {
                sortable: false,
                Header: 'Party',
                accessor: 'partyCode',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["partyCode"] }),
                filterAll: true,
            },
            {
                sortable: false,
                Header: 'Status',
                accessor: 'status',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["status"] }),
                filterAll: true,
            },
            {
                sortable: false,
                Header: 'Created By',
                accessor: 'createdBy',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["createdBy"] }),
                filterAll: true,
            },
            {
                sortable: false,
                Header: 'Modify By',
                accessor: 'modifyBy',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["modifyBy"] }),
                filterAll: true,
            },

        ]

        return (
            <Container style={{ border: "0.10px solid", color: 'grey', marginTop: '10px' }}>
                <Row style={{ paddingTop: "10px" }}>
                    <Col md={10}>
                        <h3 style={{ "text-align": "left", color: "navy" }}> Order XML </h3>
                    </Col>
                    <Col md={2}>
                        <Button outline color="success" onClick={this.generateXml.bind(this)}>Generate XML</Button>
                    </Col>
                </Row>
                <hr style={{ color: "red" }}></hr>


                <Form onSubmit={this.handleSubmit}>
                    <div className="row">

                        <div className="form-group col-md-4">
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label style={{ fontWeight: "bold" }}>Order From</label>
                                </div>
                                <div className='col-md-7'>
                                    <Input type="text"
                                        className="form-control"
                                        value={this.state.fromOrder}
                                        onChange={this.handleChangeEvent.bind(this, 'fromOrder')}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-md-4">
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label style={{ fontWeight: "bold" }}>Order To</label>
                                </div>
                                <div className='col-md-7'>
                                    <Input type="text"
                                        className="form-control"
                                        value={this.state.toOrder}
                                        onChange={this.handleChangeEvent.bind(this, 'toOrder')}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-md-4" >
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="Pending" name="Pending" class="custom-control-input" value="Pending" onChange={this.selectHandleChangeEvent.bind(this, "pending")} checked={this.state.selectRadioBtn === "Pending"}></input>
                                <label class="custom-control-label" for="Pending" >Pending</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="Genarated" name="Genarated" class="custom-control-input" value="Genarated" onChange={this.selectHandleChangeEvent.bind(this, "Genarated")} checked={this.state.selectRadioBtn === "Genarated"}></input>
                                <label class="custom-control-label" for="Genarated">Genarated</label>
                            </div>
                        </div>

                        <div className="form-group col-md-4">
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label style={{ fontWeight: "bold" }}>Company</label>
                                </div>
                                <div className='col-md-7'>
                                    <Select name="companyCode" styles={customStyles}
                                        defaultValue="Select Company"
                                        options={this.state.optionsCompanyCode}
                                        value={this.state.companyCodeSelectedValue}
                                        onChange={(e) => this.getAllDropdownFunc(e, 'companyCode')}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="form-group col-md-4">
                            <Button outline color="success" type="submit">Get Order List</Button>
                        </div>


                    </div>

                    <Row>
                        <div className="col-md-12">
                            <ReactTable
                                data={this.state.orderList}
                                columns={columnOrderFilter}
                                filterable defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value}
                                showPagination={false}
                                defaultPageSize={this.state.orderList.length > 15 ? this.state.orderList.length : 15}
                                style={{
                                    height: "400px"
                                }}
                            />
                        </div>
                    </Row>



                </Form>




            </Container>
        )
    }
}

export default OrderXml;