import React, { Component } from "react";
import userService from "../../services/user.services";
import AuthService from '../../services/auth.service';
import ReactTable from 'react-table';
import Select, { createFilter } from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { Col, Container, Row, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

class RoleRights extends Component {

    constructor(props) {
        super(props);
        this.state = {
            roleRightsList: [],
            roleRightsArrays: [],
            roleMastData: [],
            value: null,
            options: [],
            roleRightJsonData: [],
            roleMastId: ''
        }

        this.selectRow = this.selectRow.bind(this);
    }

    getRoleRightList(roleMastId) {
        userService.getRoleRightList(roleMastId).then(response =>
            this.setState({
                roleRightsList: JSON.parse(response.data['jsonObject'])
            }),
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }

        })

    }

    componentWillMount() {
        this.getRoleMastData();
    }

    getRoleMastData() {
        userService.getRoleMastData().then(response =>
            this.setState({
                roleMastData: JSON.parse(response.data.jsonObject),
                options: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {

                console.log('error ', error)
            }

        })
    }

    getRolemastVal = value => {
        this.getRoleRightList(value.value);
        this.setState({ value: value, roleMastId: value.value });
    }


    selectAllRow = (e) => {
        let roleRights = this.state.roleRightsList;
        roleRights.map((role) => {
            role.menuFlg = e.target.checked;
        });
        this.setState({ ...this.state.roleRightsList });
    }


    selectRow = (e, menumastId) => {

        let roleRightsArr = this.state.roleRightsList.filter(role => role.id == menumastId);
        roleRightsArr.map((role) => {
            if (role.id == menumastId) {
                role.menuFlg = e.target.checked;
            }
        })

        this.setState({ ...this.state.roleRightsList, roleRightJsonData: roleRightsArr });

    }


    saveRoleRights() {
        userService.saveRoleRights(this.state.roleRightsList, this.state.roleMastId)
            .then(response => {
                if (response.data['status'] === 'SUCCESS') {
                    toast.success(response.data['message']);
                } else if (response.data['status'] === 'Error') {
                    toast.error(response.data['message']);
                }
            }

            ).catch(error => {
                console.log('error     ', error);
            })
    }
    render() {
        const filterConfig = { matchFrom: 'start' }
        const columns = [
            {
                width: 40,
                Header: <input type="checkbox" onClick={(e) => this.selectAllRow(e)} />,
                accessor: 'selectRowFlg',
                Cell: props => {
                    return (
                        <div>
                            <input type="checkbox" onClick={(e) => this.selectRow(e, props.original.id)} checked={props.original.inProcessFlg}
                                defaultChecked={props.original.menuFlg} />
                        </div>
                    )
                },
            },
            {
                sortable: false,
                Header: <div style={{ 'textAlign': 'left' }}>Menu</div>,
                accessor: 'menuHeading'
            }
        ]

        return (

            <div className="container">

                <ToastContainer autoClose={1000} draggable={false} />

                <div className="section-ptb" style={{ padding: "10px" }}>
                  
                        <Row style={{ paddingTop: "10px" }}>
                            <div className="col-md-10">
                                <h3 style={{ "font-weight": "bolder", "text-align": "left", color: "#BFBFBD" }}> RoleRights </h3>
                            </div>
                            <div className="col-md-2">
                                <Button outline color="success" onClick={() => this.saveRoleRights()}>Save</Button>
                            </div>
                        </Row>

                        <div className="row">
                            <div className="col-md-1" style={{ textAlign: "center", marginTop: "10px" }}>
                                <h4><strong>Role</strong> </h4>
                            </div>
                            <div className="col-md-4">
                                <Select name="roleMastId"
                                    defaultValue="Select Role"
                                    filterOption={createFilter(filterConfig)}
                                    options={this.state.options}
                                    value={this.state.value}
                                    onChange={this.getRolemastVal}
                                ></Select>
                            </div>
                        </div>

                        <div className="row" style={{ textAlign: "left", marginTop: "10px" }}>
                            <div className="col-md-7">
                                <ReactTable className="orderList-table"
                                    data={this.state.roleRightsList}
                                    columns={columns}
                                    minRows={1}
                                    defaultPageSize={25}

                                />
                            </div>

                        </div>

                 

                </div>
            </div>

        )
    }
}

export default RoleRights;