/**
 *  Header Main
 */
import classnames from 'classnames';
import React, { Fragment } from 'react';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import {
    Col, Collapse, Container, DropdownItem, DropdownMenu, Modal, ModalBody, ModalHeader, Nav, Navbar, NavbarToggler,
    NavItem, NavLink, Row, TabContent, TabPane, UncontrolledDropdown, Dropdown, DropdownToggle
} from 'reactstrap';
import AllProduct from '../../api/product';
import logo from '../../assets/images/Sero Bazaar.png';
//import navLinks from '../../NavLinks.js';
import AuthService from "../../services/auth.service";
import { toast, ToastContainer } from 'react-toastify';
import Select, { createFilter } from 'react-select';
import authService from '../../services/auth.service';
import { Divider } from 'rsuite';

const customStyles = {
    control: base => ({
        ...base,
        height: 48,
        minHeight: 48,
        maxMenuHeight: 200
    })
};

class Header extends React.Component {
    constructor(props) {

        super(props);
        this.ReadCartItems = this.ReadCartItems.bind(this);
        this.ReadWishListItems = this.ReadWishListItems.bind(this);
        this.toggle = this.toggle.bind(this);
        this.state = {
            timeout: true,
            modal: false,
            activeTab: '1',
            isOpen: false,
            collapsed: true,
            CartHide: true,
            classset: '',
            getproduct: AllProduct,
            dropdownOpen: false,
            isOpen1: false,
            username: '',
            password: '',
            seasonId: '',
            submitted: false,
            menuList: [],
            optionsSeason: [],
            value: null,
            bookingStudioFlg: false,
            bookingStudioFlgDisable: true

        }
        var removeFromCart, removeFromWishList;
        this.toggle = this.toggle.bind(this);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.ShowCart = this.ShowCart.bind(this);
        this.toggle2 = this.toggle2.bind(this);
        this.toggle3 = this.toggle3.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }



    getActiveSeasonDropdownData() {
        authService.getActiveSeasonDropdownData().then(response =>
            this.setState({
                optionsSeason: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })
    }

    getuserWiseSeasonData(val) {

        authService.getuserWiseSeasonData(val).then(response =>
            this.setState({
                optionsSeason: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }
        })

    }

    handleChange(e) {
        const { name, value } = e.target;

        if (e.target.name == 'username') {
            this.getuserWiseSeasonData(e.target.value);
        }

        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({
            message: "",
            loading: true,
            submitted: true
        });

        if (this.state.username, this.state.password, this.state.seasonId) {

            AuthService.login(this.state.username, this.state.password, this.state.seasonId, this.state.bookingStudioFlg).then(
                (data) => {
                    if (data.accessToken && data.id != null) {
                        this.toggle();
                        window.location.reload();
                    } else {
                        this.setState({
                            loading: false,
                            message: 'User already registered. Activation Pending.'
                        });
                    }

                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.setState({
                        loading: false,
                        message: resMessage
                    });
                }
            ).catch(error => {

                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')

                } else {

                    console.log('error ', error)
                }

            })

        }


    }


    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    toggle2() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    toggle3() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    logintoggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    ReadCartItems() {
        return JSON.parse(localStorage.getItem("LocalCartItems"));
    }
    removeFromCart = (Index) => {
        var UpdatedCart = JSON.parse(localStorage.getItem("LocalCartItems"));
        UpdatedCart = UpdatedCart.slice(0, Index).concat(UpdatedCart.slice(Index + 1, UpdatedCart.length));
        localStorage.removeItem("LocalCartItems");
        localStorage.setItem("LocalCartItems", JSON.stringify(UpdatedCart));
    }

    ReadWishListItems() {
        return JSON.parse(localStorage.getItem("LocalWishListItems"));
    }

    toggle() {
        //   this.getActiveSeasonDropdownData();
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    menuListByRole() {
        AuthService.menuListByRole().then(response => {
            this.setState({ menuList: JSON.parse(response.data.jsonObject) })
        }
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }

        })
    }

    componentDidMount() {
        if (localStorage.getItem("user") != undefined) {
            this.menuListByRole();
        }

        //   window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        //  window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event) {
        var scrollTop = (document.documentElement && document.documentElement.scrollTop) ||
            document.body.scrollTop;

        if (scrollTop > 100) {
            document.getElementById("site-header").setAttribute("class", "site-header header-style-menu-center is-sticky");
        } else {
            document.getElementById("site-header").setAttribute("class", "site-header header-style-menu-center");
        }
    }

    ShowCart() {
        if (this.state.CartHide == true) {
            var elm = document.getElementById("DivCartContent");
            if (elm != null) {
                document.getElementById("DivCartContent").setAttribute("style", "display:block");
                this.state.CartHide = false;
            }
        }
    }

    HideCart() {
        var elm = document.getElementById("DivCartContent");
        if (elm != null) {
            document.getElementById("DivCartContent").setAttribute("style", "display:none");
            this.state.CartHide = true;
        }
    }

    closeNavbar() {
        if (this.state.collapsed !== true) {
            this.toggleNavbar();
        }
    }
    onClickClassAdd(pages) {
        if (this.state.classset != pages) {
            this.setState({
                ...this.state,
                classset: pages
            })
        }
        else {
            if (Object.keys(this.state.classset).length == 0) {
                this.setState({
                    ...this.state,
                    classset: pages
                })
            }
            else {
                this.setState({
                    ...this.state,
                    classset: ''
                })
            }
        }

    }


    OpenSubmenuOpen(id) {
        var elm = document.getElementById(id);
        if (elm != null) {
            document.getElementById(id).setAttribute("class", "dropdown-menu dropdown-menu-right show")
        }
    }

    OpenSubmenuClose(id) {
        var elm = document.getElementById(id);
        if (elm != null) {
            document.getElementById(id).setAttribute("class", "dropdown-menu dropdown-menu-right")
        }
    }



    logOut() {
        AuthService.logout();
    }

    getAllDropdownFunc = value => {
        AuthService.checkBookingAtStudio(value.value, this.state.username).then(response => {
            this.setState({ bookingStudioFlgDisable: JSON.parse(response.data.jsonObject) });
        }
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }

        })
        this.setState({ seasonId: value.value, value: value })
    }

    selectBookingStudioRow = (e) => {
        this.setState({ bookingStudioFlg: e.target.checked })
    }


    render() {

        const { loggingIn } = this.props;
        const { username, password, submitted, seasonId, bookingStudioFlg, bookingStudioFlgDisable } = this.state;

        let pathnames = document.location.href;
        let pathArray = pathnames.split('/');
        let pageName = '/' + pathArray[pathArray.length - 1];
        var searchName;
        if (localStorage.getItem("user") == undefined) {
            this.props.currentUserNm = "";
            this.props.companyAdmin = "";
        }
        if (pageName == '/topbar-with-load-more') {
            searchName = "/topbar-with-load-more"
        }
        else if (pageName == '/sidebar-without-lazyload') {
            searchName = "/sidebar-without-lazyload"
        }
        else if (pageName == '/topbar-without-lazyload') {
            searchName = "/topbar-without-lazyload"
        }
        else if (pageName == '/sidebar-with-lazyload') {
            searchName = "/sidebar-with-lazyload"
        }
        else {
            searchName = "/sidebar-with-load-more"
        }
        if (this.state.timeout == true) {
            setTimeout(function () {
                this.setState({ timeout: false });
            }.bind(this), 2000);  // wait 5 seconds, then reset to false
        }

        return (
            <header className="site-header header-style-menu-center" id="site-header">
                {this.state.timeout == false ?

                    <div>
                        <ToastContainer autoClose={2000} draggable={false} />

                        <div>
                            <Row>
                                <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-login modal-dialog-centered">
                                    <ModalHeader toggle={this.toggle}>
                                        <h4 className="mb-0">Login</h4>
                                    </ModalHeader>
                                    <ModalBody>

                                        <form name="form" onSubmit={this.handleSubmit} >
                                            <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                                                <label htmlFor="username">Username</label>
                                                {/* <input type="text" className="form-control" name="username" value={username}
                                                    onChange={this.handleChange} autoComplete="off"

                                                /> */}
                                                <input className="form-control" name="username"
                                                    onBlur={this.handleChange}
                                                    autoComplete="off"
                                                />
                                                {submitted && !username &&
                                                    <div className="alert alert-danger" role="alert">
                                                        This field is required!
                                                    </div>
                                                }
                                            </div>
                                            <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                                <label htmlFor="password">Password</label>
                                                <input type="password" className="form-control" name="password"
                                                    onBlur={this.handleChange}

                                                />
                                                {submitted && !password &&
                                                    <div className="alert alert-danger" role="alert">
                                                        This field is required!
                                                    </div>
                                                }
                                            </div>
                                            <div className={'form-group' + (submitted && !seasonId ? ' has-error' : '')}>
                                                <label htmlFor="seasonId">Season</label>
                                                <Select name="seasonId" styles={customStyles}
                                                    defaultValue="Select Season"
                                                    options={this.state.optionsSeason}
                                                    value={this.state.value}
                                                    onChange={this.getAllDropdownFunc}
                                                />
                                                {submitted && !seasonId &&
                                                    <div className="alert alert-danger" role="alert">
                                                        This field is required!
                                                    </div>
                                                }

                                            </div>
                                            <div className={'form-group'}>
                                                <input type="checkbox" defaultChecked={bookingStudioFlg}
                                                    onClick={(e) => this.selectBookingStudioRow(e)} disabled={bookingStudioFlgDisable}
                                                />&nbsp;&nbsp;<label htmlFor="allowBookingAtStudio">Allow Booking At Studio</label>


                                            </div>
                                            <div className="form-group">
                                                <button className="btn btn-primary">Login</button>
                                                {loggingIn &&
                                                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                }

                                            </div>

                                            <div className="form-group">

                                                {/* <a onClick={() => this.forgotPassword()} href="#">Forgot your password?</a> */}
                                            </div>

                                            {this.state.message && (
                                                <div className="form-group">
                                                    <div className="alert alert-danger" role="alert">
                                                        {this.state.message}
                                                    </div>
                                                </div>
                                            )}

                                        </form>
                                    </ModalBody>
                                </Modal>
                                <div className="col-12">
                                    <div className="mobile-menu" id="mobileMenu" />
                                </div>
                            </Row>
                        </div>

                        <div className="header-main header-main-bg-color-default">
                            <div className="container-fluid">
                                <Row>
                                    <Col lg={12}>
                                        <div className="row align-items-center justify-content-md-center">
                                            <Col xl={2} lg={2} className="col-6">
                                                <div className="logo-wrapper">
                                                    <Link to="/">
                                                        <img className="img-fluid" src={logo} alt="logo" />
                                                    </Link>
                                                </div>
                                                <div className="clearfix" />
                                            </Col>

                                            <div className="col" id="mainMenu">
                                                <div className="header-nav header-nav-bg-color-default">
                                                    <div className="header-nav-wrapper">
                                                        <Container>
                                                            {this.props.currentUserNm ? (

                                                                <Row>
                                                                    <div className="col-12">
                                                                        <div className="primary-nav">
                                                                            <div className="primary-nav-wrapper">
                                                                                <nav className="mega-menu">
                                                                                    <div class="menu-list-items">
                                                                                        <Navbar light expand="md" class="front_menu" >
                                                                                            <NavbarToggler onClick={this.toggle} />
                                                                                            <Collapse isOpen={this.state.isOpen} navbar>
                                                                                                {this.state.menuList.map((navLink, index) => (
                                                                                                    <Nav className="ml-auto" navbar>
                                                                                                        {(navLink.type && navLink.type === 'subMenu') ?
                                                                                                            <Fragment>
                                                                                                                <UncontrolledDropdown nav inNavbar onMouseEnter={() => this.OpenSubmenuOpen(`submenu_${index}`)} onMouseLeave={() => this.OpenSubmenuClose(`submenu_${index}`)}>
                                                                                                                    <Link aria-haspopup="true" to={navLink.path} className="dropdown-toggle nav-link" aria-expanded="true"> {navLink.menu_title}</Link>
                                                                                                                    <DropdownMenu right id={`submenu_${index}`}>

                                                                                                                        {navLink.child_routes && navLink.child_routes.map((subNavLink, idx) => (
                                                                                                                            <DropdownItem tag={Link} className={`nav-item  ${(pageName == subNavLink.path || (subNavLink.path == "/shop/clothing/29" && pageName == "/29")) ? 'active' : ''}`} to={subNavLink.path}>{subNavLink.menu_title}

                                                                                                                                {subNavLink.children_routes && subNavLink.children_routes.map((subLink, indexx) => (
                                                                                                                                    <DropdownItem tag={Link} className={`nav-item  ${(pageName == subLink.path || (subLink.path == "/shop/clothing/29" && pageName == "/29")) ? 'active' : ''}`} to={subLink.path}>{subLink.menu_title}</DropdownItem>
                                                                                                                                ))}


                                                                                                                            </DropdownItem>
                                                                                                                        ))}

                                                                                                                    </DropdownMenu>
                                                                                                                </UncontrolledDropdown>
                                                                                                            </Fragment>
                                                                                                            :
                                                                                                            <Fragment>

                                                                                                                {this.props.companyAdmin ?
                                                                                                                    <NavItem>
                                                                                                                        <NavLink href={navLink.pathUrl}>{navLink.admin_menu_title}</NavLink>
                                                                                                                    </NavItem>


                                                                                                                    :
                                                                                                                    <NavItem>
                                                                                                                        <NavLink href={navLink.path}>{navLink.menu_title}</NavLink>
                                                                                                                    </NavItem>
                                                                                                                }


                                                                                                            </Fragment>
                                                                                                        }
                                                                                                    </Nav>
                                                                                                ))}
                                                                                            </Collapse>
                                                                                        </Navbar>
                                                                                    </div>
                                                                                </nav>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </Row>
                                                            ) : null}
                                                        </Container>
                                                    </div>
                                                </div>
                                            </div>





                                            <Col xl={4} lg={4} className="col-6">

                                                <Row>
                                                    <div style={{ marginTop: "6px" }} >
                                                        <p style={{ color: 'cadetblue' }}>{this.props.currentUserNm.seasonNm}</p>
                                                    </div>
                                                    <Dropdown isOpen={this.state.dropdownOpen} style={{ marginTop: "5px", paddingRight: "40px" }} toggle={this.toggle2} className="profile-dropdown ml-auto">

                                                        <DropdownToggle caret className="btn-white">

                                                            {this.props.currentUserNm.username != null ?

                                                                <div style={{ display: "flex", width: "33px", height: "33px", backgroundColor: "navy", borderRadius: "50%" }}>
                                                                    <span style={{ paddingLeft: "9px", color: "white", fontSize: "21px" }}>{this.props.currentUserNm.username.charAt(0).toUpperCase()}</span>
                                                                </div>

                                                                :
                                                                <div>
                                                                    <span style={{ paddingRight: "10px" }}>Login</span> <i className="fa fa-user fa-2x"></i>
                                                                </div>

                                                            }


                                                        </DropdownToggle>
                                                        <DropdownMenu>

                                                            {this.props.currentUserNm ? (
                                                                <div>
                                                                    <DropdownItem className="nav-link" tag={Link} to="/Account/AccountProfile"><i className="fa fa-user fa-lg"></i>{this.props.currentUserNm.username}</DropdownItem>
                                                                    <DropdownItem onClick={this.logOut} className="nav-link" tag={Link} to="/" ><i className="fa fa-sign-out"></i>Logout</DropdownItem>
                                                                </div>
                                                            ) :
                                                                <div>
                                                                    <DropdownItem className="nav-link" tag={Link} to="/" onClick={this.toggle} data-toggle="modal" data-target="#" ><i className="fa fa-user fa-lg"></i>Login</DropdownItem>
                                                                </div>
                                                            }

                                                        </DropdownMenu>
                                                    </Dropdown>


                                                </Row>
                                            </Col>

                                            {this.props.currentUserNm && (
                                                <Navbar color="faded" light >

                                                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                                                    <Collapse isOpen={!this.state.collapsed} navbar>

                                                        <Nav className="ml-auto" navbar>
                                                            {this.state.menuList.map((navLink, index) => (
                                                                <li className={`nav-item ${(this.state.classset == navLink.menu_title) ? 'show' : ''}`}>
                                                                    {(navLink.type && navLink.type === 'subMenu') ?
                                                                        <Fragment>
                                                                            <Link href="#" className="nav-link" onClick={() => this.onClickClassAdd(navLink.menu_title)}>{navLink.menu_title}</Link>
                                                                            <ul className={(this.state.classset == navLink.menu_title) ? 'showcollapsed' : 'submenu'}>
                                                                                {navLink.child_routes && navLink.child_routes.map((subNavLink, index) => (
                                                                                    <li className={`nav-item  ${(pageName == subNavLink.path) ? 'active' : ''}`} toggle={false}   >
                                                                                        <Link className="nav-link" onClick={() => this.closeNavbar()} to={subNavLink.path}>{subNavLink.menu_title}</Link>
                                                                                        <ul className={(this.state.classset == subNavLink.menu_title) ? 'showcollapsed' : 'childMenu'}>

                                                                                            {subNavLink.children_routes && subNavLink.children_routes.map((subLink, index) => (
                                                                                                <li className={`nav-item  ${(pageName == subLink.path) ? 'active' : ''}`} toggle={false}   >
                                                                                                    <Link className="nav-link" onClick={() => this.closeNavbar()} to={subLink.path}>{subLink.menu_title}</Link>

                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </Fragment>
                                                                        :
                                                                        <Fragment>
                                                                            <NavItem>
                                                                                <Link to={navLink.path} className="nav-admin-link" >{navLink.menu_title}</Link>
                                                                            </NavItem>

                                                                        </Fragment>
                                                                    }
                                                                </li>
                                                            ))}
                                                        </Nav>

                                                    </Collapse>
                                                </Navbar>
                                            )}

                                        </div>
                                    </Col>

                                </Row>

                            </div>
                        </div>

                    </div>

                    :

                    <div id="preloader">
                        <Loader
                            type="Puff"
                            color="#04d39f"
                            height="100"
                            width="100"
                        />
                    </div>
                }
            </header>

        )
    }
};
export default Header;
