/**
 *  Footer Main
 */
import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';

class Footer extends React.Component {

    constructor(props) {
        super(props);
    }
    componentDidMount() {
        window.addEventListener('scroll', this.Checkscroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.Checkscroll);
    }

    Checkscroll() {

        var scrollTop = (document.documentElement && document.documentElement.scrollTop) ||
            document.body.scrollTop;

        if (scrollTop > 350) {
            if (document.getElementById("back-to-top") != null) {
                document.getElementById("back-to-top").setAttribute("style", "display:block");
            }
        }
        else {

            if (document.getElementById("back-to-top") != null) {
                document.getElementById("back-to-top").setAttribute("style", "display:none");
            }
        }

    }
    ClicktoTop() {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    }
    render() {
        let backtotop = { display: 'none' }
        return (
            <div>
                <footer className="site-footer">
                    <div className="footer-wrapper">
                        {/* <div className="footer-widgets-wrapper">
                            <div className="footer">
                                <Container>
                                    <Row>
                                        <div className="col-lg-3 col-md-6 footer-align-left">
                                            <div className="logo-wrapper widget">
                                                <p><Link to="#">
                                                    <img className="img-fluid" src={require(`../../assets/images/Sero Bazaar.png`)} alt="logo" />
                                                </Link></p>
                                            </div>
                                            <div className="text-content">
                                                <p className="mb-3 mt-4">Sero, being the trusted and popular fashion brand for men, since 1971, has evolved with the time,
                                                    complementing the philosophy of Self Made. Over the years, Sero has set a benchmark in the fashion fraternity by
                                                    presenting fine quality of shirts, trousers and denim. With a comprehensive range of the finest brands and latest
                                                    collections, Sero reflects the Self Made attitude of contemporary men..</p>

                                            </div>
                                            <div className="pgs-social-profiles mt-4">
                                                <div className="social-profiles-wrapper">
                                                    <div className="social-profiles-wrapper-inner social-profiles-default social-profiles-shape-square">
                                                        <div className="social-profiles">
                                                            <ul>
                                                                <li><a href="https://www.facebook.com/SeroApparels/" title="Facebook" target="_blank"><i className="fa fa-facebook" /></a></li>
                                                                <li><a href="https://www.linkedin.com/company/sero-apparels/" title="LinkedIn" target="_blank"><i className="fa fa-linkedin" /></a></li>
                                                                <li><a href="https://www.instagram.com/seroapparels/?hl=en" title="Instagram" target="_blank"><i className="fa fa-instagram" /></a></li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 footer-align-left">
                                            <div className="footer-nav-menu widget">
                                                <h4 className="footer-title title">Useful Links</h4>
                                                <div className="menu-useful-links-container">
                                                    <ul className="menu">
                                                        <li className="menu-item active"><Link to="/">Home</Link></li>
                                                        <li className="menu-item"><a href="https://www.seroapparels.com/our-company/" target="_blank">About Us</a></li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 footer-align-left">
                                            <div className="footer-nav-menu widget mt-4 mt-lg-0">

                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 footer-align-left">
                                            <div className="pgs-contact-widget widget mt-4 mt-lg-0">
                                                <h4 className="footer-title title">Contact Info</h4>
                                                <div className="footer-address">
                                                    <ul>
                                                        <li><i className="fa fa-map-marker" /><span>Savla Corporation,308,
                                                            Jogani Industrial Estate, Senapati Bapat Marg, Dadar (West), Mumbai - 400028</span></li>
                                                        <li className="pgs-contact-email"><i className="fa fa-envelope-o" /><span>info@seroapparels.com</span></li>
                                                        <li className="pgs-contact-email"><i className="fa fa fa-globe" /><span>www.seroapparels.com</span></li>
                                                        <li><i className="fa fa-phone" /><span>+ 91 - 22 - 40110885</span></li>
                                                        <li><i className="fa fa-phone" /><span>+ 91 - 22 - 35114520</span></li>

                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </Row>
                                </Container>
                            </div>
                        </div> */}

                        <div className="site-info">
                            <div className="footer-widget">
                                <Container>
                                    <div className="row align-items-center">
                                        <Col md={6} className="float-left">
                                            <p> {this.props.currentUserNm.seasonNm}</p>
                                        </Col>
                                    </div>
                                    <div className="clearfix" />
                                </Container>
                            </div>
                        </div>
                    </div>
                </footer>
                {/* Back to Top */}
                <div id="back-to-top" style={backtotop} onClick={this.ClicktoTop}>
                    <Link class="top arrow">
                        <i class="fa fa-angle-up"></i>
                    </Link>
                </div>
            </div>
        )
    }
};
export default Footer;
