import React, { Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Headers from './layouts/header/Header';
import Footer from './layouts/footer/Footer';

//Component
import HomePage from './component/home/index';
import HomePage2 from './component/home2';
import HomePage3 from './component/home3';

import './App.css';
import './Vendor.js';



import { IntlProvider } from 'react-intl';
import ShopPage from './component/shop';
import ShopPage1 from './component/shop/index1';
import ShopPage2 from './component/shop/index2';
import ShopPage3 from './component/shop/index3';
import ShopPage4 from './component/shop/index4';
import ProductDetail from './component/shop/product-detail';
import ShopingCart from './component/shop/ShopingCart';
import MyAccount from './component/Account/MyAccount';
import PageNotFound from './component/Pages/PageNotFound';
import ComingSoon from './component/Pages/ComingSoon';
import BlogSinglePage from './component/Blog/BlogSinglePage';
import WishList from './component/WishList/WishList';
import Aboutus from './component/AboutUs/Aboutus';
import Contactus from './component/ContactUs/Contactus';
import Maintenance from './component/Pages/Maintenance';
import BlogFullWidth from './component/Blog/BlogFullWidth';
import HomePage4 from './component/home4';
import CheckOut from './component/shop/CheckOut';
import Address from './component/Account/Address';
import Addressedit from './component/Account/Addressedit';
import AccountProfile from './component/Account/AccountProfile';
import AccountProfileedit from './component/Account/AccountProfileedit';
import SavedCards from './component/Account/SavedCards';
import SavedCardsedit from './component/Account/SavedCardsedit';
import SuccessScreen from './component/Account/SuccessScreen';
import Reports from './component/admin/Reports';
import Invoices from './component/admin/Invoices';
import OrderHistory from './component/Account/OrderHistory';
import AdminDashboard from './component/admin';
import SavedCardsadd from './component/Account/SavedCardsadd';
import { receiveProducts } from './actions';
import { connect } from 'react-redux';
import LoginPage from './LoginPage/LoginPage';
import AuthService from "./services/auth.service";
import PartyMaster from "./component/masters/PartyList";
import ProductMaster from "./component/masters/ProductMaster";
import AddProduct from "./component/masters/AddProductMaster";
import OrderMaster from "./component/masters/OrderMaster";
import AddOrder from "./component/masters/AddOrder";
import SavlaHomePage from "./component/masters/savlaHomePage";
import AddParty from "./component/masters/AddPartyMaster";
import UserMaster from "./component/masters/UserMaster";
import AddUser from "./component/masters/AddUser";
import RoleRights from "./component/masters/RoleRights";
import ReportsNew from "./component/reports/ReportsNew";
import SeasonMast from './component/masters/SeasonMast';
import AddSeason from './component/masters/AddSeason';
import SeasonUserRights from './component/masters/SeasonUserRights';
import AddSeasonUser from './component/masters/AddSeasonUser';
import StockStatus from './component/masters/StockStatus';
import JsonUpload from './component/masters/JsonUpload';
import JsonUploadCredit from './component/masters/PartyStockJsonUpload';
import OrderXml from "./component/reports/OrderXml";
import ResetPassword from "./component/masters/ResetPassword";
import 'devextreme/dist/css/dx.light.css';
import ProductWiseOrderStatus from './component/masters/ProductWiseOrderStatus.js';



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: undefined,
      companyAdmin: false,
      roleMast: undefined
    };
  }
  // componentWillMount() {
  //   this.props.receiveProducts();
  // }
  componentWillMount() {
    const user = AuthService.getCurrentUser();

    if (user) {

      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
        companyAdmin: user.isCompanyAdmin,
        roleMast: user.roleMast
      });
    }

    //  this.props.receiveProducts();
  }

  getUrl(pathname) {
    let pathArray = pathname.split('/');
    return `/${pathArray[1]}` === '/ComingSoon' ? true : `/${pathArray[1]}` === '/Maintenance' ? true : `/${pathArray[1]}` === '/admin-panel' ? true : false;
  }

  render() {
    const { location } = this.props;
    const { currentUser, isAdmin, companyAdmin, roleMast } = this.state;



    return (
      <IntlProvider
        locale="a"
        messages="s"
      >
        <Fragment>
          {
            this.getUrl(location.pathname) ?
              <Switch>
                <Route path="/ComingSoon" component={ComingSoon} />
                <Route path="/Maintenance" component={Maintenance} />
                <Route path="/admin-panel" component={AdminDashboard} />
              </Switch>
              :
              <div>

                <Headers currentUserNm={currentUser} companyAdmin={companyAdmin} pathname={this.props.location.pathname} />

                <Switch>
                  <Route exact path="/" component={SavlaHomePage} />
                  <Route exact path="/login" component={LoginPage} />
                  <Route exact path="/home-default" component={HomePage} />
                  {/* <Route exact path="/index-new-fashion" component={HomePage2} /> */}
                  <Route exact path="/index-modern" component={HomePage3} />
                  <Route exact path="/index-home-classic" component={HomePage4} />
                  <Route exact path="/sidebar-with-load-more" component={ShopPage} />
                  <Route exact path="/topbar-with-load-more" component={ShopPage1} />
                  <Route exact path="/sidebar-without-lazyload" component={ShopPage2} />
                  <Route exact path="/topbar-without-lazyload" component={ShopPage3} />
                  <Route exact path="/sidebar-with-lazyload" component={ShopPage4} />
                  <Route exact path="/ShopingCart" component={ShopingCart} />
                  <Route exact path="/MyAccount" component={MyAccount} />
                  <Route exact path="/BlogSinglePage" component={BlogSinglePage} />
                  <Route exact path="/BlogFullWidth" component={BlogFullWidth} />
                  <Route exact path="/wishlist" component={WishList} />
                  <Route exact path="/Aboutus" component={Aboutus} />
                  <Route exact path="/contactus" component={Contactus} />
                  <Route exact path="/CheckOut" component={CheckOut} />
                  <Route exact path="/Address" component={Address} />
                  <Route exact path="/Account/Addressedit" component={Addressedit} />
                  <Route exact path="/Account/AccountProfile" component={AccountProfile} />
                  <Route exact path="/Account/Address" component={Address} />
                  <Route exact path="/Account/OrderHistory" component={OrderHistory} />
                  <Route exact path="/Account/SavedCards" component={SavedCards} />
                  <Route exact path="/Account/AccountProfileedit" component={AccountProfileedit} />
                  <Route exact path="/Account/SavedCards" component={SavedCards} />
                  <Route exact path="/Account/SavedCardsedit" component={SavedCardsedit} />
                  <Route exact path="/Account/SavedCardsadd" component={SavedCardsadd} />
                  <Route exact path="/SuccessScreen" component={SuccessScreen} />
                  <Route exact path="/Reports" component={Reports} />
                  <Route exact path="/Invoices" component={Invoices} />
                  <Route path={`/shop/:category/:id`} component={ProductDetail} />

                  <Route exact path="/partyMaster" component={PartyMaster} />
                  <Route exact path="/productMaster" component={ProductMaster} />
                  <Route exact path="/addProduct" component={AddProduct} />
                  <Route exact path="/editProduct/:productId" component={AddProduct} />
                  <Route exact path="/orderMaster" component={OrderMaster} />
                  <Route exact path="/addOrder" component={AddOrder} />
                  <Route exact path="/editOrder/:orderMtId" component={AddOrder} />
                  <Route exact path="/addParty" component={AddParty} />
                  <Route exact path="/editParty/:partyId" component={AddParty} />
                  <Route exact path="/user" component={UserMaster} />
                  <Route exact path="/addUser" component={AddUser} />
                  <Route exact path="/editUser/:userId" component={AddUser} />
                  <Route exact path="/roleRights" component={RoleRights} />
                  <Route path="/orderDetailsGroupWiseSummary" component={ReportsNew} />
                  <Route path="/orderDetailSummaryPartyWise" component={ReportsNew} />
                  <Route path="/companyWiseOrderSummary" component={ReportsNew} />
                  <Route path="/clothRequirement" component={ReportsNew} />
                  <Route path="/orderReport" component={ReportsNew} />
                  <Route exact path="/seasonMast" component={SeasonMast} />
                  <Route exact path="/addSeason" component={AddSeason} />
                  <Route exact path="/editSeason/:seasonId" component={AddSeason} />
                  <Route exact path="/seasonUserRights" component={SeasonUserRights} />
                  <Route exact path="/addSeasonUser" component={AddSeasonUser} />
                  <Route exact path="/stockStatus" component={StockStatus} />
                  <Route exact path="/jsonUpload" component={JsonUpload} />
                  <Route path="/clothRequirementSavla" component={ReportsNew} />
                  <Route path="/clothRequirementSagi" component={ReportsNew} />
                  <Route path="/clothRequirementSmart" component={ReportsNew} />
                  <Route path="/clothRequirementSpan" component={ReportsNew} />
                  <Route path="/clothRequirementFashion" component={ReportsNew} />
                  <Route path="/partyDetailsReports" component={ReportsNew} />
                  <Route path="/orderXml" component={OrderXml} />
                  <Route path="/resetPassword" component={ResetPassword} />
                  <Route path="/orderStatusReports" component={ReportsNew} />
                  <Route path="/orderBookSizeWise" component={ReportsNew} />
                  <Route path="/productWiseOrderStatus" component={ProductWiseOrderStatus} />
                  <Route exact path="/jsonUploadCredit" component={JsonUploadCredit} />

                  <Route exact component={PageNotFound} />

                </Switch>


                {/* <Footer currentUserNm={currentUser}/> */}
              </div>
          }

        </Fragment>
      </IntlProvider>
    );
  }
}

const AppMapStateToProps = state => {
  return {
    products: state.data.products
  };
};

const AppMapDispatchToProps = dispatch => {
  return {
    receiveProducts: () => {
      dispatch(receiveProducts());
    }
  };
};


export default connect(AppMapStateToProps, AppMapDispatchToProps)(withRouter(App))
