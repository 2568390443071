import React, { Component } from "react";
import userJson from '../../api/userJson';
import { Button, Row } from 'reactstrap';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import userService from "../../services/user.services";
import { toast, ToastContainer } from 'react-toastify';
import AuthService from '../../services/auth.service';


class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            password: '',
            password1: '',
            userId: '',
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.password != '' || this.state.password1 != '') {
            if (this.state.password === this.state.password1) {
                AuthService.changePassword(this.state.password, this.state.userId).then(
                    response => {
                        if (response.data['status'] === 'SUCCESS') {
                            toast.success(response.data['message']);
                        } else if (response.data['status'] === 'Error') {
                            toast.error(response.data['message']);
                        }
                    },
                ).catch(error => {
                    if (error.response.status === 403) {
                        alert('Your Login Session Is Expire ,Please Login ')
                        AuthService.logout()
                        this.props.history.push('/')
                    } else {
                        console.log('error ', error)
                    }
                })
            } else {
                alert('Both password is mismatch');
            }
        } else {
            alert('Please enter password');
        }
    }


    handleChangeEventPass(field, e) {
        this.setState({ password: e.target.value });
    }

    handleChangeEventPass1(field, e) {
        this.setState({ password1: e.target.value });
    }
    componentWillMount() {
        const user = AuthService.getCurrentUser();
        this.setState({ userId: user.id, userName: user.username })
    }


    render() {

        return (
            <div className="container" style={{ border: "0.10px solid", color: 'grey', marginTop: '10px' }}>
                <br />

                <Row style={{ paddingTop: "10px", justifyContent: 'center', alignItems: 'center' }}>
                    <div className="col-md-4">
                        <h3 style={{ "text-align": "left", color: "navy" }}> Reset Password </h3>
                    </div>
                </Row>




                <Form onSubmit={this.handleSubmit} >
                    <div className="row" style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <div className="form-group col-md-4" >
                            <label style={{ fontWeight: "bold" }}>User Name <span style={{ color: "red" }}>*</span> </label>
                            <Input
                                type="text"
                                className="form-control"
                                placeholder="User Name"
                                value={this.state.userName}
                                disabled
                            />
                        </div>

                    </div>



                    <div className="row" style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <div className="form-group col-md-4" >
                            <label style={{ fontWeight: "bold" }}>Password <span style={{ color: "red" }}>*</span> </label>
                            <Input
                                type="password"
                                className="form-control"
                                placeholder="Password"
                                value={this.state.password}
                                onChange={this.handleChangeEventPass.bind(this, "password")}
                                autoComplete="off" />
                        </div>
                    </div>

                    <div className="row" style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <div className="form-group col-md-4">
                            <label style={{ fontWeight: "bold" }}>Confirm Password <span style={{ color: "red" }}>*</span> </label>
                            <Input
                                type="password"
                                className="form-control"
                                placeholder="Confirm Password"
                                value={this.state.password1}
                                onChange={this.handleChangeEventPass1.bind(this, "password1")}
                                autoComplete="off" />
                        </div>
                    </div>

                    <div className="row" style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <div className="form-group">
                            <button className="btn btn-outline-success">Save</button>
                        </div>
                    </div>


                </Form>


            </div>
        )
    }

}

export default ResetPassword;