import React, { Component } from 'react';
import { Col, Container, Row, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import userService from "../../services/user.services";
import AuthService from '../../services/auth.service';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { matchSorter } from 'match-sorter';


class SeasonMast extends Component {

    constructor(props) {
        super(props);
        this.state = {
            seasonList: [],
        };
    }

    getSeasonList() {
        userService.seasonList().then(response =>
            this.setState({
                seasonList: JSON.parse(response.data['jsonObject'])
            }),
        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {

                console.log('error ', error)
            }

        })

    }

    componentWillMount() {
        this.getSeasonList();
    }

    addSeason() {
        this.props.history.push('/addSeason');
    }

    deleteSeason(e, seasonId) {
        userService.deleteSeason(seasonId).then(response => {
            if (response.data['status'] == 'SUCCESS') {
                this.getSeasonList();
                toast.success(response.data['message'])
            }
        }).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ');
                AuthService.logout();
                this.props.history.push('/');
            } else {
                console.log('error ', error)
            }

        })
    }

    activeDeactiveSeason(e, seasonId,activeFlg) {
        userService.activeDeactiveSeason(seasonId, activeFlg).then(response => {
            if (response.data['status'] == 'SUCCESS') {
                this.getSeasonList();
                toast.success(response.data['message'])
            }
        }).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ');
                AuthService.logout();
                this.props.history.push('/');
            } else {
                console.log('error ', error)
            }

        })
    }

    render() {

        const columns = [
            {
                sortable: false,
                Header: 'Name',
                accessor: 'seasonName',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["seasonName"] }),
                filterAll: true,
            },
            {
                Header: 'From Date',
                accessor: 'fromDate',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["fromDate"] }),
                filterAll: true,
            },

            {
                Header: 'To Date',
                accessor: 'toDate',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["toDate"] }),
                filterAll: true,
            },

            // {
            //     Header: 'Active',
            //     accessor: 'activeFlg',
            //     filterMethod: (filter, rows) =>
            //         matchSorter(rows, filter.value, { keys: ["activeFlg"] }),
            //     filterAll: true,
            // },
            {
                Header: 'Edit',
                accessor: 'action1',
                Cell: props => {
                    return (
                        <div style={{ paddingLeft: "50px" }}>
                            <Link className="btn btn-outline-info" to={`/editSeason/${props.original.seasonId}`} href="#">
                                <i class="fa fa-edit"></i></Link>
                        </div>
                    )
                },
            },

            {
                Header: 'Action',
                accessor: 'action2',
                Cell: props => {
                    return (
                        <div style={{ paddingLeft: "50px" }}>
                            <Button style={{ width: "80px" }} outline color={props.original.activeFlg ? "danger" : "info"} onClick={(e) => this.activeDeactiveSeason(e, props.original.seasonId, props.original.activeFlg)}
                                href="#">{props.original.activeFlg ? 'Deactive' : 'Active'}</Button>
                        </div>
                    )
                },
            }



            // {
            //     Header: 'Delete',
            //     accessor: 'action2',
            //     Cell: props => {
            //         return (
            //             <div style={{ paddingLeft: "50px" }}>
            //                 <Link onClick={(e) => this.deleteSeason(e, props.original.seasonId)}
            //                     href="#">Delete</Link>
            //             </div>
            //         )
            //     },
            // }


        ]


        return (
            <div className='container'>
                <ToastContainer autoClose={1000} draggable={false} />

                <div className="section-ptb" style={{ padding: "10px" }}>
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Row>
                                    <Col lg={2}>
                                        <Button outline color="secondary" onClick={() => this.addSeason()}><i class="fa fa-plus"></i> Add</Button>
                                    </Col>
                                </Row>
                                <br />

                                <ReactTable id="table-to-xls" className="orderList-table"
                                    data={this.state.seasonList}
                                    columns={columns}
                                    filterable defaultFilterMethod={(filter, row) =>
                                        String(row[filter.id]) === filter.value}
                                    minRows={1}
                                    defaultPageSize={10}
                                    pageSizeOptions={[5, 25, 50, 100, 150, 250, 500]}
                                />


                            </Col>
                        </Row>

                    </Container>
                </div>

            </div>
        )

    }
}

export default SeasonMast;