import React, { Component } from 'react';
import { DataGrid, Column, MasterDetail } from 'devextreme-react/data-grid';
import userService from "../../services/user.services";
import { toast, ToastContainer } from 'react-toastify';
import AuthService from '../../services/auth.service';
import PartyWiseProductStock from './PartyWiseProductStock.js';


class ProductStock extends Component {
  constructor(props) {
    super(props);
    this.getTasks(props.data.key);
    this.state = {
      clothChildList: [],
      sizeArray: '',
      sizeNm1: '',
      sizeNm2: '',
      sizeNm3: '',
      sizeNm4: '',
      sizeNm5: '',
      sizeNm6: '',
      sizeNm7: '',
      sizeNm8: '',
      sizeNm9: '',
      sizeNm10: '',
      sizeNm11: '',
      sizeNm12: '',

      productNm: props.data.key
    }


  }

  getTasks(productNm) {

    userService.getclothChildData(productNm, localStorage.getItem('seasonId')).then(
      response => {

        if (response.data['status'] === 'SUCCESS') {
          let sizeArrayNew = JSON.parse(response.data['jsonObject'])['sizeArray'];
          this.setState({
            clothChildList: JSON.parse(response.data['jsonObject'])['childList'],
            sizeNm1: sizeArrayNew[0],
            sizeNm2: sizeArrayNew[1],
            sizeNm3: sizeArrayNew[2],
            sizeNm4: sizeArrayNew[3],
            sizeNm5: sizeArrayNew[4],
            sizeNm6: sizeArrayNew[5],
            sizeNm7: sizeArrayNew[6],
            sizeNm8: sizeArrayNew[7],
            sizeNm9: sizeArrayNew[8],
            sizeNm10: sizeArrayNew[9],
            sizeNm11: sizeArrayNew[10],
            sizeNm12: sizeArrayNew[11]


          });


        } else if (response.data['status'] === 'Error') {
          toast.error(response.data['message']);
        }
      },

    )
  }


  render() {



    return (
      <React.Fragment>
        <div className="master-detail-caption">
          {`Stock Status`}
        </div>
        <DataGrid
          dataSource={this.state.clothChildList}
          keyExpr="product"
          showBorders={true}
          columnAutoWidth={true}
        >
          <Column dataField="status" />
          <Column dataField="size1" caption={this.state.sizeNm1} />
          <Column dataField="size2" caption={this.state.sizeNm2} />
          <Column dataField="size3" caption={this.state.sizeNm3} />
          <Column dataField="size4" caption={this.state.sizeNm4} />
          <Column dataField="size5" caption={this.state.sizeNm5} />
          <Column dataField="size6" caption={this.state.sizeNm6} />
          <Column dataField="size7" caption={this.state.sizeNm7} />
          <Column dataField="size8" caption={this.state.sizeNm8} />
          <Column dataField="size9" caption={this.state.sizeNm9} />
          <Column dataField="size10" caption={this.state.sizeNm10} />
          {/* <Column dataField="size11" caption={this.state.sizeNm11} />
          <Column dataField="size12" caption={this.state.sizeNm12} /> */}
          <Column dataField="total" />

          <MasterDetail
            enabled={true}
            component={PartyWiseProductStock}
          />

        </DataGrid>
      </React.Fragment>
    );
  }


}









export default ProductStock;
