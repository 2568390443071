import React, { Component } from 'react';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Textarea from "react-validation/build/textarea";
import Select, { createFilter } from 'react-select';
import userService from "../../services/user.services";
import { toast, ToastContainer } from 'react-toastify';
import AuthService from '../../services/auth.service';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    Col, Container, Row, Modal, ModalBody, ModalHeader, Button, ButtonGroup, ButtonDropdown,
    DropdownItem, DropdownToggle, DropdownMenu, Table
} from 'reactstrap';
import ReactTable from 'react-table';
import ratio from '../../api/newRatio.json';
import { matchSorter } from 'match-sorter';
import '../../customCss.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import AddStockOrder from './AddStockOrder';
import { CheckBox } from 'devextreme-react';


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',
        padding: 0,
    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: 100,
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 100ms';

        return { ...provided, opacity, transition };
    }
}

class AddOrder extends Component {

    componentDidMount() {
        this.nameInput.focus();
    }


    constructor(props) {
        super(props);
        //   this.handleChangeEvent = this.handleChangeEvent.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleSummary = this.toggleSummary.bind(this);
        this.toggle = this.toggle.bind(this);
        this.updateHVPremiumqty = this.updateHVPremiumqty.bind(this);
        this.updateFreezonqty = this.updateFreezonqty.bind(this);
        this.updateSeroFormalqty = this.updateSeroFormalqty.bind(this);
        this.updatefairdealqty = this.updatefairdealqty.bind(this);
        this.togglePlaceOrder = this.togglePlaceOrder.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.popPlaceOrder = this.popPlaceOrder.bind(this);
        this.refreshRatioCopy = this.refreshRatioCopy.bind(this);
        this.toggle4 = this.toggle4.bind(this);
        this.toggleCopyProductBySleev = this.toggleCopyProductBySleev.bind(this);
        this.togglePleaseWait = this.togglePleaseWait.bind(this);
        this.toggleBtnDropdown = this.toggleBtnDropdown.bind(this);
        this.inputReference = React.createRef();
        this.toggleError = this.toggleError.bind(this);
        this.toggleDeleteAll = this.toggleDeleteAll.bind(this);
        this.toggleDeleteZeroQty = this.toggleDeleteZeroQty.bind(this);
        this.addProductJsonList = this.addProductJsonList.bind(this);
        this.getOrderDtWiseSummary = this.getOrderDtWiseSummary.bind(this);

        this.state = {
            orderNo: "",
            partyId: "",
            qty: "",
            amount: "",
            mtId: "",
            options: [],
            value: null,
            partyData: [],
            orderDate: "",
            hpSleevFlg: false,
            fSleevFlg: false,
            bothSleevFlg: false,
            orderDtData: [],
            searchProduct: '',
            invoiceview: '',
            fieldvalue: "",
            errors: {},
            modalSummary: false,
            sleevWiseOrderData: [],
            brandDataArray: [],
            brandArray: [],
            modal: false,
            productType: '',
            ratioObject: [],
            productTypeWiseRatio: [],
            trousersRatioObject: [],
            dropdownRatio: ratio,
            itemRatioCopyDtId: '',
            orderMtId: parseInt(this.props.match.params.orderMtId),
            mode: '',
            addProductNm: '',
            placeOrderModal: false,
            ordusername: '',
            ordpassword: '',
            submitted: false,
            loading: false,
            copyRatioProductNm: '',
            partySelectedValue: "",
            sleevVal: 'Both',
            partyDetails: '',
            ratioArray: ['0', '0.1', '0.10', '0.25', '0.33', '0.5', '0.50', '0.66', '0.75', '0.8', '0.80', '1', '1.5', '2', '2.5'],
            ratioErrorFlg: false,
            searchProduct: '',
            productList: '',
            productNewList: [],
            product: '',
            modal4: false,
            orderDtId: '',
            rowOrderDtData: [],
            modalCopySleev: false,
            copySleevVal: '',
            ftohpSleevFlg: false,
            hpToFSleevFlg: false,
            modalPleaseWait: false,
            dropdownOpen: false,
            sizeNm1: 'S36',
            sizeNm2: 'S38',
            sizeNm3: 'S39',
            sizeNm4: 'S40',
            sizeNm5: 'S42',
            sizeNm6: 'S44',
            sizeNm7: 'S46',
            sizeNm8: 'S48',
            sizeNm9: 'S50',
            previuousRowIndex: '',
            deliveryInstrTxtFlg: false,
            deliveryTypeArrayList: [{ label: "Starting Dispatch From", value: "startingFrom" },
            { label: "Others", value: "others" }],
            disabledButton: true,
            deliveryTypeArr: [],
            deliveryInstrTxt: "",
            deliveryEndDateTypeFlg: true,
            deliveryEndDateFlg: true,
            modalError: false,
            errorMsg: '',
            submitFlg: false,
            selectAllDtIds: false,
            modalDeleteAll: false,
            orderDtSummary: '',
            orderStatusFlg: false,
            panelFlg: false,
            sizePanelFlg: true,
            selectedSizeList: [],
            sizeType: '',
            sleevType: '',
            modalDeleteZeroQty: false,
            mainSaveBtnFlg: false,
        };

    }

    toggleBtnDropdown() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    toggleDeleteAll() {
        this.setState(prevState => ({
            modalDeleteAll: !prevState.modalDeleteAll
        }));
    }

    toggleDeleteZeroQty() {
        this.setState(prevState => ({
            modalDeleteZeroQty: !prevState.modalDeleteZeroQty
        }));
    }

    togglePleaseWait() {
        this.setState(prevState => ({
            modalPleaseWait: !prevState.modalPleaseWait
        }));
    }

    toggleError() {

        this.setState(prevState => ({
            modalError: !prevState.modalError
        }));

        setTimeout(() => {
            if (this.state.modalError == false) {
                this.nameInput.focus();
            }
        }, 250);

    }

    toggleSummary() {

        if (this.state.modalSummary === false) {
            this.getSleevWiseOrderData(this.state.orderMtId);
         }
        setTimeout(() => {
            
            this.setState(prevState => ({
                modalSummary: !prevState.modalSummary,
            }))
        }, 30);
        
    }


    togglePlaceOrder(e) {

        if (this.state.fieldvalue.confirmStatus === 'selected') {
            if (this.state.placeOrderModal == false) {
                this.inputReference.current = null;
                this.getDispatchScheduleData(this.state.orderMtId);
            }

            this.setState(prevState => ({
                placeOrderModal: !prevState.placeOrderModal
            }))
        } else {
            this.handleSubmit(e);
        }

    }

    refreshRatioCopy() {
        this.setState({ itemRatioCopyDtId: '', copyRatioProductNm: '' })
    }


    getSleevWiseOrderData(orderMtId) {
        userService.getSleevWiseOrderData(orderMtId)
            .then(response => {
               
                this.setState({
                    brandArray: JSON.parse(response.data['jsonObject'])['brandArray'],
                    sleevWiseOrderData: JSON.parse(response.data['jsonObject'])['subBrandArray'],
                    brandDataArray: JSON.parse(response.data['jsonObject'])['brandTotArray'],
                })
            }
            ).catch(error => {

                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')
                } else {
                    console.log('error ', error)
                }

            })
    }

    handleSubmit(event) {
        //   event.preventDefault();
        this.setState({
            submitted: true,
        });


        if (this.state.submitFlg === false && this.state.partySelectedValue != '') {

            this.setState({ submitFlg: true })

            userService.addOrder(this.state.fieldvalue).then(
                response => {
                    if (response.data['status'] === 'SUCCESS') {
                        toast.success('Order draft successfully');
                        // this.props.history.push({
                        //     pathname: '/editOrder/' + response.data['jsonObject'].orderMtId
                        // })
                        //   this.editOrder(response.data['jsonObject'].orderMtId);


                        setTimeout(() => {
                            //this.setState({ orderMtId: response.data['jsonObject'].orderMtId, submitFlg: false });
                            this.props.history.push('/orderMaster')
                        }, 100);

                    } else if (response.data['status'] === 'Error') {
                        //   toast.error(response.data['message']);
                        this.setState({ errorMsg: response.data['message'], submitFlg: false });
                        this.toggleError();
                    }
                }
            ).catch(error => {

                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')
                } else {
                    console.log('error ', error)
                }
            })
        }

    }

    copyOrderBySleev(e) {

        this.toggleCopyProductBySleev();
        this.togglePleaseWait();

        userService.copyOrderBySleev(this.state.orderMtId, this.state.copySleevVal, localStorage.getItem('seasonId')).then(response => {
            if (response.data['status'] === 'SUCCESS') {
                this.getOrderDtListing(this.state.orderMtId);
                toast.success(response.data['message']);
                this.togglePleaseWait();
            } else if (response.data['status'] === 'Error') {
                //  toast.error(response.data['message']);
                this.setState({ errorMsg: response.data['message'] });
                this.togglePleaseWait();
                this.toggleError();
            }
        }
        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {
                console.log('error ', error)
            }
        })
    }

    getPartyCode = value => {
        let fieldvalue = this.state.fieldvalue;
        fieldvalue["partyId"] = value.value;
        this.setState({ fieldvalue, partySelectedValue: value, partyId: value.value });

        this.saveOrderParty(this.state.orderMtId, value.value)
        this.getPartyDetails(value.value);
    }

    saveOrderParty(orderMtId, partyId) {

        userService.saveOrderParty(orderMtId, partyId).then(response => {

        }

        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {

                console.log('error ', error)
            }

        })
    }

    getPartyDetails(partyId) {

        userService.getPartyDetails(partyId).then(response => {
            {

                let fieldvalue = this.state.fieldvalue;
                fieldvalue["partyName"] = JSON.parse(response.data['jsonObject']).partyName;
                fieldvalue["address"] = JSON.parse(response.data['jsonObject']).address;
                fieldvalue["gstNo"] = JSON.parse(response.data['jsonObject']).gstNo;
                this.setState({ fieldvalue });
            }
        }

        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {

                console.log('error ', error)
            }

        })
    }

    getPartyData() {
        userService.getPartyData().then(response =>
            this.setState({
                partyData: JSON.parse(response.data.jsonObject),
                options: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {

                console.log('error ', error)
            }

        })
    }


    addOrderEmptyJson() {
        userService.addOrderEmptyJson(localStorage.getItem('seasonId')).then(response =>
            this.setState({
                fieldvalue: response.data, partySelectedValue: '', orderDate: new Date()
            })
        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {

                console.log('error ', error)
            }

        })
    }

    componentWillMount() {

        if (parseInt(this.props.match.params.orderMtId) > 0) {
            this.editOrder(parseInt(this.props.match.params.orderMtId));
            this.getOrderDtListing(parseInt(this.props.match.params.orderMtId));
            this.getOrderDtWiseSummary(this.props.match.params.orderMtId);
            this.setState({ orderMtId: parseInt(this.props.match.params.orderMtId) });

        } else {
            this.addOrderEmptyJson();
            //     this.setState({ fieldvalue: OrderData["0"], partySelectedValue: OrderData["0"].partyJsonString, orderDate: new Date() })
        }

        this.getPartyData();
    }


    editOrder(orderMtId) {
        userService.editOrder(orderMtId).then(response => {
            this.setState({
                fieldvalue: response.data, partySelectedValue: JSON.parse((response.data.partyJsonString))
            })
        })
    }


    getOrderDtListing(orderMtId) {
        userService.getOrderDtListing(orderMtId).then(response => {

            if (JSON.parse(response.data['jsonObject']).length > 0) {

                this.setState({
                    orderDtData: JSON.parse(response.data['jsonObject']),
                    sizeNm1: JSON.parse(response.data['jsonObject'])[0].v36, sizeNm2: JSON.parse(response.data['jsonObject'])[0].v38,
                    sizeNm3: JSON.parse(response.data['jsonObject'])[0].v39, sizeNm4: JSON.parse(response.data['jsonObject'])[0].v40,
                    sizeNm5: JSON.parse(response.data['jsonObject'])[0].v42, sizeNm6: JSON.parse(response.data['jsonObject'])[0].v44,
                    sizeNm7: JSON.parse(response.data['jsonObject'])[0].v46, sizeNm8: JSON.parse(response.data['jsonObject'])[0].v48,
                    sizeNm9: JSON.parse(response.data['jsonObject'])[0].v50
                })

            }
        }
        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {

                console.log('error ', error)
            }

        })
    }

    onDateChange(dateVal) {
        let fieldvalue = this.state.fieldvalue;
        fieldvalue["orderDate"] = dateVal;
        this.setState({ fieldvalue, orderDate: dateVal });
    }


    handleChangeEvent(field, e) {
        let fieldvalue = this.state.fieldvalue;
        fieldvalue[field] = e.target.value;
        this.setState({ fieldvalue });
    }

    sleevHandleChangeEvent(field, e) {
        this.setState({ sleevVal: e.target.value });
        this.nameInput.value = '';
        this.nameInput.focus();
    }

    copySleevHandleChangeEvent(field, e) {
        this.setState({ copySleevVal: e.target.value });
    }

    addStockProductKeyDown(e) {
        if (e.key == 'Enter' || e.key == 'Tab') {
            alert('ccccc   ')
            e.preventDefault();
        }
    }

    addProductKeyDown(e) {
        if (e.key === 'Enter' || e.key === 'Tab' || e.keyCode === 13) {
            this.addProduct(e);
            e.preventDefault();
        }
    }


    addProduct(e) {

        if (e.target.value != '') {
            this.setState({ addProductNm: e.target.value });
            userService.addProduct(e.target.value, this.state.fieldvalue.orderMtId, this.state.itemRatioCopyDtId,
                this.state.sleevVal, localStorage.getItem('seasonId'), this.state.selectedSizeList, this.state.sizeType)
                .then(response => {
                    if (response.data['status'] === 'SUCCESS') {
                        this.state.addProductNm = '';
                        this.getOrderDtWiseSummary(this.state.orderMtId);

                        let fieldvalue = this.state.fieldvalue;

                        fieldvalue['confirmStatus'] = '';
                        fieldvalue['draftStatus'] = 'selected';

                        this.setState({ fieldvalue });

                        if (response.data['jsonObject'] != 'SUCCESS') {

                            let jsonObj = JSON.parse(response.data['jsonObject']);

                            jsonObj.map((obj, idx) => {
                                this.setState(prevState => ({
                                    orderDtData: [obj, ...prevState.orderDtData],
                                    sizeNm1: obj.v36, sizeNm2: obj.v38, sizeNm3: obj.v39, sizeNm4: obj.v40,
                                    sizeNm5: obj.v42, sizeNm6: obj.v44, sizeNm7: obj.v46, sizeNm8: obj.v48, sizeNm9: obj.v50
                                }));
                            })

                            toast.success(response.data['message']);
                        }

                        this.nameInput.value = '';
                        this.nameInput.focus();

                    } else if (response.data['status'] === 'Error') {
                        //    toast.error(response.data['message']);
                        this.nameInput.value = '';
                        this.setState({ errorMsg: response.data['message'] });
                        this.toggleError();
                        //  this.nameInput.focus();
                    }
                }).catch(error => {
                    if (error.response.status === 403) {
                        alert('Your Login Session Is Expire ,Please Login ')
                        AuthService.logout()
                        this.props.history.push('/')

                    } else {
                        console.log('error ', error)

                    }

                })
        }
    }


    addProductJsonList(productList) {

        productList && productList.map((obj, idx) => {
            this.setState(prevState => ({
                orderDtData: [obj, ...prevState.orderDtData],
                sizeNm1: obj.v36, sizeNm2: obj.v38, sizeNm3: obj.v39, sizeNm4: obj.v40,
                sizeNm5: obj.v42, sizeNm6: obj.v44, sizeNm7: obj.v46, sizeNm8: obj.v48, sizeNm9: obj.v50
            }));

        })

    }


    toggle4() {
        this.setState(prevState => ({
            modal4: !prevState.modal4
        }));
    }

    toggleCopyProductBySleev() {
        this.setState(prevState => ({
            modalCopySleev: !prevState.modalCopySleev
        }));
    }

    onDeleteOrderDt(rowOrderDtData) {
        this.setState({
            orderDtId: rowOrderDtData.orderDtId,
            rowOrderDtData: rowOrderDtData
        });
        this.toggle4();
    }

    deleteOrderDt(e) {
        userService.onDeleteOrderDt(this.state.orderDtId, this.state.orderMtId).then(response => {

            this.toggle4();

            if (response.data['status'] === 'Error') {
                toast.warn(response.data['message'])
            } else {
                this.getOrderDtWiseSummary(this.state.orderMtId);
                let deleteOrder = this.state.rowOrderDtData;
                let newOrderDt = this.state.orderDtData.filter((order) => order.orderDtId !== deleteOrder.orderDtId);

                if (newOrderDt.length > 0) {
                    this.setState({
                        ...this.state,
                        orderDtData: newOrderDt,
                        sizeNm1: newOrderDt[0].v36, sizeNm2: newOrderDt[0].v38, sizeNm3: newOrderDt[0].v39,
                        sizeNm4: newOrderDt[0].v40, sizeNm5: newOrderDt[0].v42, sizeNm6: newOrderDt[0].v44,
                        sizeNm7: newOrderDt[0].v46, sizeNm8: newOrderDt[0].v48, sizeNm9: newOrderDt[0].v50
                    })
                } else {
                    this.setState({
                        ...this.state,
                        orderDtData: newOrderDt
                    })
                }

            }
            this.nameInput.focus();
        }
        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {

                console.log('error ', error)
            }

        })
    }

    popItemRatioCopy(e, orderDt) {
        this.setState({
            itemRatioCopyDtId: orderDt.orderDtId, copyRatioProductNm: orderDt.orderDtId,
            sleevType: orderDt.sleevType, sizePanelFlg: false
        });
        this.nameInput.focus();

        this.getSelectedSizeList(e, orderDt.sleevType, orderDt.orderDtId);

    }


    toggle() {

        userService.getOrderRatioJson("shirts", this.state.orderMtId)
            .then(response =>
                this.setState(prevState => ({
                    modal: !prevState.modal,
                    productType: "shirts",
                    ratioObject: JSON.parse(response.data.jsonObject)
                })),
            ).catch(error => {

                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')

                } else {

                    console.log('error ', error)
                }

            })
    }

    getSelectedSizeList(e, val, orderDtId) {

        let sizeVal = '';
        if (val === 'fromDropdown') {
            sizeVal = e.target.value;
        } else {
            sizeVal = val;
        }

        this.setState({ selectedSizeList: [] })

        userService.getSelectedSizeList(sizeVal, orderDtId)
            .then(response => {
                this.setState({
                    selectedSizeList: JSON.parse(response.data['jsonObject'])['sizeList'],
                    sizeType: sizeVal
                })
            },
            ).catch(error => {

                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')
                } else {
                    console.log('error ', error)
                }

            })
    }

    getShirtsRatio(productType) {

        userService.getOrderRatioJson(productType, this.state.orderMtId)
            .then(response => {
                this.setState(prevState => ({
                    ratioObject: JSON.parse(response.data.jsonObject),
                    productType: productType
                }))
            },
            ).catch(error => {

                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')
                } else {
                    console.log('error ', error)
                }

            })
    }

    getOrderTrouserRatioJson() {
        userService.getOrderTrouserRatioJson("trousers", this.state.orderMtId)
            .then(response =>
                this.setState(prevState => ({
                    trousersRatioObject: JSON.parse(response.data.jsonObject),
                    productType: "trousers"
                })),
            ).catch(error => {

                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')

                } else {

                    console.log('error ', error)
                }

            })
    }



    updateHVPremiumqty = (e, val, size, preQty) => {
        let ratioFlg = false;
        let vQty = e.target.value;
        if (vQty != preQty) {
            let ratioObj = this.state.ratioObject;
            if (vQty <= 2.5) {
                let ratioVal = this.state.ratioArray.filter(ratio => ratio === vQty);
                if (ratioVal != '') {
                    //    this.setState({ ratioErrorFlg: false });
                    ratioFlg = false;
                } else {
                    vQty = 0;
                    //   this.setState({ ratioErrorFlg: true });
                    ratioFlg = true;
                }
            } else {
                if (!Number.isInteger(+vQty)) {
                    vQty = 0;
                    //   this.setState({ ratioErrorFlg: true });
                    ratioFlg = true;
                } else {
                    //    this.setState({ ratioErrorFlg: false });
                    ratioFlg = false;
                }
            }

            ratioObj.map((brand) => {
                brand.hvPremiumRatio.map((brandRt) => {
                    if (brandRt.size == size) {
                        if (val == "F") {
                            brandRt.fqty = vQty
                        } else {
                            brandRt.hqty = vQty
                        }
                    }
                })
            })


            setTimeout(() => {
                this.setState({ ratioObject: ratioObj, ratioErrorFlg: ratioFlg })
            }, 50);
        }
    }


    updateFreezonqty = (e, val, size, preQty) => {
        let ratioFlg = false;
        let vQty = e.target.value;
        if (vQty != preQty) {
            let ratioObj = this.state.ratioObject;
            if (vQty <= 2.5) {
                let ratioVal = this.state.ratioArray.filter(ratio => ratio === vQty);
                if (ratioVal != '') {
                    //     this.setState({ ratioErrorFlg: false });
                    ratioFlg = false;
                } else {
                    vQty = 0;
                    //    this.setState({ ratioErrorFlg: true });
                    ratioFlg = true;
                }
            } else {
                if (!Number.isInteger(+vQty)) {
                    vQty = 0;
                    //   this.setState({ ratioErrorFlg: true });
                    ratioFlg = true;
                } else {
                    // this.setState({ ratioErrorFlg: false });
                    ratioFlg = false;
                }
            }


            ratioObj.map((brand) => {
                brand.freezoneRatio.map((brandRt) => {
                    if (brandRt.size == size) {
                        if (val == "F") {
                            brandRt.fqty = vQty;
                        } else {
                            brandRt.hqty = vQty;
                        }
                    }
                })
            })

            setTimeout(() => {
                this.setState({ ratioObject: ratioObj, ratioErrorFlg: ratioFlg })
            }, 50);
        }


    }


    updateSeroFormalqty = (e, val, size, preQty) => {
        let ratioFlg = false;
        let vQty = e.target.value;
        if (vQty != preQty) {
            let ratioObj = this.state.ratioObject;
            if (vQty <= 2.5) {
                let ratioVal = this.state.ratioArray.filter(ratio => ratio === vQty);
                if (ratioVal != '') {
                    //   this.setState({ ratioErrorFlg: false });
                    ratioFlg = false;
                } else {
                    vQty = 0;
                    //  this.setState({ ratioErrorFlg: true });
                    ratioFlg = true;
                }
            } else {
                if (!Number.isInteger(+vQty)) {
                    vQty = 0;
                    //    this.setState({ ratioErrorFlg: true });
                    ratioFlg = true;
                } else {
                    //    this.setState({ ratioErrorFlg: false });
                    ratioFlg = false;
                }
            }

            ratioObj.map((brand) => {
                brand.formalsRatio.map((brandRt) => {
                    if (brandRt.size == size) {
                        if (val == "F") {
                            brandRt.fqty = vQty
                        } else {
                            brandRt.hqty = vQty
                        }
                    }
                })
            })

            setTimeout(() => {
                this.setState({ ratioObject: ratioObj, ratioErrorFlg: ratioFlg })
            }, 50);
        }
    }


    updatefairdealqty = (e, val, size, preQty) => {
        let ratioFlg = false;
        let vQty = e.target.value;
        if (vQty != preQty) {
            let ratioObj = this.state.ratioObject;
            if (vQty <= 2.5) {
                let ratioVal = this.state.ratioArray.filter(ratio => ratio === vQty);
                if (ratioVal != '') {
                    ratioFlg = false;
                } else {
                    vQty = 0;
                    ratioFlg = true;
                }
            } else {
                if (!Number.isInteger(+vQty)) {
                    vQty = 0;
                    ratioFlg = true;
                } else {
                    ratioFlg = false;
                }
            }

            ratioObj.map((brand) => {
                brand.fairDealRatio.map((brandRt) => {
                    if (brandRt.size == size) {
                        if (val == "F") {
                            brandRt.fqty = vQty
                        } else {
                            brandRt.hqty = vQty
                        }
                    }
                })
            })
            setTimeout(() => {
                this.setState({ ratioObject: ratioObj, ratioErrorFlg: ratioFlg })
            }, 50);
        }
    }


    updatePremiumqty = (e, val, size, preQty) => {
        let ratioFlg = false;
        let vQty = e.target.value;
        if (vQty != preQty) {
            let ratioObj = this.state.ratioObject;
            if (vQty <= 2.5) {
                let ratioVal = this.state.ratioArray.filter(ratio => ratio === vQty);
                if (ratioVal != '') {
                    ratioFlg = false;
                } else {
                    vQty = 0;
                    ratioFlg = true;
                }
            } else {
                if (!Number.isInteger(+vQty)) {
                    vQty = 0;
                    ratioFlg = true;
                } else {
                    ratioFlg = false;
                }
            }

            ratioObj.map((brand) => {
                brand.premiumRatio.map((brandRt) => {
                    if (brandRt.size == size) {
                        if (val == "F") {
                            brandRt.fqty = vQty
                        } else {
                            brandRt.hqty = vQty
                        }
                    }
                })
            })

            setTimeout(() => {
                this.setState({ ratioObject: ratioObj, ratioErrorFlg: ratioFlg })
            }, 50);
        }

    }

    updateUthqty = (e, val, size, preQty) => {
        let ratioFlg = false;
        let vQty = e.target.value;
        if (vQty != preQty) {
            let ratioObj = this.state.ratioObject;
            if (vQty <= 2.5) {
                let ratioVal = this.state.ratioArray.filter(ratio => ratio === vQty);
                if (ratioVal != '') {
                    ratioFlg = false;
                } else {
                    vQty = 0;
                    ratioFlg = true;
                }
            } else {
                if (!Number.isInteger(+vQty)) {
                    vQty = 0;
                    ratioFlg = true;
                } else {
                    ratioFlg = false;
                }
            }

            ratioObj.map((brand) => {
                brand.uthRatio.map((brandRt) => {
                    if (brandRt.size == size) {
                        if (val == "F") {
                            brandRt.fqty = vQty
                        } else {
                            brandRt.hqty = vQty
                        }
                    }
                })
            })

            setTimeout(() => {
                this.setState({ ratioObject: ratioObj, ratioErrorFlg: ratioFlg })
            }, 50);
        }

    }

    updateWhiteGoldqty = (e, val, size, preQty) => {
        let ratioFlg = false;
        let vQty = e.target.value;
        if (vQty != preQty) {
            let ratioObj = this.state.ratioObject;
            if (vQty <= 2.5) {
                let ratioVal = this.state.ratioArray.filter(ratio => ratio === vQty);
                if (ratioVal != '') {
                    ratioFlg = false;
                } else {
                    vQty = 0;
                    ratioFlg = true;
                }
            } else {
                if (!Number.isInteger(+vQty)) {
                    vQty = 0;
                    ratioFlg = true;
                } else {
                    ratioFlg = false;
                }
            }

            ratioObj.map((brand) => {
                brand.whiteRatio.map((brandRt) => {
                    if (brandRt.size == size) {
                        if (val == "F") {
                            brandRt.fqty = vQty
                        } else {
                            brandRt.hqty = vQty
                        }
                    }
                })
            })

            setTimeout(() => {
                this.setState({ ratioObject: ratioObj, ratioErrorFlg: ratioFlg })
            }, 50);
        }

    }


    updateMarryMeqty = (e, val, size, preQty) => {
        let ratioFlg = false;
        let vQty = e.target.value;
        if (vQty != preQty) {
            let ratioObj = this.state.ratioObject;
            if (vQty <= 2.5) {
                let ratioVal = this.state.ratioArray.filter(ratio => ratio === vQty);
                if (ratioVal != '') {
                    ratioFlg = false;
                } else {
                    vQty = 0;
                    ratioFlg = true;
                }
            } else {
                if (!Number.isInteger(+vQty)) {
                    vQty = 0;
                    ratioFlg = true;
                } else {
                    ratioFlg = false;
                }
            }

            ratioObj.map((brand) => {
                brand.marryMeRatio.map((brandRt) => {
                    if (brandRt.size == size) {
                        if (val == "F") {
                            brandRt.fqty = vQty
                        } else {
                            brandRt.hqty = vQty
                        }
                    }
                })
            })

            setTimeout(() => {
                this.setState({ ratioObject: ratioObj, ratioErrorFlg: ratioFlg })
            }, 50);
        }
    }


    updateFormalTrouser = (e, val, size, preQty) => {
        let ratioFlg = false;
        let vQty = e.target.value;
        if (vQty != preQty) {
            let ratioObj = this.state.trousersRatioObject;
            if (vQty <= 2.5) {
                let ratioVal = this.state.ratioArray.filter(ratio => ratio === vQty);
                if (ratioVal != '') {
                    ratioFlg = false;
                } else {
                    vQty = 0;
                    ratioFlg = true;
                }
            } else {
                if (!Number.isInteger(+vQty)) {
                    vQty = 0;
                    ratioFlg = true;
                } else {
                    ratioFlg = false;
                }
            }

            ratioObj.map((category) => {
                category.formalTrousersRatio.map((categoryRt) => {
                    if (categoryRt.size == size) {
                        if (val == "Pleat") {
                            categoryRt.pqty = vQty
                        } else if (val == "smart") {
                            categoryRt.sfqty = vQty
                        } else {
                            categoryRt.cfqty = vQty
                        }
                    }
                })
            })

            setTimeout(() => {
                this.setState({ trousersRatioObject: ratioObj, ratioErrorFlg: ratioFlg })
            }, 50);
        }
    }

    updateCasualTrouser = (e, val, size, preQty) => {
        let ratioFlg = false;
        let vQty = e.target.value;
        if (vQty != preQty) {
            let ratioObj = this.state.trousersRatioObject;
            if (vQty <= 2.5) {
                let ratioVal = this.state.ratioArray.filter(ratio => ratio === vQty);
                if (ratioVal != '') {
                    ratioFlg = false;
                } else {
                    vQty = 0;
                    ratioFlg = true;
                }
            } else {
                if (!Number.isInteger(+vQty)) {
                    vQty = 0;
                    ratioFlg = true;
                } else {
                    ratioFlg = false;
                }
            }

            ratioObj.map((category) => {
                category.casualTrousersRatio.map((categoryRt) => {
                    if (categoryRt.size == size) {
                        if (val == "Pleat") {
                            categoryRt.pqty = vQty
                        } else if (val == "smart") {
                            categoryRt.sfqty = vQty
                        } else {
                            categoryRt.cfqty = vQty
                        }
                    }
                })
            })
            setTimeout(() => {
                this.setState({ trousersRatioObject: ratioObj, ratioErrorFlg: ratioFlg })
            }, 50);
        }
    }

    updateDenimTrouser = (e, val, size, preQty) => {
        let ratioFlg = false;
        let vQty = e.target.value;
        if (vQty != preQty) {
            let ratioObj = this.state.trousersRatioObject;
            if (vQty <= 2.5) {
                let ratioVal = this.state.ratioArray.filter(ratio => ratio === vQty);
                if (ratioVal != '') {
                    ratioFlg = false;
                } else {
                    vQty = 0;
                    ratioFlg = true;
                }
            } else {
                if (!Number.isInteger(+vQty)) {
                    vQty = 0;
                    ratioFlg = true;
                } else {
                    ratioFlg = false;
                }
            }

            ratioObj.map((category) => {
                category.denimsRatio.map((categoryRt) => {
                    if (categoryRt.size == size) {
                        categoryRt.sfqty = vQty
                    }
                })
            })
            setTimeout(() => {
                this.setState({ trousersRatioObject: ratioObj, ratioErrorFlg: ratioFlg })
            }, 50);
        }
    }


    updateboysqty = (e, val, size, preQty) => {
        let ratioFlg = false;
        let vQty = e.target.value;
        if (vQty != preQty) {
            let ratioObj = this.state.ratioObject;
            if (vQty <= 2.5) {
                let ratioVal = this.state.ratioArray.filter(ratio => ratio === vQty);
                if (ratioVal != '') {
                    ratioFlg = false;
                } else {
                    vQty = 0;
                    ratioFlg = true;
                }
            } else {
                if (!Number.isInteger(+vQty)) {
                    vQty = 0;
                    ratioFlg = true;
                } else {
                    ratioFlg = false;
                }
            }

            ratioObj.map((brand) => {
                brand.boysRatio.map((brandRt) => {
                    if (brandRt.size == size) {
                        if (val == "F") {
                            brandRt.fqty = vQty
                        } else {
                            brandRt.hqty = vQty
                        }
                    }
                })
            })
            setTimeout(() => {
                this.setState({ ratioObject: ratioObj, ratioErrorFlg: ratioFlg })
            }, 50);
        }
    }



    applyOrderRatio = () => {
        if (this.state.productType === 'shirts' || this.state.productType === 'boys') {
            this.state.productTypeWiseRatio = this.state.ratioObject;
        }
        if (this.state.productType === 'trousers') {
            this.state.productTypeWiseRatio = this.state.trousersRatioObject;
        }
        this.togglePleaseWait();
        userService.applyOrderRatio(this.state.productTypeWiseRatio, this.state.productType, this.state.orderMtId)
            .then(response => {
                if (response.data['status'] === 'SUCCESS') {
                    window.location.reload();

                    //   this.getOrderDtListing(this.state.orderMtId);
                    //   this.getOrderDtWiseSummary(this.state.orderMtId)
                    //   this.togglePleaseWait();
                    //   toast.success(response.data['message']);
                    //   this.toggle();

                } else if (response.data['status'] === 'Error') {
                    //   toast.error(response.data['message']);
                    this.setState({ errorMsg: response.data['message'] });
                    this.toggleError();
                }
            }

            ).catch(error => {
                console.log('error     ', error);
                this.setState({ errorMsg: 'Error occured, contact admin' });
                this.toggleError();
            })
    }


    getDispatchScheduleData(orderMtId) {
        userService.getDispatchScheduleData(orderMtId, localStorage.getItem('seasonId'))
            .then(response => {
                this.setState({ deliveryTypeArr: JSON.parse(response.data['jsonObject']), ordusername: '', ordpassword: '' })
            },
            ).catch(error => {
                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')
                } else {
                    console.log('error ', error)
                }
            })
    }

    deliveryTypeFunc = (e, brandNm) => {

        let vDelType = "Others";
        let vType = e.target.value;

        if (vType == "startingFrom") {
            vDelType = "Starting Dispatch From";
        } else if (vType == "others") {
            vDelType = "Others";
        } else if (vType == "noStartDateFlg") {
            vDelType = "No Start Date";
        }

        this.state.deliveryTypeArr.filter(brand => brand.brand == brandNm).map((brandRt) => {

            brandRt.deliveryType = vDelType;
            if (vDelType === "Starting Dispatch From") {

                brandRt.deliveryInstrTxtFlg = false;
                brandRt.deliveryEndDateTypeFlg = true;
                brandRt.deliveryEndDateFlg = true;
                brandRt.noStartDateFlg = false;

            } else if (vDelType === "No Start Date") {
                brandRt.deliveryInstrTxtFlg = false;
                brandRt.deliveryEndDateTypeFlg = true;
                brandRt.deliveryEndDateFlg = true;
                brandRt.noStartDateFlg = true;
            }
            else {

                brandRt.deliveryInstrTxtFlg = true;
                brandRt.deliveryEndDateTypeFlg = false;
                brandRt.deliveryEndDateFlg = false;
                brandRt.endDateType = '';

            }


            this.setState({ value: e })
        })
    }


    deliveryEndDateType = (e, brandNm) => {

        let vDelType = "endDate";
        let vType = e.target.value;

        if (vType == "endDate") {
            vDelType = "End Date";

        } else if (vType == "noEndDate") {
            vDelType = "No End Date";

        }

        this.state.deliveryTypeArr.filter(brand => brand.brand == brandNm).map((brandRt) => {
            if (vDelType === "End Date") {
                brandRt.deliveryInstrTxtFlg = false;
                brandRt.deliveryEndDateTypeFlg = true;
                brandRt.deliveryEndDateFlg = true;
                brandRt.endDateType = vDelType;
                brandRt.noEndDateFlg = false;


            } else {
                brandRt.deliveryInstrTxtFlg = false;
                brandRt.deliveryEndDateTypeFlg = true;
                brandRt.deliveryEndDateFlg = false;
                brandRt.endDateType = vDelType;
                brandRt.noEndDateFlg = true;

            }
            this.setState({ value: e })
        })
    }




    handleChangeDispatch = (e, field, brandNm) => {

        let delText = e.target.value;
        this.state.deliveryTypeArr.map((brandRt) => {
            if (brandRt.brand == brandNm) {
                if (field == "instr") {
                    brandRt.instruction = delText;
                } else if (field == "fromDate") {
                    brandRt.fromDate = delText;
                } else if (field == "toDate") {
                    brandRt.toDate = delText;
                }

            }

        })
    }

    popPlaceOrder(e) {
        e.preventDefault();

        this.setState({mainSaveBtnFlg:true})

        userService.finalPlaceOrder(this.state.orderMtId, this.state.ordusername, this.state.ordpassword,
            this.state.deliveryTypeArr, this.state.fieldvalue)
            .then(response => {
                this.setState({mainSaveBtnFlg:false})
                if (response.data['status'] === 'SUCCESS') {
                    toast.success(response.data['message']);
                    this.props.history.push('/orderMaster')
                } else if (response.data['status'] === 'Error') {
                    //  toast.error(response.data['message']);
                    this.setState({ errorMsg: response.data['message'] });
                    this.toggleError();
                }
            }
            ).catch(error => {
                if (error.response.status === 403) {
                    //   toast.error("Invalid username and password");
                    this.setState({ errorMsg: "Invalid username and password" });
                    this.toggleError();
                } else {
                    console.log('error ', error)
                }
            })


    }




    popSizeQty = (val, size, orderDtId, prevQty, dynamicKey,rowColumn) => {

        console.log('rowColumn     ', rowColumn)
        console.log('size     ',size)
        //this.inputReference.current = dynamicKey;
        let sizex = 'v48';

        if (val == '') {
            val = 0;
        }

        if (val !== prevQty) {

            // this.setState(prevState => ({
            //     orderDtData: {
            //       ...prevState.orderDtData,
            //       [orderDtId]: {
            //         ...prevState.orderDtData[orderDtId],
            //         [size]: val,
            //       }
            //     }
            //   }));         

            userService.popSizeQty(val, size, orderDtId,rowColumn)
                .then(response => {
                    if (response.data['status'] === 'SUCCESS') {

 

                        let newOrderData = this.state.orderDtData;

                        newOrderData.filter(ord => ord.orderDtId == orderDtId).map((order) => {
                            order.totQty = JSON.parse(response.data['jsonObject']).totQty

                            if (order.v36 === size) {
                                order.s36 = val;
                            } else if (order.v38 === size) {
                                order.s38 = val;
                            } else if (order.v39 === size) {
                                order.s39 = val;
                            } else if (order.v40 === size) {
                                order.s40 = val;
                            } else if (order.v42 === size) {
                                order.s42 = val;
                            } else if (order.v44 === size) {
                                order.s44 = val;
                            } else if (order.v46 === size) {
                                order.s46 = val;
                            } else if (order.v48 === size) {
                                order.s48 = val;
                            } else if (order.v50 === size) {
                                order.s50 = val;
                            }

                        })

                        this.setState({
                            ...this.state,
                            orderDtData: newOrderData
                        });






                        //    this.getOrderDtWiseSummary(this.state.orderMtId);

                    } else if (response.data['status'] === 'Error') {
                        //   toast.error(response.data['message']);
                        this.setState({ errorMsg: response.data['message'] });
                        this.toggleError();
                    }
                }
                ).catch(error => {

                    if (error.response.status === 403) {
                        alert('Your Login Session Is Expire ,Please Login ')
                        AuthService.logout()
                        this.props.history.push('/')

                    } else {

                        console.log('error ', error)
                    }

                })
        }

    }

    handleChange = (e) => {
        if (e.target.name != 'ordpassword') {
            this.setState({ ordusername: e.target.value })
        } else {
            this.setState({ ordpassword: e.target.value })
        }
    }

    orderMtListing() {
        this.props.history.push('/orderMaster')
    }



    onRowClick = (state, rowInfo, column, instance) => {

        return {
            onClick: e => {

                if (this.state.previuousRowIndex != rowInfo.index) {

                    this.setState({
                        sizeNm1: rowInfo.original.v36, sizeNm2: rowInfo.original.v38, sizeNm3: rowInfo.original.v39,
                        sizeNm4: rowInfo.original.v40, sizeNm5: rowInfo.original.v42, sizeNm6: rowInfo.original.v44,
                        sizeNm7: rowInfo.original.v46, sizeNm8: rowInfo.original.v48, sizeNm9: rowInfo.original.v50,
                        previuousRowIndex: rowInfo.index
                    })
                }

            }
        }
    }


    setFocustKey(val) {
        this.inputReference.current = val;
    }

    selectDate = (e, field) => {
        let fieldvalue = this.state.fieldvalue;
        fieldvalue[field] = e.target.checked;
        this.setState({ fieldvalue });
    }

    handleSubmitsize(e) {
        e.preventDefault()
    }

    deleteAll = () => {
        this.toggleDeleteAll();
    }

    deleteZeroQty = () => {
        this.toggleDeleteZeroQty();
    }

    deleteOrderDtZeroQty(e) {
        let orderDtArr = this.state.orderDtData.filter(dt => dt.totQty === 0);

        if (orderDtArr.length > 0) {
            this.togglePleaseWait();
            userService.deleteAllOrderDt(orderDtArr, this.state.orderMtId).then(response => {

                this.toggleDeleteZeroQty();
                this.togglePleaseWait();
                if (response.data['status'] === 'Error') {
                    toast.warn(response.data['message'])
                } else {
                    this.getOrderDtWiseSummary(this.state.orderMtId);
                    let newOrderDt = this.state.orderDtData.filter((order) => order.totQty > 0);

                    if (newOrderDt.length > 0) {
                        this.setState({
                            ...this.state,
                            orderDtData: newOrderDt,
                            selectAllDtIds: false,
                            sizeNm1: newOrderDt[0].v36, sizeNm2: newOrderDt[0].v38, sizeNm3: newOrderDt[0].v39,
                            sizeNm4: newOrderDt[0].v40, sizeNm5: newOrderDt[0].v42, sizeNm6: newOrderDt[0].v44,
                            sizeNm7: newOrderDt[0].v46, sizeNm8: newOrderDt[0].v48, sizeNm9: newOrderDt[0].v50
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            orderDtData: newOrderDt,
                            selectAllDtIds: false,
                        })
                    }

                }
                this.nameInput.focus();
            }
            ).catch(error => {

                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')
                } else {
                    console.log('error ', error)
                }

            })
        }
    }

    deleteAllOrderDt(e) {

        let orderDtArr = this.state.orderDtData.filter(dt => dt.selectRowFlg == true);

        if (orderDtArr.length > 0) {

            this.togglePleaseWait();
            userService.deleteAllOrderDt(orderDtArr, this.state.orderMtId).then(response => {

                this.toggleDeleteAll();
                this.togglePleaseWait();

                if (response.data['status'] === 'Error') {
                    toast.warn(response.data['message'])
                } else {
                    this.getOrderDtWiseSummary(this.state.orderMtId);
                    let newOrderDt = this.state.orderDtData.filter((order) => order.selectRowFlg == false);

                    if (newOrderDt.length > 0) {
                        this.setState({
                            ...this.state,
                            orderDtData: newOrderDt,
                            selectAllDtIds: false,
                            sizeNm1: newOrderDt[0].v36, sizeNm2: newOrderDt[0].v38, sizeNm3: newOrderDt[0].v39,
                            sizeNm4: newOrderDt[0].v40, sizeNm5: newOrderDt[0].v42, sizeNm6: newOrderDt[0].v44,
                            sizeNm7: newOrderDt[0].v46, sizeNm8: newOrderDt[0].v48, sizeNm9: newOrderDt[0].v50
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            orderDtData: newOrderDt,
                            selectAllDtIds: false,
                        })
                    }

                }
                this.nameInput.focus();
            }
            ).catch(error => {

                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')
                } else {
                    console.log('error ', error)
                }

            })
        } else {
            this.toggleDeleteAll();
            alert('Please select records');
        }

    }

    selectAllRow = (e) => {
        this.state.orderDtData.map((dt, index) => {
            dt.selectRowFlg = e.target.checked;
        });
        this.setState({ ...this.state.orderDtData, selectAllDtIds: e.target.checked });
    }

    selectRow = (e, orderDtId) => {
        let orderDtArr = this.state.orderDtData.filter(dt => dt.orderDtId == orderDtId);
        orderDtArr.map((dt) => {
            dt.selectRowFlg = e.target.checked;
        })
        this.setState({ ...this.state.orderDtData });
    }


    handleChangeCheckbox = (e) => { 
        let fieldvalue = this.state.fieldvalue;
        fieldvalue['readyProductFlg'] = e.target.checked;
        this.setState({ fieldvalue });
        this.togglePleaseWait();
        userService.saveOrderReadyProductFlg(this.state.orderMtId,e.target.checked)
        .then(response => {
            if (response.data['status'] === 'SUCCESS') {
                this.togglePleaseWait();

            }
        })
        
    }

    getOrderDtWiseSummary(orderMtId) {
        userService.getOrderDtWiseSummary(orderMtId)
            .then(response => {
                if (response.data['status'] === 'SUCCESS') {
                    setTimeout(() => {
                        this.setState({ orderDtSummary: JSON.parse(response.data.jsonObject) })
                    }, 50);

                }
            })
    }

    popOrderStatus(e) {

        let fieldvalue = this.state.fieldvalue;

        if (e.target.value == 'draft') {
            fieldvalue['draftStatus'] = 'selected';
            fieldvalue['confirmStatus'] = '';
        } else {
            fieldvalue['draftStatus'] = '';
            fieldvalue['confirmStatus'] = 'selected';
        }

        this.setState({ fieldvalue });
    }

    hideShowMtDetails(e) {

        if (this.state.panelFlg) {
            this.setState({ panelFlg: false })
        } else {
            this.setState({ panelFlg: true })
        }
    }

    hideShowSizeDetails(e) {
        if (this.state.sizePanelFlg) {
            this.setState({ sizePanelFlg: false, selectedSizeList: [] })
        } else {
            this.setState({ sizePanelFlg: true, selectedSizeList: [] })
        }
    }

    updateSizeqty = (val, size) => {
        this.state.selectedSizeList.filter((s) => s.size == size).map((dt) => {
            dt.qty = val;
        })
        this.setState({
            ...this.state.selectedSizeList
        });
    }


    refreshRatioDetails = () => {
        this.state.selectedSizeList.map((dt) => {
            dt.qty = '';
        })
        this.setState({
            ...this.state.selectedSizeList
        });
    }

    render() {

        const filterConfig = { matchFrom: 'start' }
        const { orderDate, ordusername, ordpassword, submitted, ratioErrorFlg,
            deliveryTypeArr, orderDtSummary, panelFlg, sizePanelFlg, selectedSizeList, sleevType } = this.state;


        const columns = [

            {
                sortable: false,
                width: 50,
                Header: <input type="checkbox" defaultChecked={this.state.selectAllDtIds} checked={this.state.selectAllDtIds}
                    value={this.state.selectAllPartyFlg} onClick={(e) => this.selectAllRow(e)} />,
                accessor: 'selectRowFlg',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <input type="checkbox" defaultChecked={props.original.selectRowFlg} checked={props.original.selectRowFlg}
                                value={props.original.selectRowFlg} onClick={(e) => this.selectRow(e, props.original.orderDtId)}
                            />
                        </div>
                    )
                },
            },
            {
                width: 170,
                Header: 'Product',
                accessor: 'product',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["product"] }),
                filterAll: true,
            },
            {
                width: 170,
                Header: 'Status',
                accessor: 'status',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["status"] }),
                filterAll: true,
            },
            {
                width: 70,
                Header: this.state.sizeNm1,
                accessor: 's36',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <Form onSubmit={this.handleSubmitsize}>
                                {props.original.s36Flg ?
                                    <Input type="number" style={{ textAlign: 'center' }} class="form-control" onBlur={(e) => this.popSizeQty(e.target.value, props.original.v36, props.original.orderDtId, props.original.s36, props.original.d36,props.original.totQty)}
                                        value={props.original.s36} defaultValue={props.original.s36} disabled={this.state.fieldvalue.orderLockFlg}
                                        autoFocus={props.original.d36 === this.inputReference.current}
                                    />
                                    :
                                    <Input type="number" style={{ textAlign: 'center' }} class="form-control" value={props.original.s36} defaultValue={props.original.s36} disabled />
                                }
                            </Form>
                        </div>
                    )
                },
            },
            {
                width: 70,
                Header: this.state.sizeNm2,
                accessor: 's38',
                Cell: props => {

                    return (
                        <div className='d-flex justify-content-center'>
                            <Form onSubmit={this.handleSubmitsize}>
                                {props.original.s38Flg ?
                                    <Input type="number" style={{ textAlign: 'center' }} class="form-control" onBlur={(e) => this.popSizeQty(e.target.value, props.original.v38, props.original.orderDtId, props.original.s38, props.original.d38,props.original.totQty)}
                                        value={props.original.s38} defaultValue={props.original.s38} disabled={this.state.fieldvalue.orderLockFlg}
                                        autoFocus={props.original.d38 === this.inputReference.current} />
                                    :
                                    <Input type="number" style={{ textAlign: 'center' }} class="form-control" value={props.original.s38} defaultValue={props.original.s38} disabled />
                                }
                            </Form>
                        </div>
                    )
                },
            },


            {
                width: 70,
                sortable: false,
                Header: this.state.sizeNm3,
                accessor: 's39',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <Form onSubmit={this.handleSubmitsize}>
                                {props.original.s39Flg ?
                                    <Input type="number" style={{ textAlign: 'center' }} class="form-control" onBlur={(e) => this.popSizeQty(e.target.value, props.original.v39, props.original.orderDtId, props.original.s39, props.original.d39,props.original.totQty)}
                                        defaultValue={props.original.s39} value={props.original.s39} disabled={this.state.fieldvalue.orderLockFlg}
                                        autoFocus={props.original.d39 === this.inputReference.current} />
                                    :
                                    <Input type="number" style={{ textAlign: 'center' }} class="form-control" value={props.original.s39} defaultValue={props.original.s39} disabled />
                                }
                            </Form>

                        </div>
                    )
                },
            },
            {
                width: 70,
                Header: this.state.sizeNm4,
                accessor: 's40',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <Form onSubmit={this.handleSubmitsize}>
                                {props.original.s40Flg ?
                                    <Input type="number" style={{ textAlign: 'center' }} class="form-control" onBlur={(e) => this.popSizeQty(e.target.value, props.original.v40, props.original.orderDtId, props.original.s40, props.original.d40,props.original.totQty)}
                                        value={props.original.s40} defaultValue={props.original.s40} disabled={this.state.fieldvalue.orderLockFlg}
                                        autoFocus={props.original.d40 === this.inputReference.current} />
                                    :
                                    <Input type="number" style={{ textAlign: 'center' }} class="form-control" value={props.original.s40} defaultValue={props.original.s40} disabled />
                                }
                            </Form>
                        </div>
                    )
                },
            },
            {
                width: 70,
                Header: this.state.sizeNm5,
                accessor: 's42',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <Form onSubmit={this.handleSubmitsize}>
                                {props.original.s42Flg ?
                                    <Input type="number" style={{ textAlign: 'center' }} class="form-control" onBlur={(e) => this.popSizeQty(e.target.value, props.original.v42, props.original.orderDtId, props.original.s42, props.original.d42,props.original.totQty)}
                                        value={props.original.s42} defaultValue={props.original.s42} disabled={this.state.fieldvalue.orderLockFlg}
                                        autoFocus={props.original.d42 === this.inputReference.current} />
                                    :
                                    <Input type="number" style={{ textAlign: 'center' }} class="form-control" value={props.original.s42} defaultValue={props.original.s42} disabled />
                                }
                            </Form>
                        </div>
                    )
                },
            },
            {
                width: 70,
                Header: this.state.sizeNm6,
                accessor: 's44',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <Form onSubmit={this.handleSubmitsize}>
                                {props.original.s44Flg ?
                                    <Input type="number" style={{ textAlign: 'center' }} class="form-control" onBlur={(e) => this.popSizeQty(e.target.value, props.original.v44, props.original.orderDtId, props.original.s44, props.original.d44,props.original.totQty)}
                                        value={props.original.s44} defaultValue={props.original.s44} disabled={this.state.fieldvalue.orderLockFlg}
                                        autoFocus={props.original.d44 === this.inputReference.current} />
                                    :
                                    <Input type="number" style={{ textAlign: 'center' }} class="form-control" value={props.original.s44} defaultValue={props.original.s44} disabled />
                                }
                            </Form>
                        </div>
                    )
                },
            },
            {
                width: 70,
                Header: this.state.sizeNm7,
                accessor: 's46',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <Form onSubmit={this.handleSubmitsize}>
                                {props.original.s46Flg ?
                                    <Input type="number" style={{ textAlign: 'center' }} class="form-control" onBlur={(e) => this.popSizeQty(e.target.value, props.original.v46, props.original.orderDtId, props.original.s46, props.original.d46,props.original.totQty)}
                                        value={props.original.s46} defaultValue={props.original.s46} disabled={this.state.fieldvalue.orderLockFlg}
                                        autoFocus={props.original.d46 === this.inputReference.current} />
                                    :
                                    <Input type="number" style={{ textAlign: 'center' }} class="form-control" value={props.original.s46} defaultValue={props.original.s46} disabled />
                                }
                            </Form>
                        </div>
                    )
                },
            },
            {
                width: 70,
                sortable: false,
                Header: this.state.sizeNm8,
                accessor: 's48',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <Form onSubmit={this.handleSubmitsize}>
                                {props.original.s48Flg ?
                                    <Input type="number" style={{ textAlign: 'center' }} class="form-control" onBlur={(e) => this.popSizeQty(e.target.value, props.original.v48, props.original.orderDtId, props.original.s48, props.original.d48,props.original.totQty)}
                                        value={props.original.s48} defaultValue={props.original.s48} disabled={this.state.fieldvalue.orderLockFlg}
                                        autoFocus={props.original.d48 === this.inputReference.current} />
                                    :
                                    <Input type="number" style={{ textAlign: 'center' }} class="form-control" value={props.original.s48} defaultValue={props.original.s48} disabled />
                                }
                            </Form>
                        </div>
                    )
                },
            },
            {
                width: 70,
                Header: this.state.sizeNm9,
                accessor: 's50',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <Form onSubmit={this.handleSubmitsize}>
                                {props.original.s50Flg ?
                                    <Input type="number" style={{ textAlign: 'center' }} class="form-control" onBlur={(e) => this.popSizeQty(e.target.value, props.original.v50, props.original.orderDtId, props.original.s50, props.original.d50,props.original.totQty)}
                                        value={props.original.s50} defaultValue={props.original.s50} disabled={this.state.fieldvalue.orderLockFlg}
                                        autoFocus={props.original.d50 === this.inputReference.current} />
                                    :
                                    <Input type="number" style={{ textAlign: 'center' }} class="form-control" value={props.original.s50} defaultValue={props.original.s50} disabled />
                                }
                            </Form>
                        </div>
                    )
                },
            },
            {
                Header: "Total Pieces",
                accessor: 'totQty',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["totQty"] }),
                filterAll: true,
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <lable>{props.original.totQty}</lable>
                        </div>
                    )
                },
            },
            {
                Header: 'Ratio Copy',
                accessor: 'action',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <a onClick={(e) => this.popItemRatioCopy(e, props.original)}
                            > <i class="fa fa-clone" aria-hidden="true"></i>
                            </a>
                        </div>
                    )
                },
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <a className="btn btn-outline-danger"
                                onClick={() => this.onDeleteOrderDt(props.original)}
                                disabled={this.state.fieldvalue.orderLockFlg}
                            ><i className="fa fa-trash-o"></i>
                            </a>
                        </div>
                    )
                },
            }
        ]

        return (

            <Container style={{ border: "0.10px solid", color: 'grey', marginTop: '10px' }}>

                <ToastContainer autoClose={5} draggable={false} />
                <div className="col-md-12 " >
                    <Row style={{ paddingTop: "10px" }}>
                        <div className="col-md-4">
                            <h3 style={{ "text-align": "left", color: "navy" }}> Order Booking </h3>
                        </div>

                        <div className="col-md-4 text-center">
                            {this.state.fieldvalue.orderLockFlg &&
                                <h3 style={{ "text-align": "left", color: "#FF0000" }}> Order is lock </h3>}
                        </div>

                        <div className="col-md-4 text-right">
                            <ButtonGroup size="sm" >
                                <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggleBtnDropdown}>
                                    <DropdownToggle caret >
                                        More Options
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={this.toggleCopyProductBySleev} disabled={this.state.fieldvalue.orderLockFlg}>Copy Product By Sleev</DropdownItem>
                                        <DropdownItem onClick={this.toggle} color="dark" disabled={this.state.fieldvalue.orderLockFlg}>Selection Ratio</DropdownItem>
                                        <DropdownItem onClick={() => this.toggleSummary()}>Summary</DropdownItem>
                                        <DropdownItem onClick={() => this.orderMtListing()}>Listing</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </ButtonGroup>&nbsp;&nbsp;
                            <select onChange={(e) => this.popOrderStatus(e)}>
                                <option value="draft" selected={this.state.fieldvalue.draftStatus}>Draft</option>
                                <option value="confirm" selected={this.state.fieldvalue.confirmStatus}>Confirm</option>
                            </select>&nbsp;&nbsp;
                            <Button outline color="success" onClick={() => this.togglePlaceOrder()} disabled={this.state.fieldvalue.orderLockFlg}>Save</Button>
                        </div>
                    </Row>

                    <Form onSubmit={this.handleSubmit}>

                        <div>
                            <div className="row">
                                <div className="form-group col-md-4">
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <label style={{ fontWeight: "bold" }}>Order No</label>
                                        </div>
                                        <div className='col-md-9'>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={this.state.fieldvalue.orderNo}
                                                onChange={this.handleChangeEvent.bind(this, "orderNo")}
                                                autoComplete="off"
                                                disabled />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group col-md-4">
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <label style={{ fontWeight: "bold" }}>Order Date</label>
                                        </div>
                                        <div className='col-md-9'>
                                            {<DatePicker
                                                name="orderDate" id="orderDate"
                                                dateFormat="dd/MM/yyyy"
                                                value={this.state.fieldvalue.orderDate}
                                                selected={orderDate}
                                                onChange={this.onDateChange}
                                                readOnly
                                            />}
                                        </div>
                                    </div>
                                </div>

                                {this.state.fieldvalue.orderLockFlg ?

                                    <div className="form-group col-md-4">
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <label style={{ fontWeight: "bold" }}>Party <span style={{ color: "red" }}>*</span></label>
                                            </div>
                                            <div className='col-md-9'>
                                                <Select className="react-select" classNamePrefix="react-select" name="partyId"
                                                    defaultValue="Select Party"
                                                    value={this.state.partySelectedValue}
                                                    onChange={this.getPartyCode}
                                                    disabled
                                                ></Select>
                                            </div>
                                        </div>
                                    </div>

                                    :

                                    <div className={'form-group col-md-4' + (submitted && !this.state.partySelectedValue ? ' has-error' : '')} >
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <label style={{ fontWeight: "bold" }}>Party <span style={{ color: "red" }}>*</span></label>
                                            </div>
                                            <div className='col-md-9'>
                                                <Select className="react-select" classNamePrefix="react-select" name="partyId"
                                                    defaultValue="Select Party"
                                                    options={this.state.options}
                                                    value={this.state.partySelectedValue}
                                                    onChange={this.getPartyCode}

                                                ></Select>

                                                {submitted && !this.state.partySelectedValue &&
                                                    <div className="alert alert-danger" role="alert">
                                                        This field is required!
                                                    </div>
                                                }
                                            </div>
                                        </div>


                                    </div>

                                }


                        <div className="form-group col-md-4">
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <label style={{ fontWeight: "bold" }}>Ready Product</label>
                                        </div>
                                        <div className='col-md-9'>
                                        <input type="checkbox" defaultChecked={this.state.fieldvalue.readyProductFlg} checked={this.state.fieldvalue.readyProductFlg}
                                                value={this.state.fieldvalue.readyProductFlg} onClick={(e) => this.handleChangeCheckbox(e)} />
                                            
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <a href='#' onClick={this.hideShowMtDetails.bind(this)}>{panelFlg ? 'Hide Details' : 'Show Details'}</a>
                                </div>
                            </div>

                            <div className='row'>
                                {/* <br /> */}
                                {panelFlg &&
                                    <div className=" form-group col-md-4">
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <label style={{ fontWeight: "bold" }}>Party Details </label>
                                            </div>
                                            <div className='col-md-9'>
                                                <Textarea
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.fieldvalue.partyName}
                                                    disabled
                                                    rows={1}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }

                                {panelFlg &&
                                    <div className="form-group col-md-4">
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <label style={{ fontWeight: "bold" }}>Instructions </label>
                                            </div>
                                            <div className='col-md-9'>
                                                <Textarea
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.fieldvalue.instructions}
                                                    onChange={this.handleChangeEvent.bind(this, "instructions")}
                                                    disabled={this.state.fieldvalue.orderLockFlg}
                                                    rows={1}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }

                                {panelFlg &&
                                    <div className="form-group col-md-4">
                                        <div className='row'>
                                            <div className='col-md-3'> </div>


                                            <div className='col-md-4'>
                                                <input type="checkbox" defaultChecked={this.state.fieldvalue.noStartDateFlg}
                                                    value={this.state.fieldvalue.noStartDateFlg} onClick={(e) => this.selectDate(e, 'noStartDateFlg')} />&nbsp;
                                                <label style={{ fontWeight: "bold" }}>No Start Date </label>
                                            </div>


                                            <div className='col-md-4'>
                                                <input type="checkbox" defaultChecked={this.state.fieldvalue.noEndDateFlg}
                                                    value={this.state.fieldvalue.noEndDateFlg} onClick={(e) => this.selectDate(e, 'noEndDateFlg')} />&nbsp;
                                                <label style={{ fontWeight: "bold" }}>No End Date</label>
                                            </div>

                                        </div>
                                    </div>
                                }



                            </div>

                        </div>




                    </Form>




                </div>

                <div className="col-md-12" style={{ border: "0.10px solid", color: 'grey', marginTop: '10px' }}>
                    {/* Order Detail        */}

                    <br></br>

                    <div>
                        <Tabs>
                            <TabList>
                                <Tab>Open Order</Tab>
                                <Tab>Stock Order</Tab>
                            </TabList>
                            {/* Open Order */}
                            <TabPanel>
                                <div className="row">

                                    <div className="form-group col-md-3" >
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="hpSleevFlg" name="hpSleevFlg" class="custom-control-input" value="H/P" onChange={this.sleevHandleChangeEvent.bind(this, "sleev")} checked={this.state.sleevVal === "H/P"}></input>
                                            <label class="custom-control-label" for="hpSleevFlg" >H/P</label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="fSleevFlg" name="fSleevFlg" class="custom-control-input" value="F" onChange={this.sleevHandleChangeEvent.bind(this, "sleev")} checked={this.state.sleevVal === "F"}></input>
                                            <label class="custom-control-label" for="fSleevFlg">F</label>
                                        </div>

                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="bothSleevFlg" name="bothSleevFlg" class="custom-control-input" value="Both" onChange={this.sleevHandleChangeEvent.bind(this, "sleev")} checked={this.state.sleevVal === "Both"}></input>
                                            <label class="custom-control-label" for="bothSleevFlg">Both</label>
                                        </div>
                                    </div>

                                    {/* <div className='col-md-9' >
    <Row>
        <Col md={2}><h6>Total Count : {orderDtSummary.totalcount}</h6></Col>
        <Col md={2}><h6>H/P Count : {orderDtSummary.hpcount}</h6></Col>
        <Col md={2}><h6>F Count : {orderDtSummary.fcount}</h6></Col>
        <Col md={2}><h6>Total Qty : {orderDtSummary.totalqty}</h6></Col>
        <Col md={2}><h6>H/P Qty : {orderDtSummary.hpqty}</h6></Col>
        <Col md={2}><h6>F Qty : {orderDtSummary.fqty}</h6></Col>
    </Row>
</div> */}



                                </div>
                                <Row>
                                    <div className="col-md-3">
                                        {/* <Form> */}
                                        <div class="form-group">

                                            <input className="form-control"
                                                ref={(input) => { this.nameInput = input; }}
                                                // onBlur={this.addProduct.bind(this)}
                                                onKeyDown={this.addProductKeyDown.bind(this)}
                                                autoComplete="off"
                                                disabled={this.state.fieldvalue.orderLockFlg}
                                                onClick={this.setFocustKey(this)}
                                            />
                                        </div>
                                        {/* </Form> */}
                                    </div>


                                    <div className="col-md-6 col-sm-6">
                                        <Button onClick={this.deleteAll} outline color="danger">
                                            <i class="fa fa-trash-o" aria-hidden="true"></i>{" "}Del Selected Rows </Button>
                                        {" "} <Button onClick={this.deleteZeroQty} outline color="danger">
                                            <i class="fa fa-trash-o" aria-hidden="true"></i>{" "}Del Zero Qty </Button>
                                        {" "} <Button onClick={this.toggleSummary} outline color="secondary">
                                            {" "}Summary</Button>
                                    </div>

                                    {/* <div className="col-md-6">
    <div className="row">
        <div className="col-md-4" style={{ paddingTop: "8px" }}>
            <label style={{ fontWeight: "bold" }}>Copy Design Details</label>
        </div>
        <div className="col-md-6">
            <input type="text" defaultValue={this.state.copyRatioProductNm} class="form-control"
                readOnly></input>
        </div>
        <div className="col-md-1">
            <Button onClick={this.refreshRatioCopy} outline color="info">
                <i class="fa fa-refresh" aria-hidden="true"></i> </Button>

        </div>
    </div>
</div> */}

                                </Row>


                                <Row>
                                    <Col md={12}>
                                        <a href='#' onClick={this.hideShowSizeDetails.bind(this)}>{sizePanelFlg ? 'Show Ratio Details' : 'Hide Ratio Details'}</a>
                                    </Col>
                                </Row>

                                <Form>
                                    {!sizePanelFlg &&
                                        <div className='Container'>
                                            <Row style={{ border: "0.10px solid", color: 'grey', marginTop: '10px' }}>
                                                <Col md={2}>
                                                    <Row>
                                                        <Col md={3}>
                                                            <label>Sizes</label>
                                                        </Col>
                                                        <Col md={8}></Col>
                                                    </Row>
                                                    <select onChange={(e) => this.getSelectedSizeList(e, 'fromDropdown', null)}>
                                                        <option value=''>Select Sizes</option>
                                                        <option value="S36-S50" selected={sleevType === 'S36-S50' ? "selected" : ""}>S36-S50</option>
                                                        <option value="F36-F48" selected={sleevType === 'F36-F48' ? "selected" : ""}>F36-F48</option>
                                                        <option value="S28-S44" selected={sleevType === 'S28-S44' ? "selected" : ""}>S28-S44</option>
                                                        <option value="S6-S16" selected={sleevType === 'S6-S16' ? "selected" : ""}>S6-S16</option>
                                                    </select>
                                                </Col>
                                                <Col md={8}>
                                                    <Row>
                                                        {
                                                            selectedSizeList.map((sizes, idx) => {
                                                                return (
                                                                    <Col style={{ textAlign: "center" }}>
                                                                        <label>{sizes.size}</label>
                                                                    </Col>

                                                                )
                                                            })
                                                        }
                                                    </Row>

                                                    <Row>

                                                        {
                                                            selectedSizeList.map((sizes, idx) => {
                                                                return (
                                                                    <Col style={{ textAlign: "center" }}>
                                                                        <Input type="number" class="form-control" onChange={(e) => this.updateSizeqty(e.target.value, sizes.size)}
                                                                            value={sizes.qty} defaultValue={sizes.qty}
                                                                        />
                                                                    </Col>
                                                                )
                                                            })
                                                        }

                                                    </Row>
                                                </Col>

                                                <Col md={1} style={{ marginTop: '27px' }}>
                                                    <Button onClick={this.refreshRatioDetails} outline color="info">
                                                        <i class="fa fa-refresh" aria-hidden="true"></i> </Button>

                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                </Form>
                            </TabPanel>

                            {/* Stock Order        */}
                            <TabPanel>
                                <AddStockOrder orderMtId={this.state.orderMtId}
                                    getOrderDtWiseSummary={this.getOrderDtWiseSummary}
                                    addProductJsonList={this.addProductJsonList}
                                    orderLockFlg={this.state.fieldvalue.orderLockFlg}
                                    fieldvalue={this.state.fieldvalue}
                                    

                                />
                            </TabPanel>

                        </Tabs>
                    </div>



                    <br></br>


                    <Row>
                        <Col md={12}>
                            <ReactTable id="table-to-xls" className="orderList-table"
                                data={this.state.orderDtData}
                                columns={columns}
                                filterable defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value}
                                minRows={1}
                                defaultPageSize={10}
                                pageSizeOptions={[5, 10, 20, 25, 50, 100, 150, 250, 500]}
                                getTrProps={this.onRowClick}

                            />
                        </Col>
                    </Row>


                    <Modal isOpen={this.state.modalSummary} toggle={this.toggleSummary} style={{ maxWidth: "1050px" }} className="modal-login modal-lg modal-dialog-centered" >
                        <ModalHeader toggle={() => this.toggleSummary()}>
                            <h6 className="mb-0">Sleev Wise Summary</h6>
                        </ModalHeader>

                        <ModalBody>
                            <div>

                                {
                                    
                                   this.state.brandArray?.map((brandArr, idx) => {
                                        return (
                                            <div>

                                                <div>

                                                </div>


                                                {

                                                    this.state.sleevWiseOrderData.map((ratioObj, idx) => {
                                                        return (
                                                            <div>

                                                                {
                                                                    brandArr.brandNm == ratioObj.brand &&
                                                                    <div>
                                                                        <br />
                                                                        <h6>Brand : &nbsp;{ratioObj.brand}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Subbrand : &nbsp;{ratioObj.subBrandNm}
                                                                        </h6>

                                                                        {

                                                                            ratioObj.sleevArry.slice(0, 1).map((ratio, idx) => {
                                                                                return (
                                                                                    <div>
                                                                                        <Row>

                                                                                            <Col style={{ paddingLeft: "10px" }}>
                                                                                                <label> Sleev</label></Col>

                                                                                            <Col style={{ paddingLeft: "10px" }}>
                                                                                                <label>Count</label></Col>

                                                                                            {

                                                                                                ratio.sizeArry.map((sizeMap, idx) => {
                                                                                                    return (
                                                                                                        <Col style={{ paddingLeft: "10px" }}>
                                                                                                            <label>
                                                                                                                {sizeMap.size}</label></Col>

                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                            <Col style={{ paddingLeft: "10px" }}>
                                                                                                <label> Total</label></Col>

                                                                                        </Row>
                                                                                    </div>

                                                                                )

                                                                            }
                                                                            )
                                                                        }


                                                                        {

                                                                            ratioObj.sleevArry.map((ratio, idx) => {
                                                                                return (
                                                                                    <div>
                                                                                        <Row>
                                                                                            <Col style={{ padding: "2px" }} >

                                                                                                <input
                                                                                                    value={ratio.sleev}
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    autoComplete="off"
                                                                                                />
                                                                                            </Col>

                                                                                            <Col style={{ padding: "2px" }} >

                                                                                                <input
                                                                                                    value={ratio.itemCount}
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    autoComplete="off"
                                                                                                />
                                                                                            </Col>

                                                                                            {

                                                                                                ratio.sizeArry.map((sizeMap, idx) => {
                                                                                                    return (
                                                                                                        <Col style={{ padding: "2px" }}>
                                                                                                            <input
                                                                                                                value={sizeMap.qty}
                                                                                                                type="text"
                                                                                                                className="form-control"
                                                                                                                autoComplete="off"
                                                                                                            /></Col>

                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                            <Col style={{ padding: "2px" }} >

                                                                                                <input
                                                                                                    value={ratio.total}
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    autoComplete="off"
                                                                                                />
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>


                                                                                )
                                                                            }
                                                                            )
                                                                        }


                                                                        <Row>&nbsp;</Row>

                                                                    </div>
                                                                }
                                                            </div>  //sub brand close
                                                        )
                                                    })



                                                }



                                                <div>

                                                    {

                                                        this.state.brandDataArray.map((brandDt, idx) => {
                                                            return (
                                                                <div>
                                                                    {
                                                                        brandArr.brandNm == brandDt.brand &&
                                                                        <div>

                                                                            <Row>
                                                                                <Col style={{ padding: "2px" }} >
                                                                                    <input style={{ color: '#323232', fontSize: '11px' }}
                                                                                        value={brandDt.sleev}
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        autoComplete="off"
                                                                                    />
                                                                                </Col>

                                                                                <Col style={{ padding: "2px" }} >
                                                                                    <input style={{ color: '#323232' }}
                                                                                        value={brandDt.itemCount}
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        autoComplete="off"
                                                                                    />
                                                                                </Col>

                                                                                {
                                                                                    brandDt.sizeArry.map((brandsizeMap, idx) => {
                                                                                        return (
                                                                                            <Col style={{ padding: "2px" }}>
                                                                                                <input style={{ color: '#323232' }}
                                                                                                    value={brandsizeMap.qty}
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    autoComplete="off"
                                                                                                /></Col>

                                                                                        )
                                                                                    })
                                                                                }

                                                                                <Col style={{ padding: "2px" }} >
                                                                                    <input style={{ color: '#323232' }}
                                                                                        value={brandDt.total}
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        autoComplete="off"
                                                                                    />
                                                                                </Col>


                                                                            </Row>

                                                                        </div>
                                                                    }

                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </div>



                                            </div>   //brand close

                                        )

                                    })
                                }
                            </div>


                        </ModalBody>
                    </Modal>

                    {/* PlaceOrder Modal           */}
                    <Modal isOpen={this.state.placeOrderModal} toggle={this.togglePlaceOrder} className="modal-login modal-lg modal-dialog-centered" >
                        <ModalHeader toggle={() => this.togglePlaceOrder()}>
                            <h6 className="mb-0">Place Order</h6>
                        </ModalHeader>

                        <ModalBody>
                            <form name="form" onSubmit={this.popPlaceOrder} >

                                <Row>
                                    <Col xl={12}>
                                        <div className="table-responsive">
                                            <Table className="cart-table">
                                                <thead>
                                                    <tr>
                                                        <th className="product-name" style={{ paddingLeft: "40px" }}> Brand</th>
                                                        <th className="product-name" style={{ paddingLeft: "40px" }}> Delivery Type </th>
                                                        <th className="product-name" style={{ paddingLeft: "50px" }}> Actions</th>
                                                    </tr>
                                                    {
                                                        deliveryTypeArr.map((deliverySc, index) =>
                                                            <tr>
                                                                <td className="product-thumbnail">
                                                                    <div className="product-top-left-inner">
                                                                        <h6>{deliverySc.brand}</h6>
                                                                    </div>
                                                                </td>

                                                                <td className="product-thumbnail" >
                                                                    <div className="product-top-left-inner">
                                                                        <Row>
                                                                            <Col md={10}>
                                                                                <select name="rows" className="select2 w-100 d-block" onChange={(e) => this.deliveryTypeFunc(e, deliverySc.brand)}>
                                                                                    <option value="startingFrom" selected={deliverySc.noStartDateFlg ? "" : "selected"}>Starting Dispatch From</option>
                                                                                    <option value="noStartDateFlg" selected={deliverySc.noStartDateFlg ? "selected" : ""}>No Start Date</option>
                                                                                    <option value="others">Others</option>
                                                                                </select>
                                                                            </Col>
                                                                        </Row>


                                                                        {(deliverySc.deliveryType == "Starting Dispatch From" && deliverySc.noStartDateFlg == false) &&
                                                                            <Row style={{ marginTop: "8px" }}>
                                                                                <Col md={10}>
                                                                                    <input type="date" class="form-control" placeholder="Start Date" defaultValue={deliverySc.fromDate}
                                                                                        onChange={(e) => this.handleChangeDispatch(e, "fromDate", deliverySc.brand)}  ></input>
                                                                                </Col>
                                                                            </Row>
                                                                        }

                                                                    </div>
                                                                </td>
                                                                <td className="product-thumbnail" >
                                                                    <div className="product-top-left-inner">

                                                                        {deliverySc.deliveryInstrTxtFlg &&
                                                                            <Row >
                                                                                <Col md={10}>
                                                                                    <textarea class="form-control" type="text" rows="2" placeholder="Instruction" defaultValue={deliverySc.instruction}
                                                                                        onChange={(e) => this.handleChangeDispatch(e, "instr", deliverySc.brand)}  ></textarea>
                                                                                </Col>
                                                                            </Row>
                                                                        }

                                                                        {deliverySc.deliveryEndDateTypeFlg &&
                                                                            <Row>
                                                                                <Col md={10}>
                                                                                    <select name="rows" className="select2 w-100 d-block" onChange={(e) => this.deliveryEndDateType(e, deliverySc.brand)}>
                                                                                        <option value="endDate" selected={deliverySc.noEndDateFlg ? "" : "selected"}>End Date</option>
                                                                                        <option value="noEndDate" selected={deliverySc.noEndDateFlg ? "selected" : ""}>No End Date</option>
                                                                                    </select>
                                                                                </Col>
                                                                            </Row>

                                                                        }

                                                                        {deliverySc.deliveryEndDateFlg && deliverySc.noEndDateFlg == false &&
                                                                            <Row style={{ marginTop: "8px" }}>
                                                                                <Col md={10}>
                                                                                    <input type="date" class="form-control" placeholder="End Date" defaultValue={deliverySc.toDate}
                                                                                        onChange={(e) => this.handleChangeDispatch(e, "toDate", deliverySc.brand)} ></input>
                                                                                </Col>
                                                                            </Row>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                </thead>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>


                                <div className={'form-group' + (submitted && !ordusername ? ' has-error' : '')} style={{ marginTop: "20px" }}>
                                    <label htmlFor="username">Username</label>
                                    <input type="text" className="form-control" name="ordusername" value={ordusername}
                                        onChange={this.handleChange} autoComplete="off"

                                    />
                                    {submitted && !ordusername &&
                                        <div className="alert alert-danger" role="alert">
                                            This field is required!
                                        </div>
                                    }
                                </div>
                                <div className={'form-group' + (submitted && !ordpassword ? ' has-error' : '')}>
                                    <label htmlFor="ordpassword">Password</label>
                                    <input type="password" className="form-control" name="ordpassword" value={ordpassword}
                                        onChange={this.handleChange}

                                    />
                                    {submitted && !ordpassword &&
                                        <div className="alert alert-danger" role="alert">
                                            This field is required!
                                        </div>
                                    }
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-outline-success" disabled={this.state.mainSaveBtnFlg}>Save</button>
                                </div>

                            </form>

                        </ModalBody>

                    </Modal>


                    {/* Selection Ratio modal */}

                    <Modal isOpen={this.state.modal} toggle={this.toggle} style={{ maxWidth: "1050px" }} className="modal-login modal-lg modal-dialog-centered">
                        <ModalHeader toggle={this.toggle}>
                            {/* <h6 className="mb-0">Ratio</h6> */}

                            <Button className="loadmore-btn" onClick={() => this.getShirtsRatio("shirts")}
                            >Shirts Ratio</Button>


                            <Button onClick={() => this.getOrderTrouserRatioJson()} className="loadmore-btn"
                            >Trousers Ratio</Button>

                            <Button onClick={() => this.getShirtsRatio("boys")} className="loadmore-btn"
                            >Boys Ratio</Button>

                        </ModalHeader>
                        <ModalBody>
                            <Container>
                                <Form>
                                    {ratioErrorFlg == true && (
                                        <div>
                                            <label style={{ fontSize: "bold", color: "red" }}>Invalid Ratio</label>
                                        </div>
                                    )}
                                    {this.state.productType == 'shirts' && (
                                        <div>
                                            {
                                                this.state.ratioObject.map((ratioObj, idx) => {
                                                    return (
                                                        <div>
                                                            {
                                                                (typeof (ratioObj.hvPremiumRatio) == 'object') ?
                                                                    <div>
                                                                        <div>
                                                                        </div>
                                                                        <h3>HV</h3>
                                                                        <Row></Row>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                <label>Sleev</label></Col>
                                                                            {
                                                                                ratioObj.hvPremiumRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                            <label>{ratio.size}</label>
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>

                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Full</label></Col>
                                                                            {
                                                                                ratioObj.hvPremiumRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }} >

                                                                                            <Input
                                                                                                defaultValue={ratio.fqty}
                                                                                                value={ratio.fqty}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                autoComplete="off"
                                                                                                onBlur={(e) => this.updateHVPremiumqty(e, "F", ratio.size, ratio.fqty)}
                                                                                                onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                            />
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>

                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Half</label></Col>
                                                                            {
                                                                                ratioObj.hvPremiumRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }} >
                                                                                            <Input
                                                                                                defaultValue={ratio.hqty}
                                                                                                value={ratio.hqty}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                autoComplete="off"
                                                                                                onBlur={(e) => this.updateHVPremiumqty(e, "H", ratio.size, ratio.hqty)}
                                                                                                onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                            />
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                    </div>
                                                                    :
                                                                    null

                                                            }

                                                            {
                                                                (typeof (ratioObj.freezoneRatio) == 'object') ?
                                                                    <div>
                                                                        <h3>FREEZONE CASUALS</h3>
                                                                        <Row></Row>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Sleev</label></Col>
                                                                            {
                                                                                ratioObj.freezoneRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }} >
                                                                                            <label>{ratio.size}</label>
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }} ><label>Full</label></Col>
                                                                            {
                                                                                ratioObj.freezoneRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }} >
                                                                                            <Input
                                                                                                defaultValue={ratio.fqty}
                                                                                                value={ratio.fqty}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                autoComplete="off"
                                                                                                onBlur={(e) => this.updateFreezonqty(e, "F", ratio.size, ratio.fqty)}
                                                                                            />
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Half</label></Col>
                                                                            {
                                                                                ratioObj.freezoneRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                            <Input
                                                                                                defaultValue={ratio.hqty}
                                                                                                value={ratio.hqty}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                autoComplete="off"
                                                                                                onBlur={(e) => this.updateFreezonqty(e, "H", ratio.size, ratio.hqty)}
                                                                                                onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                            />
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>

                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                (typeof (ratioObj.formalsRatio) == 'object') ?
                                                                    <div>
                                                                        <h3>SERO FORMALS</h3>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Sleev</label></Col>
                                                                            {
                                                                                ratioObj.formalsRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                            <label >{ratio.size}</label>
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Full</label></Col>
                                                                            {
                                                                                ratioObj.formalsRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                            <Input
                                                                                                defaultValue={ratio.fqty}
                                                                                                value={ratio.fqty}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                autoComplete="off"
                                                                                                onBlur={(e) => this.updateSeroFormalqty(e, "F", ratio.size, ratio.fqty)}
                                                                                                onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                            />
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Half</label></Col>
                                                                            {
                                                                                ratioObj.formalsRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                            <Input
                                                                                                defaultValue={ratio.hqty}
                                                                                                value={ratio.hqty}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                autoComplete="off"
                                                                                                onBlur={(e) => this.updateSeroFormalqty(e, "H", ratio.size, ratio.hqty)}
                                                                                                onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                            />
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                    </div>
                                                                    :
                                                                    null

                                                            }

                                                            {
                                                                (typeof (ratioObj.premiumRatio) == 'object') ?
                                                                    <div>
                                                                        <h3>SERO PREMIUM </h3>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Sleev</label></Col>
                                                                            {
                                                                                ratioObj.premiumRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                            <label >{ratio.size}</label>
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Full</label></Col>
                                                                            {
                                                                                ratioObj.premiumRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                            <Input
                                                                                                defaultValue={ratio.fqty}
                                                                                                value={ratio.fqty}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                autoComplete="off"
                                                                                                onBlur={(e) => this.updatePremiumqty(e, "F", ratio.size, ratio.fqty)}
                                                                                                onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                            />
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Half</label></Col>
                                                                            {
                                                                                ratioObj.premiumRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                            <Input
                                                                                                defaultValue={ratio.hqty}
                                                                                                value={ratio.hqty}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                autoComplete="off"
                                                                                                onBlur={(e) => this.updatePremiumqty(e, "H", ratio.size, ratio.hqty)}
                                                                                                onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                            />
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }


                                                            {
                                                                (typeof (ratioObj.uthRatio) == 'object') ?
                                                                    <div>
                                                                        <h3>UTH </h3>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Sleev</label></Col>
                                                                            {
                                                                                ratioObj.uthRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                            <label >{ratio.size}</label>
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Full</label></Col>
                                                                            {
                                                                                ratioObj.uthRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                            <Input
                                                                                                defaultValue={ratio.fqty}
                                                                                                value={ratio.fqty}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                autoComplete="off"
                                                                                                onBlur={(e) => this.updateUthqty(e, "F", ratio.size, ratio.fqty)}
                                                                                                onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                            />
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Half</label></Col>
                                                                            {
                                                                                ratioObj.uthRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                            <Input
                                                                                                defaultValue={ratio.hqty}
                                                                                                value={ratio.hqty}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                autoComplete="off"
                                                                                                onBlur={(e) => this.updateUthqty(e, "H", ratio.size, ratio.hqty)}
                                                                                                onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                            />
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                    </div>
                                                                    :
                                                                    null

                                                            }

                                                            {
                                                                (typeof (ratioObj.whiteRatio) == 'object') ?
                                                                    <div>
                                                                        <h3>WHITE GOLD</h3>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Sleev</label></Col>
                                                                            {
                                                                                ratioObj.whiteRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                            <label >{ratio.size}</label>
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Full</label></Col>
                                                                            {
                                                                                ratioObj.whiteRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                            <Input
                                                                                                defaultValue={ratio.fqty}
                                                                                                value={ratio.fqty}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                autoComplete="off"
                                                                                                onBlur={(e) => this.updateWhiteGoldqty(e, "F", ratio.size, ratio.fqty)}
                                                                                                onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                            />
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Half</label></Col>
                                                                            {
                                                                                ratioObj.whiteRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                            <Input
                                                                                                defaultValue={ratio.hqty}
                                                                                                value={ratio.hqty}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                autoComplete="off"
                                                                                                onBlur={(e) => this.updateWhiteGoldqty(e, "H", ratio.size, ratio.hqty)}
                                                                                                onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                            />
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }

                                                            {
                                                                (typeof (ratioObj.marryMeRatio) == 'object') ?
                                                                    <div>
                                                                        <h3>MARRY ME</h3>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Sleev</label></Col>
                                                                            {
                                                                                ratioObj.marryMeRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                            <label >{ratio.size}</label>
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Full</label></Col>
                                                                            {
                                                                                ratioObj.marryMeRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                            <Input
                                                                                                defaultValue={ratio.fqty}
                                                                                                value={ratio.fqty}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                autoComplete="off"
                                                                                                onBlur={(e) => this.updateMarryMeqty(e, "F", ratio.size, ratio.fqty)}
                                                                                                onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                            />
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Half</label></Col>
                                                                            {
                                                                                ratioObj.marryMeRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                            <Input
                                                                                                defaultValue={ratio.hqty}
                                                                                                value={ratio.hqty}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                autoComplete="off"
                                                                                                onBlur={(e) => this.updateMarryMeqty(e, "H", ratio.size, ratio.hqty)}
                                                                                                onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                            />
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }

                                                            {
                                                                (typeof (ratioObj.fairDealRatio) == 'object') ?
                                                                    <div>
                                                                        <h3>FAIRDEAL </h3>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Sleev</label></Col>
                                                                            {
                                                                                ratioObj.fairDealRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                            <label >{ratio.size}</label>
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Full</label></Col>
                                                                            {
                                                                                ratioObj.fairDealRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                            <Input
                                                                                                defaultValue={ratio.fqty}
                                                                                                value={ratio.fqty}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                autoComplete="off"
                                                                                                onBlur={(e) => this.updatefairdealqty(e, "F", ratio.size, ratio.fqty)}
                                                                                                onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                            />
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                        <Row>
                                                                            <Col style={{ padding: "2px", textAlign: "center" }}><label>Half</label></Col>
                                                                            {
                                                                                ratioObj.fairDealRatio.map((ratio, idx) => {
                                                                                    return (
                                                                                        <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                            <Input
                                                                                                defaultValue={ratio.hqty}
                                                                                                value={ratio.hqty}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                autoComplete="off"
                                                                                                onBlur={(e) => this.updatefairdealqty(e, "H", ratio.size, ratio.hqty)}
                                                                                                onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                            />
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                    </div>
                                                                    :
                                                                    null

                                                            }



                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )}

                                    {/* Trousers Ratio Start    */}

                                    {
                                        this.state.productType == 'trousers' && (
                                            <div>

                                                {

                                                    this.state.trousersRatioObject.map((ratioObj, idx) => {
                                                        return (
                                                            <div>
                                                                {/* Formal Trosers */}
                                                                {
                                                                    (typeof (ratioObj.formalTrousersRatio) == 'object') ?
                                                                        <div>
                                                                            <h3>FORMAL TROUSERS</h3>
                                                                            <Row></Row>
                                                                            <Row>
                                                                                <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                    <label>
                                                                                        Size</label></Col>
                                                                                {
                                                                                    ratioObj.formalTrousersRatio.map((ratio, idx) => {
                                                                                        return (
                                                                                            <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                                <label>{ratio.size}</label>
                                                                                            </Col>
                                                                                        )
                                                                                    }
                                                                                    )
                                                                                }
                                                                            </Row>

                                                                            <Row>
                                                                                <Col style={{ padding: "2px", textAlign: "center" }}><label>Pleat</label></Col>
                                                                                {
                                                                                    ratioObj.formalTrousersRatio.map((ratio, idx) => {
                                                                                        return (
                                                                                            <Col style={{ padding: "2px", textAlign: "center" }} >
                                                                                                <Input
                                                                                                    defaultValue={ratio.pqty}
                                                                                                    value={ratio.pqty}
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    autoComplete="off"
                                                                                                    onBlur={(e) => this.updateFormalTrouser(e, "Pleat", ratio.size, ratio.pqty)}
                                                                                                    onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                                />
                                                                                            </Col>
                                                                                        )
                                                                                    }
                                                                                    )
                                                                                }
                                                                            </Row>

                                                                            <Row>
                                                                                <Col style={{ padding: "2px", textAlign: "center" }}><label>Smart Fit(Flat)</label></Col>
                                                                                {
                                                                                    ratioObj.formalTrousersRatio.map((ratio, idx) => {
                                                                                        return (
                                                                                            <Col style={{ padding: "2px", textAlign: "center" }} >
                                                                                                <Input
                                                                                                    defaultValue={ratio.sfqty}
                                                                                                    value={ratio.sfqty}
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    autoComplete="off"
                                                                                                    onBlur={(e) => this.updateFormalTrouser(e, "smart", ratio.size, ratio.sfqty)}
                                                                                                    onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                                />
                                                                                            </Col>
                                                                                        )
                                                                                    }
                                                                                    )
                                                                                }
                                                                            </Row>

                                                                            <Row>
                                                                                <Col style={{ padding: "2px", textAlign: "center" }}><label>Comfort Fit(Flat)</label></Col>
                                                                                {
                                                                                    ratioObj.formalTrousersRatio.map((ratio, idx) => {
                                                                                        return (
                                                                                            <Col style={{ padding: "2px", textAlign: "center" }} >
                                                                                                <Input
                                                                                                    defaultValue={ratio.cfqty}
                                                                                                    value={ratio.cfqty}
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    autoComplete="off"
                                                                                                    onBlur={(e) => this.updateFormalTrouser(e, "comfort", ratio.size, ratio.cfqty)}
                                                                                                    onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                                />
                                                                                            </Col>
                                                                                        )
                                                                                    }
                                                                                    )
                                                                                }
                                                                            </Row>

                                                                        </div>
                                                                        :
                                                                        null

                                                                }

                                                                {/* Casual Trousers */}
                                                                {

                                                                    (typeof (ratioObj.casualTrousersRatio) == 'object') ?
                                                                        <div>
                                                                            <h3>COTTON TROUSERS</h3>
                                                                            <Row></Row>
                                                                            <Row>
                                                                                <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                    <label>
                                                                                        Size</label></Col>
                                                                                {
                                                                                    ratioObj.casualTrousersRatio.map((ratio, idx) => {
                                                                                        return (
                                                                                            <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                                <label>{ratio.size}</label>
                                                                                            </Col>
                                                                                        )
                                                                                    }
                                                                                    )
                                                                                }
                                                                            </Row>

                                                                            <Row>
                                                                                <Col style={{ padding: "2px", textAlign: "center" }}><label>Pleat</label></Col>
                                                                                {
                                                                                    ratioObj.casualTrousersRatio.map((ratio, idx) => {
                                                                                        return (
                                                                                            <Col style={{ padding: "2px", textAlign: "center" }} >
                                                                                                <Input
                                                                                                    defaultValue={ratio.pqty}
                                                                                                    value={ratio.pqty}
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    autoComplete="off"
                                                                                                    onBlur={(e) => this.updateCasualTrouser(e, "Pleat", ratio.size, ratio.pqty)}
                                                                                                    onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                                />
                                                                                            </Col>
                                                                                        )
                                                                                    }
                                                                                    )
                                                                                }
                                                                            </Row>

                                                                            <Row>
                                                                                <Col style={{ padding: "2px", textAlign: "center" }}><label>Smart Fit(Flat)</label></Col>
                                                                                {
                                                                                    ratioObj.casualTrousersRatio.map((ratio, idx) => {
                                                                                        return (
                                                                                            <Col style={{ padding: "2px", textAlign: "center" }} >
                                                                                                <Input
                                                                                                    defaultValue={ratio.sfqty}
                                                                                                    value={ratio.sfqty}
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    autoComplete="off"
                                                                                                    onBlur={(e) => this.updateCasualTrouser(e, "smart", ratio.size, ratio.sfqty)}
                                                                                                    onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                                />
                                                                                            </Col>
                                                                                        )
                                                                                    }
                                                                                    )
                                                                                }
                                                                            </Row>

                                                                            <Row>
                                                                                <Col style={{ padding: "2px", textAlign: "center" }}><label>Comfort Fit(Flat)</label></Col>
                                                                                {
                                                                                    ratioObj.casualTrousersRatio.map((ratio, idx) => {
                                                                                        return (
                                                                                            <Col style={{ padding: "2px", textAlign: "center" }} >
                                                                                                <Input
                                                                                                    defaultValue={ratio.cfqty}
                                                                                                    value={ratio.cfqty}
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    autoComplete="off"
                                                                                                    onBlur={(e) => this.updateCasualTrouser(e, "comfort", ratio.size, ratio.cfqty)}
                                                                                                    onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                                />
                                                                                            </Col>
                                                                                        )
                                                                                    }
                                                                                    )
                                                                                }
                                                                            </Row>

                                                                        </div>
                                                                        :
                                                                        null

                                                                }


                                                                {/* Denims */}

                                                                {


                                                                    (typeof (ratioObj.denimsRatio) == 'object') ?
                                                                        <div>
                                                                            <h3>DENIM</h3>
                                                                            <Row></Row>
                                                                            <Row>
                                                                                <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                    <label>
                                                                                        Size</label></Col>
                                                                                {
                                                                                    ratioObj.denimsRatio.map((ratio, idx) => {
                                                                                        return (
                                                                                            <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                                <label>{ratio.size}</label>
                                                                                            </Col>
                                                                                        )
                                                                                    }
                                                                                    )
                                                                                }
                                                                            </Row>


                                                                            <Row>
                                                                                <Col style={{ padding: "2px", textAlign: "center" }}><label>Smart Fit(Flat)</label></Col>
                                                                                {
                                                                                    ratioObj.denimsRatio.map((ratio, idx) => {
                                                                                        return (
                                                                                            <Col style={{ padding: "2px", textAlign: "center" }} >
                                                                                                <Input
                                                                                                    defaultValue={ratio.sfqty}
                                                                                                    value={ratio.sfqty}
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    autoComplete="off"
                                                                                                    onBlur={(e) => this.updateDenimTrouser(e, "smart", ratio.size, ratio.sfqty)}
                                                                                                    onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                                />
                                                                                            </Col>
                                                                                        )
                                                                                    }
                                                                                    )
                                                                                }
                                                                            </Row>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        )}


                                    {/* Boys Ratio Start    */}

                                    {
                                        this.state.productType == 'boys' && (
                                            <div>
                                                {
                                                    this.state.ratioObject.map((ratioObj, idx) => {
                                                        return (
                                                            <div>
                                                                {
                                                                    (typeof (ratioObj.boysRatio) == 'object') ?
                                                                        <div>
                                                                            <h3>FREEZONE BOYS </h3>
                                                                            <Row>
                                                                                <Col style={{ padding: "2px", textAlign: "center" }}><label>Sleev</label></Col>
                                                                                {
                                                                                    ratioObj.boysRatio.map((ratio, idx) => {
                                                                                        return (
                                                                                            <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                                <label >{ratio.size}</label>
                                                                                            </Col>
                                                                                        )
                                                                                    }
                                                                                    )
                                                                                }
                                                                            </Row>
                                                                            <Row>
                                                                                <Col style={{ padding: "2px", textAlign: "center" }}><label>Full</label></Col>
                                                                                {
                                                                                    ratioObj.boysRatio.map((ratio, idx) => {
                                                                                        return (
                                                                                            <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                                <Input
                                                                                                    defaultValue={ratio.fqty}
                                                                                                    value={ratio.fqty}
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    autoComplete="off"
                                                                                                    onBlur={(e) => this.updateboysqty(e, "F", ratio.size, ratio.fqty)}
                                                                                                    onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                                />
                                                                                            </Col>
                                                                                        )
                                                                                    }
                                                                                    )
                                                                                }
                                                                            </Row>
                                                                            <Row>
                                                                                <Col style={{ padding: "2px", textAlign: "center" }}><label>Half</label></Col>
                                                                                {
                                                                                    ratioObj.boysRatio.map((ratio, idx) => {
                                                                                        return (
                                                                                            <Col style={{ padding: "2px", textAlign: "center" }}>
                                                                                                <Input
                                                                                                    defaultValue={ratio.hqty}
                                                                                                    value={ratio.hqty}
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    autoComplete="off"
                                                                                                    onBlur={(e) => this.updateboysqty(e, "H", ratio.size, ratio.hqty)}
                                                                                                    onClick={(e) => this.setFocustKey(ratio.size)}
                                                                                                />
                                                                                            </Col>
                                                                                        )
                                                                                    }
                                                                                    )
                                                                                }
                                                                            </Row>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        )}
                                </Form>
                            </Container>

                            <div style={{ textAlign: "center", paddingTop: "10px" }} className={"col-md-3"}>
                                <a onClick={this.applyOrderRatio} className="loadmore-btn" >
                                    Apply Ratio</a>
                            </div>

                        </ModalBody>
                    </Modal>



                    {/* modal-delete */}
                    <Modal isOpen={this.state.modal4} toggle={this.toggle4} className="modal-login modal-md modal-dialog-centered">
                        <ModalHeader toggle={this.toggle4}>
                            <h4 className="mb-0">Do you want delete product</h4>
                        </ModalHeader>

                        <ModalBody>
                            <div className="success-screen">
                                <div className="row">
                                    <div className="col-md-4" style={{ paddingLeft: "15px" }}>
                                        <Button style={{ paddingTop: "5px" }} outline color="success" onClick={(e) => this.deleteOrderDt(e)}>Yes</Button>&nbsp;&nbsp;
                                        <Button style={{ paddingTop: "5px" }} outline color="danger" onClick={this.toggle4}>No</Button>&nbsp;
                                    </div>
                                    <div className="col-md-6" style={{ paddingLeft: "5px" }}>

                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>

                    {/* modal-delete */}
                    <Modal isOpen={this.state.modalDeleteAll} toggle={this.toggleDeleteAll} className="modal-login modal-md modal-dialog-centered">
                        <ModalHeader toggle={this.toggleDeleteAll}>
                            <h4 className="mb-0">Do you want delete products</h4>
                        </ModalHeader>

                        <ModalBody>
                            <div className="success-screen">
                                <div className="row">
                                    <div className="col-md-4" style={{ paddingLeft: "15px" }}>
                                        <Button style={{ paddingTop: "5px" }} outline color="success" onClick={(e) => this.deleteAllOrderDt(e)}>Yes</Button>&nbsp;&nbsp;
                                        <Button style={{ paddingTop: "5px" }} outline color="danger" onClick={this.toggleDeleteAll}>No</Button>&nbsp;
                                    </div>
                                    <div className="col-md-6" style={{ paddingLeft: "5px" }}>

                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>

                    {/* modal-deleteZeroQty */}
                    <Modal isOpen={this.state.modalDeleteZeroQty} toggle={this.toggleDeleteZeroQty} className="modal-login modal-md modal-dialog-centered">
                        <ModalHeader toggle={this.toggleDeleteZeroQty}>
                            <h4 className="mb-0">Do you want delete products</h4>
                        </ModalHeader>

                        <ModalBody>
                            <div className="success-screen">
                                <div className="row">
                                    <div className="col-md-4" style={{ paddingLeft: "15px" }}>
                                        <Button style={{ paddingTop: "5px" }} outline color="success" onClick={(e) => this.deleteOrderDtZeroQty(e)}>Yes</Button>&nbsp;&nbsp;
                                        <Button style={{ paddingTop: "5px" }} outline color="danger" onClick={this.toggleDeleteZeroQty}>No</Button>&nbsp;
                                    </div>
                                    <div className="col-md-6" style={{ paddingLeft: "5px" }}>

                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>

                    {/* modal-copy */}
                    <Modal isOpen={this.state.modalCopySleev} toggle={this.toggleCopyProductBySleev} className="modal-login modal-md modal-dialog-centered">
                        <ModalHeader toggle={this.toggleCopyProductBySleev}>
                            <h4 className="mb-0">Copy Product By Sleev</h4>
                        </ModalHeader>

                        <ModalBody>
                            <div className="success-screen">
                                <div className="row">
                                    <div className='col-md-2'></div>

                                    <div className='col-md-4'>
                                        <div class="custom-control custom-radio custom-control-inline" style={{ paddingLeft: "15px" }}>
                                            <input type="radio" id="hpToFSleevFlg" name="hpToFSleevFlg" class="custom-control-input" value="hpToF" onChange={this.copySleevHandleChangeEvent.bind(this, "copySleev")} checked={this.state.copySleevVal === "hpToF"}></input>
                                            <label class="custom-control-label" for="hpToFSleevFlg" >H/P To F</label>
                                        </div>
                                    </div>

                                    <div className='col-md-4'>
                                        <div class="custom-control custom-radio custom-control-inline" style={{ paddingLeft: "15px" }}>
                                            <input type="radio" id="ftohpSleevFlg" name="ftohpSleevFlg" class="custom-control-input" value="fTohp" onChange={this.copySleevHandleChangeEvent.bind(this, "copySleev")} checked={this.state.copySleevVal === "fTohp"}></input>
                                            <label class="custom-control-label" for="ftohpSleevFlg">F To H/P</label>
                                        </div>
                                    </div>

                                </div>

                                <div className='row' style={{ marginTop: "5px" }}>
                                    <div className='col-md-4'></div>

                                    <div class="col-md-4">
                                        <Button onClick={(e) => this.copyOrderBySleev(e)} outline color="success" > Submit</Button>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>


                    <Modal isOpen={this.state.modalPleaseWait} toggle={this.togglePleaseWait} className="modal-login modal-dialog-centered">
                        <ModalHeader toggle={this.togglePleaseWait}>
                        </ModalHeader>
                        <ModalBody>
                            <h1 className="mb-0">Please Wait...</h1>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={this.state.modalError} toggle={this.toggleError} className="modal-login modal-dialog-centered">
                        <ModalHeader toggle={this.toggleError} style={{ background: "#ff5349", padding: "10px" }}>
                            <h6 style={{ color: "white" }}>Warning</h6>
                        </ModalHeader>
                        <ModalBody>
                            <h3 style={{ color: "red", textAlign: "center" }}>{this.state.errorMsg}</h3>
                        </ModalBody>
                    </Modal>


                </div>

            </Container>

        )
    }
}



export default AddOrder;