import React, { Component } from 'react';
import { Col, Container, Row, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import userService from "../../services/user.services";
import AuthService from '../../services/auth.service';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import Select, { createFilter } from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { matchSorter } from 'match-sorter';
import Switch from "react-switch";
import Form from "react-validation/build/form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class OrderMaster extends Component {

    constructor(props) {
        super(props);
        this.state = {
            orderList: [],
            disabledButton: true,
            searchOrder: '',
            searchParty: '',
            searchPartyCode: '',
            orderNo: '',
            party: '',
            partyCode: '',
            orderNewList: [],
            stateNewList: [],
            partyNewList: [],
            partyCodeNewList: [],
            modal: false,
            orderMtId: '',
            partyData: [],
            value: '',
            options: [],
            compOrderQty: [],
            modal1: false,
            companyData: [],
            value1: null,
            options1: [],
            modal2: false,
            modal3: false,
            mobileno: '',
            modal4: false,
            rowOrderData: [],
            modalSummary: false,
            sleevWiseOrderData: [],
            brandDataArray: [],
            brandArray: [],
            modalPleaseWait: false,
            dropdownOpen: false,
            switchFlg: false,
            modalNew: false,
            orderDate: "",
            fieldvalue: "",
            partySelectedValue: "",
            submitted: false,
            submitFlg: false,
            modalError: false,
            errorMsg: '',
            partyId: '',

        };

        this.toggle = this.toggle.bind(this);
        this.orderCopySubmit = this.orderCopySubmit.bind(this);
        this.toggle1 = this.toggle1.bind(this);
        this.toggle2 = this.toggle2.bind(this);
        this.toggle3 = this.toggle3.bind(this);
        this.toggle4 = this.toggle4.bind(this);
        this.toggleSummary = this.toggleSummary.bind(this);
        this.togglePleaseWait = this.togglePleaseWait.bind(this);
        this.toggleBtnDropdown = this.toggleBtnDropdown.bind(this);
        this.switchChange = this.switchChange.bind(this);
        this.toggleNew = this.toggleNew.bind(this);
        this.toggleError = this.toggleError.bind(this);
        this.handleOrderSubmit = this.handleOrderSubmit.bind(this);


    }

    switchChange(switchFlg) {
        this.setState({ switchFlg });
    }

    toggleNew() {
        if (!this.state.modalNew) {
            this.addOrderEmptyJson();
            this.getPartyData();
        }

        this.setState(prevState => ({
            modalNew: !prevState.modalNew
        }));
    }

    toggleError() {
        this.setState(prevState => ({
            modalError: !prevState.modalError
        }));
    }

    handleOrderSubmit(event) {

        event.preventDefault();

        this.setState({
            submitted: true,
        });

        if (this.state.submitFlg === false && this.state.partySelectedValue != '') {
            this.setState({ submitFlg: true });

            userService.addOrder(this.state.fieldvalue).then(
                response => {
                    if (response.data['status'] === 'SUCCESS') {
                        toast.success(response.data['message']);

                        this.props.history.push({
                            pathname: '/editOrder/' + response.data['jsonObject'].orderMtId
                        })

                    } else if (response.data['status'] === 'Error') {
                        this.setState({ errorMsg: response.data['message'], submitFlg: false });
                        this.toggleError();
                    }
                }
            ).catch(error => {

                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')
                } else {
                    console.log('error ', error)
                }
            })
        }

    }

    togglePleaseWait() {
        this.setState(prevState => ({
            modalPleaseWait: !prevState.modalPleaseWait
        }));
    }

    toggleBtnDropdown() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    toggleSummary(orderMtId) {

        if (this.state.modalSummary === false) {
            this.getSleevWiseOrderData(orderMtId);
        }
        
        this.setState(prevState => ({
            modalSummary: !prevState.modalSummary,
        }))
     //   this.getSleevWiseOrderData(orderMtId);
    }

    getSleevWiseOrderData(orderMtId) {
        userService.getSleevWiseOrderData(orderMtId)
            .then(response =>{
                this.setState({
                    brandArray: JSON.parse(response.data['jsonObject'])['brandArray'],
                    sleevWiseOrderData: JSON.parse(response.data['jsonObject'])['subBrandArray'],
                    brandDataArray: JSON.parse(response.data['jsonObject'])['brandTotArray'],
                })
                }
            ).catch(error => {

                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')
                } else {
                    console.log('error ', error)
                }

            })
    }

    getOrderList() {
        userService.getOrderHistoryList(localStorage.getItem('seasonId')).then(response =>
            this.setState({
                orderList: JSON.parse(response.data['jsonObject'])
            }),
        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {

                console.log('error ', error)
            }

        })

    }

    componentWillMount() {
        this.getOrderList();
    }

    whatsAppSendReport(e, orderMtId) {
        this.setState({
            orderMtId: orderMtId,
            mobileno: ''
        });
        this.toggle3();
    }


    sendWhatsappFunc(e) {
        userService.whatsAppSendReport(this.state.orderMtId, this.state.mobileno).then(response => {
            if (response.data['status'] == 'SUCCESS') {
                toast.success(response.data['message'])
            }
        }
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ');
                AuthService.logout();
                this.props.history.push('/');

            } else {
                console.log('error ', error)
            }

        })

    }


    deleteOrder(e, rowOrderData, orderLockFlg) {
        if (!rowOrderData.orderLockFlg) {

            this.setState({
                orderMtId: rowOrderData.orderMtId,
                rowOrderData: rowOrderData
            });
            this.toggle4();
        } else {
            toast.warn("Can not delete, Order is lock");
        }
    }

    deleteOrderMt(e) {
        userService.deleteOrderMt(this.state.orderMtId).then(response => {
            {
                if (response.data['status'] === 'SUCCESS') {
                    this.toggle4();
                    // this.getOrderList();

                    toast.success(response.data['message'])

                    let deleteOrder = this.state.rowOrderData;
                    let newOrderData = this.state.orderList.filter((order) => order.orderMtId !== deleteOrder.orderMtId)
                    this.setState({
                        ...this.state,
                        orderList: newOrderData
                    })
                }

                if (response.data['status'] === 'Error') {
                    this.toggle4(); toast.warn(response.data['message'])
                }
            }
        }).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ');
                AuthService.logout();
                this.props.history.push('/');
            } else {
                console.log('error ', error)
            }

        })

    }

    addOrder() { 
        this.toggleNew();
        // this.props.history.push('/addOrder');
    }

    editOrderMt(e, orderMtData) {

        if (localStorage.getItem('bookingAtStudio') === 'true' && orderMtData.bookingAtStudioFlg === true) {
            this.props.history.push({
                pathname: '/editOrder/' + orderMtData.orderMtId
            })
        } else if (localStorage.getItem('bookingAtStudio') == 'true' && orderMtData.bookingAtStudioFlg == false) {
            alert('Can not edit order');
        } else if (localStorage.getItem('bookingAtStudio') == 'false' && orderMtData.bookingAtStudioFlg == true) {
            alert('Can not edit order');
        } else {
            this.props.history.push({
                pathname: '/editOrder/' + orderMtData.orderMtId
            })
        }


    }

    reportPreview(repornm) {
        var link = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
        link.href = '../pdf/' + repornm;
        link.target = '_blank';
        var event = new MouseEvent('click', {
            'view': window,
            'bubbles': false,
            'cancelable': true
        });
        link.dispatchEvent(event);

        userService.downloadOrderReport(repornm).then(response => {
        }).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }

        })
    }

    handleSubmit(repornm) {
        let link = document.createElement('a');
        link.href = '../pdf/' + repornm;
        link.download = repornm;
        link.dispatchEvent(new MouseEvent('click'));
        userService.downloadOrderReport(repornm).then(response => {
        }).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }

        })
    }

    previewOrderMtReport(orderMtId) {

        let repornm = '';
        userService.previewOrderMtReport(orderMtId).then(response => {
            repornm = response.data['jsonObject']
            // this.handleSubmit(repornm)
            this.reportPreview(repornm);
        }
        )

    }



    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    orderCopySubmit(e) {
        e.preventDefault();
        this.toggle();
        this.togglePleaseWait();

        userService.orderCopy(this.state.orderMtId, this.state.value.value, localStorage.getItem('seasonId'),
            localStorage.getItem('bookingAtStudio')).then(response => {
                if (response.data['status'] === 'SUCCESS') {
                    toast.success(response.data['message']);
                    this.togglePleaseWait();
                } else {
                    this.togglePleaseWait();
                    toast.warn(response.data['message']);
                }
                this.setState({ options: [] })
                //  this.toggle()
                this.getOrderList();
            }

            ).catch(error => {

                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ')
                    AuthService.logout()
                    this.props.history.push('/')
                } else {
                    console.log('error ', error)
                }

            })
    }

    popOrderCopy(e, orderMtId) {
        this.setState({
            orderMtId: orderMtId,
            value: ''
        });
        this.getPartyData();
        this.toggle();
    }

    getOrdPartyCode = value => {
        this.setState({ value: value });
    }

    getPartyCodeDt = value => {

        let fieldvalue = this.state.fieldvalue;
        fieldvalue["partyId"] = value.value;
        this.setState({ fieldvalue, partySelectedValue: value, partyId: value.value });

    }

    getPartyData() {
        userService.getPartyData().then(response =>
            this.setState({
                partyData: JSON.parse(response.data.jsonObject),
                options: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }

        })
    }

    companyWiseOrderQty(orderId) {
        userService.companyWiseOrderQty(orderId).then(response => {
            this.setState({ compOrderQty: JSON.parse(response.data.jsonObject) })
            this.toggle1();
        }
        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {
                console.log('error ', error)
            }

        })
    }


    toggle1() {
        this.setState(prevState => ({
            modal1: !prevState.modal1
        }));
    }


    toggle3() {
        this.setState(prevState => ({
            modal3: !prevState.modal3
        }));
    }

    toggle4() {
        this.setState(prevState => ({
            modal4: !prevState.modal4
        }));
    }

    downloadOrderExcel(e) {
        let xmlName = '';
        userService.generateOrderXml(this.state.orderMtId, this.state.value1.value).then(response => {
            xmlName = response.data['jsonObject']
            this.handleSubmit(xmlName)
        }
        ).catch(error => {
            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')
            } else {
                console.log('error ', error)
            }

        })
    }

    toggle2() {
        this.setState(prevState => ({
            modal2: !prevState.modal2
        }));
    }


    popDownloadExcel(e, orderMtId, orderLockFlg) {
        if (orderLockFlg) {
            this.setState({
                orderMtId: orderMtId,
                value1: ''
            });
            this.getCompanyData();
            this.toggle2();
        } else {
            toast.warn("Can not download, Please lock the order");
        }
    }

    getOrdCompany = value => {
        this.setState({ value1: value })
    }

    getCompanyData() {
        userService.getCompanyData().then(response =>
            this.setState({
                companyData: JSON.parse(response.data.jsonObject),
                options1: JSON.parse(response.data.jsonObject),
            })
        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {

                console.log('error ', error)
            }

        })
    }

    orderLockFunc(e, orderMtId, orderLockFlg, role) {

        if (role === 'Admin') {

            userService.orderLockFunc(orderMtId, orderLockFlg).then(response => {
                if (response.data['status'] == 'SUCCESS') {

                    let lockStaus = 'Lock';
                    if (orderLockFlg) {
                        orderLockFlg = false;
                        lockStaus = 'Unlock';
                    } else {
                        orderLockFlg = true;
                    }
                    //  this.getOrderList();
                    toast.success(response.data['message'])
                    let newOrderData = this.state.orderList;

                    newOrderData.filter((order) => order.orderMtId == orderMtId).map((order) => {
                        order.orderLockFlg = orderLockFlg;
                        order.orderLockStatus = lockStaus;
                    })

                    this.setState({
                        ...this.state,
                        orderList: newOrderData
                    })

                }
            }).catch(error => {

                if (error.response.status === 403) {
                    alert('Your Login Session Is Expire ,Please Login ');
                    AuthService.logout();
                    this.props.history.push('/');
                } else {
                    console.log('error ', error)
                }

            })

        } else {
            alert("you don't have rights to perform this operation");
        }
    }

    popSummary(e, orderMtId) {
        this.toggleSummary(orderMtId);
    }

    multiAction = (e, orderMtId, orderLockFlg) => {

        if (e.target.value === 'compQty') {
            this.companyWiseOrderQty(orderMtId);
        } else if (e.target.value === 'download') {
            this.popDownloadExcel(e, orderMtId, orderLockFlg);
        } else if (e.target.value === 'whatsapp') {
            this.whatsAppSendReport(e, orderMtId);
        } else if (e.target.value === 'preview') {
            this.previewOrderMtReport(orderMtId);
        } else if (e.target.value === 'orderCopy') {
            this.popOrderCopy(e, orderMtId);
        } else if (e.target.value === 'summary') {
            this.popSummary(e, orderMtId);
        }

    }

    onDateChange(dateVal) {
        let fieldvalue = this.state.fieldvalue;
        fieldvalue["orderDate"] = dateVal;
        this.setState({ fieldvalue, orderDate: dateVal });
    }

    convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    addOrderEmptyJson() {
        userService.addOrderEmptyJson(localStorage.getItem('seasonId'), localStorage.getItem('bookingAtStudio')).then(response =>
            this.setState({
                fieldvalue: response.data, partySelectedValue: '', orderDate: new Date()
            })
        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {

                console.log('error ', error)
            }

        })
    }

    render() {

        const filterConfig = { matchFrom: 'start' }
        const companyWiseData = this.state.compOrderQty;

        const { orderDate, submitted,sleevWiseOrderData } = this.state;

        const columns = [

            {
                width: 60,
                Header: 'Delete',
                accessor: 'action2',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <Link className="btn btn-outline-danger" onClick={(e) => this.deleteOrder(e, props.original)}
                                href="#"><i className="fa fa-trash-o"></i></Link>
                        </div>
                    )
                },
            },
            {
                width: 60,
                Header: 'Edit',
                accessor: 'action1',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            <Link className="btn btn-outline-info" onClick={(e) => this.editOrderMt(e, props.original)} href="#">
                                <i class="fa fa-edit"></i></Link>
                        </div>
                    )
                },
            },
            {
                sortable: false,
                width: 150,
                Header: 'Transaction Id',
                accessor: 'orderNo',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["orderNo"] }),
                filterAll: true,
            },
            {
                Header: 'Party Code',
                accessor: 'partyCode',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["partyCode"] }),
                filterAll: true,
            },

            {
                width: 160,
                Header: 'Buyer',
                accessor: 'party',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["party"] }),
                filterAll: true,
            },
            {
                Header: 'Date',
                accessor: 'ordDate',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["ordDate"] }),
                filterAll: true,
            },
            {
                Header: 'Quantity',
                accessor: 'qty',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["qty"] }),
                filterAll: true,

            },
            {
                width: 160,
                Header: 'Action',
                accessor: 'action',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            {props.original.status === 'Confirm' ?
                                <select name='actions' onChange={(e) => this.multiAction(e, props.original.orderMtId, props.original.orderLockFlg)}>
                                    <option value="select">Select</option>
                                    <option value="compQty">Comp Qty</option>
                                    <option value="download">Download</option>
                                    <option value="whatsapp">Whatsapp</option>
                                    <option value="preview" >Preview</option>
                                    <option value="orderCopy">Order Copy</option>
                                    <option value="summary">Summary</option>
                                </select>
                                :
                                <select name='actions' onChange={(e) => this.multiAction(e, props.original.orderMtId, props.original.orderLockFlg)}>
                                    <option value="select">Select</option>
                                    <option value="compQty">Comp Qty</option>
                                    <option value="orderCopy">Order Copy</option>
                                    <option value="summary">Summary</option>
                                </select>
                            }


                        </div>

                    )
                },
            },
            // {
            //     Header: 'Download',
            //     accessor: 'action',
            //     Cell: props => {
            //         return (
            //             <div>
            //                 <Link className="btn btn-outline-info fa fa-download" onClick={(e) => this.popDownloadExcel(e, props.original.orderMtId, props.original.orderLockFlg)} href="#" ></Link>
            //             </div>
            //         )
            //     },
            // },

            {
                width: 100,
                Header: 'Lock/Unlock',
                accessor: 'action2',
                Cell: props => {
                    return (
                        <div className='d-flex justify-content-center'>
                            {/* <Link className={props.original.orderLockFlg ? 'btn btn-outline-secondary fa fa-lock' : 'btn btn-outline-secondary fa fa-unlock'} onClick={(e) => this.orderLockFunc(e, props.original.orderMtId, props.original.orderLockFlg)}
                                href="#"></Link> */}
                            <Switch
                                className="react-switch"
                                onChange={(e) => this.orderLockFunc(e, props.original.orderMtId, props.original.orderLockFlg, props.original.role)}
                                checked={props.original.orderLockFlg}
                                defaultChecked={props.original.orderLockFlg}
                                disabled={props.original.status == 'Confirm' ? false : true}
                                required
                            />

                        </div>
                    )
                },
            },
            {
                width: 80,
                Header: 'Lock',
                accessor: 'orderLockStatus',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["orderLockStatus"] }),
                filterAll: true,
            },
            {

                Header: 'Booking At Studio Status',
                accessor: 'bookingAtStudio',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["bookingAtStudio"] }),
                filterAll: true,
            },

            {
                Header: 'Status',
                accessor: 'status',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["status"] }),
                filterAll: true,
            },
            // {
            //     Header: 'Send',
            //     accessor: 'action3',
            //     Cell: props => {
            //         return (
            //             <div style={{ paddingLeft: "30px" }}>
            //                 <Link className="btn btn-outline-info" onClick={(e) => this.whatsAppSendReport(e, props.original.orderMtId)} href="#">
            //                     <i class='fa fa-whatsapp'></i></Link>
            //             </div>
            //         )
            //     },
            // },
            // {
            //     Header: 'Preview',
            //     accessor: 'preview',
            //     Cell: props => {
            //         return (
            //             <div>
            //                 <Link className="btn btn-outline-info" onClick={(e) => this.previewOrderMtReport(e, props.original.orderMtId)} href="#">
            //                     Preview</Link>
            //             </div>
            //         )
            //     },
            // },
            // {
            //     Header: 'Order Copy',
            //     accessor: 'action',
            //     Cell: props => {
            //         return (
            //             <div>
            //                 <a className="btn btn-outline-secondary" onClick={(e) => this.popOrderCopy(e, props.original.orderMtId)}>
            //                     <i class="fa fa-clone" aria-hidden="true"></i>
            //                 </a>
            //             </div>
            //         )
            //     },
            // },
            // {
            //     Header: 'Summary',
            //     accessor: 'summary',
            //     Cell: props => {
            //         return (
            //             <div>
            //                 <a className="btn btn-outline-secondary" onClick={(e) => this.popSummary(e, props.original.orderMtId)}>
            //                     Summary
            //                 </a>
            //             </div>
            //         )
            //     },
            // },


        ]

        return (
            <div>
                <ToastContainer autoClose={500} draggable={true} />

                <div className="section-ptb" style={{ padding: "10px" }}>
                    <Container>
                        <Row>
                            <Col lg={12}>

                                <Row>
                                    <Col lg={2}>
                                        <div className="mb-2">
                                            <Button outline color="secondary" onClick={() => this.addOrder()}><i class="fa fa-plus"></i> New</Button>
                                        </div>
                                    </Col>
                                </Row>

                                <ReactTable className="orderList-table"
                                    data={this.state.orderList}
                                    columns={columns}
                                    filterable defaultFilterMethod={(filter, row) =>
                                        String(row[filter.id]) === filter.value}
                                    minRows={1}
                                    defaultPageSize={10}
                                    pageSizeOptions={[5, 10, 20, 25, 50, 100, 150, 250, 500]}
                                />


                                {/* modal-New Order */}
                                <Modal isOpen={this.state.modalNew} toggle={this.toggleNew} className="modal-login modal-lg modal-dialog-centered">
                                    <ModalHeader toggle={this.toggleNew} style={{ padding: '12px' }}>
                                        <h4 className="mb-0">New Order</h4>
                                    </ModalHeader>

                                    <ModalBody>
                                        <div className="success-screen">
                                            <Form onSubmit={this.handleOrderSubmit}>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="form-group col-md-6">
                                                            <div className='row'>
                                                                <div className='col-md-4'>
                                                                    <label style={{ fontWeight: "bold" }}>Order Date</label>
                                                                </div>
                                                                <div className='col-md-8'>
                                                                    {<DatePicker
                                                                        name="orderDate" id="orderDate"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        value={orderDate}
                                                                        selected={orderDate}
                                                                        onChange={this.onDateChange}
                                                                        readOnly
                                                                    />}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={'form-group col-md-6' + (submitted && !this.state.partySelectedValue ? ' has-error' : '')} >
                                                            <div className='row'>
                                                                <div className='col-md-4'>
                                                                    <label style={{ fontWeight: "bold" }}>Party <span style={{ color: "red" }}>*</span></label>
                                                                </div>
                                                                <div className='col-md-8'>
                                                                    <Select className="react-select" classNamePrefix="react-select" name="partyId"
                                                                        defaultValue="Select Party"
                                                                        options={this.state.options}
                                                                        value={this.state.partySelectedValue}
                                                                        onChange={this.getPartyCodeDt}></Select>

                                                                    {submitted && !this.state.partySelectedValue &&
                                                                        <div className="alert alert-danger" role="alert">
                                                                            This field is required!
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>


                                                        </div>

                                                        <div className="col-md-3" style={{ paddingLeft: "10px" }}>
                                                            <Button outline color="success">Generate Order</Button>&nbsp;
                                                        </div>
                                                    </div>
                                                </div>

                                            </Form>
                                        </div>
                                    </ModalBody>
                                </Modal>



                                {/* modal-view */}
                                <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-login modal-lg modal-dialog-centered">
                                    <ModalHeader toggle={this.toggle}>
                                        <h4 className="mb-0">Order Copy</h4>
                                    </ModalHeader>

                                    <ModalBody>
                                        <div className="success-screen">
                                            <div className="row">
                                                <div className="col-md-2" style={{ textAlign: "center", marginTop: "10px" }}>
                                                    <h4><strong>Party</strong> </h4>
                                                </div>
                                                <div className="col-md-6">
                                                    <Select
                                                        defaultValue="Select Party"
                                                        filterOption={createFilter(filterConfig)}
                                                        options={this.state.options}
                                                        value={this.state.value}
                                                        onChange={this.getOrdPartyCode}

                                                    ></Select>
                                                </div>
                                                <div className="col-md-2" style={{ padding: "5px" }}>
                                                    <Button style={{ paddingTop: "5px" }} outline color="primary" onClick={(e) => this.orderCopySubmit(e)}>Copy Order</Button>&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>


                                {/*Company Wise Order Qty modal-view */}
                                <Modal isOpen={this.state.modal1} toggle={this.toggle1} className="modal-login modal-lg modal-dialog-centered">
                                    <ModalHeader toggle={this.toggle1}>
                                        <h4 className="mb-0">Company Wise Order Quantity</h4>
                                    </ModalHeader>
                                    {companyWiseData !== null ?
                                        <ModalBody>
                                            <div className="success-screen">


                                                <div className="row">
                                                    <Col lg={12}>

                                                        <div className="table-responsive">

                                                            <table class="table orderhistory-table mb-0">
                                                                <thead class="thead-light">
                                                                    <tr>
                                                                        <th scope="col">Company</th>
                                                                        <th scope="col">Order Quantity</th>

                                                                    </tr>
                                                                </thead>


                                                                <tbody>
                                                                    {companyWiseData.map((Ordervalue) =>
                                                                        <tr>
                                                                            <td>{Ordervalue.companyCode}</td>
                                                                            <td>{Ordervalue.orderQty} </td>
                                                                        </tr>
                                                                    )}

                                                                </tbody>


                                                            </table>
                                                        </div>
                                                    </Col>
                                                </div>
                                            </div>
                                        </ModalBody>
                                        :
                                        null
                                    }

                                </Modal>




                                {/* modal-Downloadexcel */}
                                <Modal isOpen={this.state.modal2} toggle={this.toggle2} className="modal-login modal-lg modal-dialog-centered">
                                    <ModalHeader toggle={this.toggle2}>
                                        <h4 className="mb-0">Download</h4>
                                    </ModalHeader>

                                    <ModalBody>
                                        <div className="success-screen">
                                            <div className="row">
                                                <div className="col-md-2" style={{ textAlign: "center", marginTop: "10px" }}>
                                                    <h4><strong>Company</strong> </h4>
                                                </div>
                                                <div className="col-md-6">
                                                    <Select name="companyId"
                                                        defaultValue="Select Company"
                                                        filterOption={createFilter(filterConfig)}
                                                        options={this.state.options1}
                                                        value={this.state.value1}
                                                        onChange={this.getOrdCompany}
                                                    ></Select>
                                                </div>
                                                <div className="col-md-2" style={{ padding: "5px" }}>
                                                    <Button style={{ paddingTop: "5px" }} color="primary" onClick={(e) => this.downloadOrderExcel(e)}>Download</Button>&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>


                                {/* modal-whatsapp */}
                                <Modal isOpen={this.state.modal3} toggle={this.toggle3} className="modal-login modal-lg modal-dialog-centered">
                                    <ModalHeader toggle={this.toggle3}>
                                        <h4 className="mb-0">Send whatsapp</h4>
                                    </ModalHeader>

                                    <ModalBody>
                                        <div className="success-screen">
                                            <div className="row">
                                                <div className="col-md-2" style={{ textAlign: "center", marginTop: "10px" }}>
                                                    <h4><strong>Mobile</strong> </h4>
                                                </div>
                                                <div className="col-md-6">
                                                    <PhoneInput
                                                        country={'in'}
                                                        value={this.state.mobileno}
                                                        onChange={mobileno => this.setState({ mobileno })}
                                                    />
                                                </div>
                                                <div className="col-md-2" style={{ padding: "5px" }}>
                                                    <Button style={{ paddingTop: "5px" }} outline color="primary" onClick={(e) => this.sendWhatsappFunc(e)}>send</Button>&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>


                                {/* modal-delete */}
                                <Modal isOpen={this.state.modal4} toggle={this.toggle4} className="modal-login modal-sm modal-dialog-centered">
                                    <ModalHeader toggle={this.toggle4}>
                                        <h4 className="mb-0">Do you want delete order</h4>
                                    </ModalHeader>

                                    <ModalBody>
                                        <div className="success-screen">
                                            <div className="row">
                                                <div className="col-md-4" style={{ paddingLeft: "15px" }}>
                                                    <Button style={{ paddingTop: "5px" }} outline color="primary" onClick={(e) => this.deleteOrderMt(e)}>Yes</Button>&nbsp;
                                                </div>
                                                <div className="col-md-6" style={{ paddingLeft: "5px" }}>
                                                    <Button style={{ paddingTop: "5px" }} outline color="danger" onClick={this.toggle4}>No</Button>&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>

                                {/* Summary       */}
                                <Modal isOpen={this.state.modalSummary} toggle={this.toggleSummary} style={{ maxWidth: "1050px" }} className="modal-login modal-lg modal-dialog-centered" >
                                    <ModalHeader toggle={() => this.toggleSummary()}>
                                        <h6 className="mb-0">Sleev Wise Summary</h6>
                                    </ModalHeader>

                                    <ModalBody>
                                    <div>

{
    
   this.state.brandArray?.map((brandArr, idx) => {
        return (
            <div>

                <div>

                </div>


                {

                    this.state.sleevWiseOrderData.map((ratioObj, idx) => {
                        return (
                            <div>

                                {
                                    brandArr.brandNm == ratioObj.brand &&
                                    <div>
                                        <br />
                                        <h6>Brand : &nbsp;{ratioObj.brand}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Subbrand : &nbsp;{ratioObj.subBrandNm}
                                        </h6>

                                        {

                                            ratioObj.sleevArry.slice(0, 1).map((ratio, idx) => {
                                                return (
                                                    <div>
                                                        <Row>

                                                            <Col style={{ paddingLeft: "10px" }}>
                                                                <label> Sleev</label></Col>

                                                            <Col style={{ paddingLeft: "10px" }}>
                                                                <label>Count</label></Col>

                                                            {

                                                                ratio.sizeArry.map((sizeMap, idx) => {
                                                                    return (
                                                                        <Col style={{ paddingLeft: "10px" }}>
                                                                            <label>
                                                                                {sizeMap.size}</label></Col>

                                                                    )
                                                                })
                                                            }

                                                            <Col style={{ paddingLeft: "10px" }}>
                                                                <label> Total</label></Col>

                                                        </Row>
                                                    </div>

                                                )

                                            }
                                            )
                                        }


                                        {

                                            ratioObj.sleevArry.map((ratio, idx) => {
                                                return (
                                                    <div>
                                                        <Row>
                                                            <Col style={{ padding: "2px" }} >

                                                                <input
                                                                    value={ratio.sleev}
                                                                    type="text"
                                                                    className="form-control"
                                                                    autoComplete="off"
                                                                />
                                                            </Col>

                                                            <Col style={{ padding: "2px" }} >

                                                                <input
                                                                    value={ratio.itemCount}
                                                                    type="text"
                                                                    className="form-control"
                                                                    autoComplete="off"
                                                                />
                                                            </Col>

                                                            {

                                                                ratio.sizeArry.map((sizeMap, idx) => {
                                                                    return (
                                                                        <Col style={{ padding: "2px" }}>
                                                                            <input
                                                                                value={sizeMap.qty}
                                                                                type="text"
                                                                                className="form-control"
                                                                                autoComplete="off"
                                                                            /></Col>

                                                                    )
                                                                })
                                                            }

                                                            <Col style={{ padding: "2px" }} >

                                                                <input
                                                                    value={ratio.total}
                                                                    type="text"
                                                                    className="form-control"
                                                                    autoComplete="off"
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>


                                                )
                                            }
                                            )
                                        }


                                        <Row>&nbsp;</Row>

                                    </div>
                                }
                            </div>  //sub brand close
                        )
                    })



                }



                <div>

                    {

                        this.state.brandDataArray.map((brandDt, idx) => {
                            return (
                                <div>
                                    {
                                        brandArr.brandNm == brandDt.brand &&
                                        <div>

                                            <Row>
                                                <Col style={{ padding: "2px" }} >
                                                    <input style={{ color: '#323232', fontSize: '11px' }}
                                                        value={brandDt.sleev}
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                    />
                                                </Col>

                                                <Col style={{ padding: "2px" }} >
                                                    <input style={{ color: '#323232' }}
                                                        value={brandDt.itemCount}
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                    />
                                                </Col>

                                                {
                                                    brandDt.sizeArry.map((brandsizeMap, idx) => {
                                                        return (
                                                            <Col style={{ padding: "2px" }}>
                                                                <input style={{ color: '#323232' }}
                                                                    value={brandsizeMap.qty}
                                                                    type="text"
                                                                    className="form-control"
                                                                    autoComplete="off"
                                                                /></Col>

                                                        )
                                                    })
                                                }

                                                <Col style={{ padding: "2px" }} >
                                                    <input style={{ color: '#323232' }}
                                                        value={brandDt.total}
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                    />
                                                </Col>


                                            </Row>

                                        </div>
                                    }

                                </div>
                            )
                        })
                    }

                </div>



            </div>   //brand close

        )

    })
}
</div>


                                    </ModalBody>
                                </Modal>

                                <Modal isOpen={this.state.modalPleaseWait} toggle={this.togglePleaseWait} className="modal-login modal-dialog-centered">
                                    <ModalHeader toggle={this.togglePleaseWait}>
                                    </ModalHeader>
                                    <ModalBody>
                                        <h1 className="mb-0">Please Wait...</h1>
                                    </ModalBody>
                                </Modal>


                                <Modal isOpen={this.state.modalError} toggle={this.toggleError} className="modal-login modal-dialog-centered">
                                    <ModalHeader toggle={this.toggleError} style={{ background: "#ff5349", padding: "10px" }}>
                                        <h6 style={{ color: "white" }}>Warning</h6>
                                    </ModalHeader>
                                    <ModalBody>
                                        <h3 style={{ color: "red", textAlign: "center" }}>{this.state.errorMsg}</h3>
                                    </ModalBody>
                                </Modal>

                            </Col>
                        </Row>



                    </Container>
                </div>
            </div>
        )
    }

}
export default OrderMaster;