import axios from 'axios';
import authHeader from './auth-header';

 // const API_URL = "http://localhost:8080/api/test/";
 const API_URL = 'http://bookyoursero.com/api/test/';

const user = JSON.parse(localStorage.getItem('user'));


class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  //   React.useEffect(() => {
  //     if(sourceInit){
  //     getAxios(fetchFirstName).then(res => {
  //     setDdlFirstName(res.data.response)
  //     }).catch(errorDesc => {
  //     })        
  // }
  // }, [sourceInit])

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }


  getCartDetail() {
    return axios.get(API_URL + 'getCartDetail', { headers: authHeader() });
  }


  getRegDetail() {
    return axios.get(API_URL + 'getRegDetail', { headers: authHeader() });
  }

  getOrderHistory() {
    return axios.get(API_URL + 'getOrderHistory', { headers: authHeader() });
  }


  getShirtsData(brandCond, orderByCond, stockType) {

    const shirtsConfig = {
      headers: authHeader(),
      params: {
        productTypeCond: "shirts",
        filterCond: brandCond,
        orderByCond: orderByCond,
        stockType: stockType

      },
    }

    return axios.get(API_URL + 'productDetail', shirtsConfig);
  }

  getTrouserstData(categoryCond, orderByCond, stockType) {

    const trousersConfig = {
      headers: authHeader(),
      params: {
        productTypeCond: "trousers",
        filterCond: categoryCond,
        orderByCond: orderByCond,
        stockType: stockType

      },
    }
    return axios.get(API_URL + 'productDetail', trousersConfig);
  }


  getBoysData(filterCond, orderByCond, stockType) {

    const boysConfig = {
      headers: authHeader(),
      params: {
        productTypeCond: "boys",
        filterCond: filterCond,
        orderByCond: orderByCond,
        stockType: stockType

      },
    }
    return axios.get(API_URL + 'productDetail', boysConfig);
  }


  addToCartSave(product, sleev, productType) {

    const prodParam = {
      product: product,
      sleev: sleev,
      productType: productType
    }

    const cartParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "addToCart", JSON.stringify(prodParam), cartParam)

  }


  applyRatio(ratioObj, productType) {

    const prodParam = {
      ratioObj: ratioObj,
      productType: productType
    }

    const ratioParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "updateRatio", prodParam, ratioParam)

  }




  getUserList() {
    return axios.get(API_URL + 'getUserList', { headers: authHeader() });
  }

  generateOrder() {
    const orderParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "genarateOrder", {}, orderParam)
  }

  getRatioJson(productType) {

    const paramConfig = {
      headers: authHeader(),
      params: {
        productTypeCond: productType

      },
    }


    return axios.get(API_URL + 'getRatioJson', paramConfig);

  }

  getTrousersRatioJson(productType) {

    const paramConfig = {
      headers: authHeader(),
      params: {
        productTypeCond: productType

      },
    }

    return axios.get(API_URL + 'getTrousersRatioJson', paramConfig);

  }

  checkProductStock(productnm, size, qty, tranType) {

    const stockConfig = {
      headers: authHeader(),
      params: {
        productNm: productnm,
        size: size,
        qty: qty,
        tranType: tranType
      },
    }

    return axios.get(API_URL + 'checkProductStock', stockConfig);
  }


  previewOrderMtReport(orderMtId) {
    const orderConfig = {
      headers: authHeader(),
      params: {
        orderMtId: orderMtId
      },
    }

    return axios.get(API_URL + 'previewOrderMtReport', orderConfig);
  }

  downloadOrderReport(fileName) {

    const orderConfig = {
      headers: authHeader(),
      params: {
        fileName: fileName
      },
    }

    return axios.get(API_URL + 'downloadReport', orderConfig);
  }


  getPartyData() {
    return axios.get(API_URL + 'getPartyData', { headers: authHeader() });
  }


  getBrandData() {
    return axios.get(API_URL + 'getBrandDropdownData', { headers: authHeader() });
  }

  addParty(companyId, partyId) {
    const partyConfig = {
      headers: authHeader(),
      params: {
        companyId: companyId,
        partyId: partyId
      },
    }
    return axios.get(API_URL + 'addParty', partyConfig);
  }


  deleteCartDtItem(id) {
    const cartConfig = {
      headers: authHeader(),
      params: {
        id: id
      },
    }

    return axios.get(API_URL + 'deleteCartDtItem', cartConfig);
  }

  getOrderHistoryList(seasonId) {

    return axios.get(API_URL + 'getOrderHistoryList', { headers: authHeader(), params: { seasonId: seasonId } });
  }



  generateOrderXml(orderMtId, companyCode) {
    const orderConfig = {
      headers: authHeader(),
      params: {
        orderMtId: orderMtId,
        companyCode: companyCode
      },
    }

    return axios.get(API_URL + 'generateOrderXml', orderConfig);
  }

  orderCancel(id, cancelReason) {
    const orderCancelConfig = {
      headers: authHeader(),
      params: {
        id: id,
        cancelReason: cancelReason
      },
    }

    return axios.get(API_URL + 'orderCancel', orderCancelConfig);
  }

  getPartyAddress() {
    return axios.get(API_URL + 'getPartyAddress', { headers: authHeader() });
  }

  activeDeactiveRegister(compId, closeFlg) {
    const compConfig = {
      headers: authHeader(),
      params: {
        compId: compId,
        closeFlg: closeFlg
      },
    }

    return axios.get(API_URL + 'activeDeactiveRegister', compConfig);
  }

  deleteParty(compId, closeFlg) {
    const compConfig = {
      headers: authHeader(),
      params: {
        compId: compId
      },
    }

    return axios.get(API_URL + 'deleteParty', compConfig);
  }

  productList() {
    const config = {
      headers: authHeader()
    }

    return axios.get(API_URL + 'allProductList', config);
  }

  getSingleProductDetails(productId) {
    const productConfig = {
      headers: authHeader(),
      params: {
        productId: productId
      },
    }

    return axios.get(API_URL + 'getSingleProductDetails', productConfig);
  }

  updateImages(uploadImageData) {

    const authParam = {
      headers: authHeader()
    }

    return axios.post(API_URL + 'uploadFile', uploadImageData, authParam);
  }

  uploadExcelFile(formData) {

    const authParam = {
      headers: authHeader()
    }

    return axios.post(API_URL + 'uploadExcelFile', formData, authParam);
  }

  getFairDealProductData(brandCond, FilterCond) {

    const fairDealConfig = {
      headers: authHeader(),
      params: {
        brandCond: "fairDeal",
        filterCond: FilterCond
      },
    }

    return axios.get(API_URL + 'fairDealProductDetail', fairDealConfig);
  }


  generateFairDealOrder(productDetail) {

    const prodParam = {
      productDetail: productDetail,

    }

    const params = {
      headers: authHeader()
    }
    return axios.post(API_URL + "generateFairDealOrder", JSON.stringify(prodParam), params)

  }


  generateFairDealOrderNew(productDetail, deliveryTypeArr) {

    const prodParam = {
      productDetail: productDetail,
      deliveryTypeArr: deliveryTypeArr

    }

    const params = {
      headers: authHeader()
    }
    return axios.post(API_URL + "generateFairDealOrderNew", JSON.stringify(prodParam), params)

  }




  orderMail(orderId) {

    const orderConfig = {
      headers: authHeader(),
      params: {
        orderId: orderId
      },
    }

    return axios.get(API_URL + 'orderMail', orderConfig);
  }

  companyWiseOrderQty(orderId) {

    const orderConfig = {
      headers: authHeader(),
      params: {
        orderId: orderId
      },
    }

    return axios.get(API_URL + 'companyWiseOrderQty', orderConfig);
  }

  getPartyWiseOrderSummary() {
    const orderConfig = {
      headers: authHeader()
    }
    return axios.get(API_URL + 'getPartyWiseOrderSummary', orderConfig);
  }


  getpatternWiseStock(filterCond) {

    const orderConfig = {
      headers: authHeader(),
      params: {
        filterCond: filterCond,
      },
    }

    return axios.get(API_URL + 'getPatternWiseStockList', orderConfig);
  }


  getColorWiseStock(filterCond) {
    const orderConfig = {
      headers: authHeader(),
      params: {
        filterCond: filterCond,
      },
    }
    return axios.get(API_URL + 'getColorWiseStockList', orderConfig);
  }

  getSleevWiseStock(filterCond) {
    const orderConfig = {
      headers: authHeader(),
      params: {
        filterCond: filterCond,
      },
    }
    return axios.get(API_URL + 'getSleevWiseStockList', orderConfig);
  }


  deleteRegistration(compId) {
    const compConfig = {
      headers: authHeader(),
      params: {
        compId: compId
      },
    }

    return axios.get(API_URL + 'deleteRegistration', compConfig);
  }

  uploadProductExcelFile(formData) {

    const authParam = {
      headers: authHeader()

    }

    return axios.post(API_URL + 'uploadProductExcelFile', formData, authParam);
  }



  uploadPartyExcelFile(formData) {

    const authParam = {
      headers: authHeader()
    }

    return axios.post(API_URL + 'uploadPartyExcelFile', formData, authParam);
  }

  getCartCompanyWiseCount(shirts) {

    const shirtConfig = {
      headers: authHeader(),
      params: {
        patternType: shirts
      },
    }

    return axios.get(API_URL + 'getCartCompanyWiseCount', shirtConfig);
  }

  getCartPatternWiseCount(shirts) {

    const shirtConfig = {
      headers: authHeader(),
      params: {
        patternType: shirts
      },
    }

    return axios.get(API_URL + 'getCartPatternWiseCount', shirtConfig);
  }

  csvProductList() {

    const shirtConfig = {
      headers: authHeader(),

    }

    return axios.get(API_URL + 'csvProductList', shirtConfig);
  }

  updateTableProductList(product) {

    const prodParam = {
      product: product
    }

    const cartParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "updateTableProductList", JSON.stringify(prodParam), cartParam)

  }

  partyList() {
    const config = {
      headers: authHeader()
    }

    return axios.get(API_URL + 'allPartyList', config);
  }


  updateTablePartyList(partyData) {

    const partyParam = {
      partyData: partyData
    }

    const cartParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "updateTablePartyList", JSON.stringify(partyParam), cartParam)

  }

  saveOrder(deliveryTypeArr) {

    const compConfig = {
      headers: authHeader(),
    }

    const prodParam = {
      deliveryTypeArr: deliveryTypeArr
    }

    const cartParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "saveOrder", JSON.stringify(prodParam), compConfig)

  }


  getDispatchScheduleData(orderMtId, seasonId) {

    const config = {
      headers: authHeader(),
      params: {
        orderMtId: orderMtId,
        seasonId: seasonId
      },
    }

    return axios.get(API_URL + 'getDispatchScheduleData', config);
  }




  activeProductExcelUpload(formData) {

    const authParam = {
      headers: authHeader()
    }

    return axios.post(API_URL + 'activeProductExcelUpload', formData, authParam);
  }


  activateProductList() {
    const config = {
      headers: authHeader()
    }
    return axios.get(API_URL + 'activateProductList', config);
  }


  getProductDataList(seasonId, bookingAtStudio) {

    const compConfig = {
      headers: authHeader(),
      params: {
        seasonId: seasonId,
        bookingAtStudio: bookingAtStudio
      },
    }
    return axios.get(API_URL + 'getProductDataList', compConfig);
  }

  imageFileUpload() {
    const config = {
      headers: authHeader()
    }

    return axios.get(API_URL + 'imageFileUpload', config);

  }



  addOrder(orderData) {
    const oParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "addOrder", orderData, oParam)
  }


  addOrderEmptyJson(seasonId, bookingAtStudio) {

    const paramConfig = {
      headers: authHeader(),
      params: {
        seasonId: seasonId,
        bookingAtStudio: bookingAtStudio
      },
    }
    return axios.get(API_URL + 'addOrderEmptyJson', paramConfig);

  }


  editOrder(orderMtId) {

    const paramConfig = {
      headers: authHeader(),
      params: {
        orderMtId: orderMtId
      },
    }
    return axios.get(API_URL + 'editOrderMt', paramConfig);

  }


  addProduct(product, orderMtId, itemRatioCopyDtId, sleevVal, seasonId, selectedSizeList, sizeType) {

    const prodParam = {
      product: product,
      orderMtId: orderMtId,
      itemOrderDtId: itemRatioCopyDtId,
      sleevVal: sleevVal,
      seasonId: seasonId,
      sizeList: selectedSizeList,
      sizeType: sizeType
    }

    const oParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "addProductToOrder", JSON.stringify(prodParam), oParam)

  }


  getShortProductWiseData(product, seasonId, bookingAtStudio,orderMtId) {

    const paramConfig = {
      headers: authHeader(),
      params: {
        shortProductNm: product,
        seasonId: seasonId,
        bookingAtStudio: bookingAtStudio,
        orderMtId : orderMtId
      },
    }
    return axios.get(API_URL + 'getShortProductNmWiseData', paramConfig);

  }


  addStockProductToOrder(productList, orderMtId, seasonId) {

    const prodParam = {
      productList: productList,
      orderMtId: orderMtId,
      seasonId: seasonId,

    }

    const oParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "addStockProductToOrder", JSON.stringify(prodParam), oParam)

  }



  getOrderDtListing(orderMtId) {

    const paramConfig = {
      headers: authHeader(),
      params: {
        orderMtId: orderMtId
      },
    }
    return axios.get(API_URL + 'getOrderDtListing', paramConfig);

  }

  getSleevWiseOrderData(orderMtId) {
    const paramConfig = {
      headers: authHeader(),
      params: {
        orderMtId: orderMtId
      },
    }

    return axios.get(API_URL + 'getSleevWiseOrderData', paramConfig);
  }


  onDeleteOrderDt(orderDtId, orderMtId) {
    const paramConfig = {
      headers: authHeader(),
      params: {
        orderDtId: orderDtId,
        orderMtId: orderMtId
      },
    }

    return axios.get(API_URL + 'deleteOrderDt', paramConfig);
  }


  getOrderRatioJson(productType, orderMtId) {

    const prodParam = {
      productType: productType,
      orderMtId: orderMtId
    }
    const oParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "getOrderRatioJson", JSON.stringify(prodParam), oParam)
  }


  getOrderTrouserRatioJson(productType, orderMtId) {

    const prodParam = {
      productType: productType,
      orderMtId: orderMtId
    }
    const oParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "getOrderTrouserRatioJson", JSON.stringify(prodParam), oParam)
  }


  applyOrderRatio(ratioObj, productType, orderMtId) {

    const prodParam = {
      ratioObj: ratioObj,
      productType: productType,
      orderMtId: orderMtId
    }

    const ratioParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "updateOrderRatio", prodParam, ratioParam)

  }


  finalPlaceOrder(orderMtId, username, password, deliveryTypeArr, fieldvalue) {
    const paramConfig = {
      headers: authHeader(),
    }
    const oParam = {
      orderMtId: orderMtId,
      username: username,
      password: password,
      deliveryTypeArr: deliveryTypeArr,
      fieldvalue: fieldvalue

    }

    return axios.post(API_URL + 'finalPlaceOrder', oParam, paramConfig);
  }



  popSizeQty(qty, size, orderDtId) {
    const prodParam = {
      qty: qty,
      size: size,
      orderDtId: orderDtId
    }
    const oParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "updateSizeQty", prodParam, oParam)

  }
  whatsAppSendReport(orderMtId, mobileno) {
    const paramConfig = {
      headers: authHeader(),
      params: {
        orderMtId: orderMtId,
        mobileno: mobileno
      },
    }

    return axios.get(API_URL + 'whatsAppSendReport', paramConfig);
  }


  saveParty(partyData) {
    const oParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "saveParty", partyData, oParam)
  }

  deleteOrderMt(orderMtId) {
    const paramConfig = {
      headers: authHeader(),
      params: {
        orderMtId: orderMtId
      },
    }

    return axios.get(API_URL + 'deleteOrderMt', paramConfig);
  }


  editPartyMast(partyId) {
    const paramConfig = {
      headers: authHeader(),
      params: {
        partyId: partyId
      },
    }
    return axios.get(API_URL + 'editPartyMast', paramConfig);

  }

  deletePartyMast(partyId, statusFlg) {
    const paramConfig = {
      headers: authHeader(),
      params: {
        partyId: partyId,
        statusFlg: statusFlg
      },
    }

    return axios.get(API_URL + 'deletePartyMast', paramConfig);
  }

  orderCopy(orderMtId, partyId, seasonId, bookingAtStudio) {
    const prodParam = {
      orderMtId: orderMtId,
      partyId: partyId,
      seasonId: seasonId,
      bookingAtStudio: bookingAtStudio
    }
    const oParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "orderCopy", prodParam, oParam)

  }


  userList() {
    const config = {
      headers: authHeader()
    }

    return axios.get(API_URL + 'allUserList', config);
  }


  saveUser(userData) {
    const oParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "saveUser", userData, oParam)
  }

  editUserMast(userId) {
    const paramConfig = {
      headers: authHeader(),
      params: {
        userId: userId
      },
    }
    return axios.get(API_URL + 'editUserMast', paramConfig);

  }

  getCompanyData() {
    return axios.get(API_URL + 'getCompanyData', { headers: authHeader() });

  }

  getRoleRightList(roleMastId) {

    const compConfig = {
      headers: authHeader(),
      params: {
        roleMastId: roleMastId,
      },
    }
    return axios.get(API_URL + 'getRoleRightsList', compConfig);
  }


  getRoleMastData() {
    return axios.get(API_URL + 'getRolemastJsonList', { headers: authHeader() });

  }


  saveRoleRights(roleRightsData, roleMastId) {
    const rParam = {
      roleRightsData: roleRightsData,
      roleMastId: roleMastId

    }
    const oParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "saveRoleRights", rParam, oParam)
  }

  getPartyDetails(partyId) {
    const compConfig = {
      headers: authHeader(),
      params: {
        partyId: partyId,
      },
    }
    return axios.get(API_URL + 'getPartyDetails', compConfig);
  }

  orderLockFunc(orderMtId, orderLockFlg) {
    const paramConfig = {
      headers: authHeader(),
      params: {
        orderMtId: orderMtId,
        orderLockFlg: orderLockFlg
      },
    }

    return axios.get(API_URL + 'orderLockFunc', paramConfig);
  }

  deleteUser(userId, statusFlg) {
    const compConfig = {
      headers: authHeader(),
      params: {
        userId: userId,
        statusFlg: statusFlg
      },
    }

    return axios.get(API_URL + 'deleteUser', compConfig);
  }

  getReportDetails(menunm) {
    const orderConfig = {
      headers: authHeader(),
      params: {
        menunm: menunm
      },
    }

    return axios.get(API_URL + 'getReportDetails', orderConfig);
  }

  getProductWiseOrderQtyAndStockQty(id, state) {
    const config = {
      headers: authHeader(),
      params: {
        seasonId: id,
        allowAccessOrderFlg: state
      },
    }

    return axios.get(API_URL + 'getProductWiseOrderQtyAndStockQty', config);
  }

  deleteProductSeason(data) {
    const config = {
      headers: authHeader(),
    }

    return axios.post(API_URL + 'deleteProductSeason', data, config);
  }

  updateAllowAccessQty(id, qty) {
    const config = {
      headers: authHeader(),
      params: {
        productSeasonId: id,
        allowAccessQty: qty
      },
    }

    return axios.get(API_URL + 'updateAllowAccessQty', config);
  }


  generateReports(reportParam, reportData, opt) {
    const oParam = {
      headers: authHeader()
    }
    const rParam = {
      reportParam: reportParam,
      reportData: reportData,
      opt: opt
    }

    return axios.post(API_URL + "generateReports", rParam, oParam)
  }



  generateListingReport(reportParam, reportData, opt, bookingAtStudioFlg) {
    const oParam = {
      headers: authHeader()
    }
    const rParam = {
      reportParam: reportParam,
      reportData: reportData,
      opt: opt,
      bookingAtStudioFlg: bookingAtStudioFlg
    }

    return axios.post(API_URL + "generateListingReport", rParam, oParam)
  }


  getBrandDropdownData() {
    return axios.get(API_URL + 'getBrandDropdownData', { headers: authHeader() });
  }

  getSubBrandDropdownData() {
    return axios.get(API_URL + 'getSubBrandDropdownData', { headers: authHeader() });
  }

  getColorDropdownData() {
    return axios.get(API_URL + 'getColorDropdownData', { headers: authHeader() });
  }

  getCollarDropdownData() {
    return axios.get(API_URL + 'getCollarDropdownData', { headers: authHeader() });
  }

  getCompanyCodeDropdownData() {
    return axios.get(API_URL + 'getCompanyCodeDropdownData', { headers: authHeader() });
  }

  getFitsDropdownData() {
    return axios.get(API_URL + 'getFitsDropdownData', { headers: authHeader() });
  }

  getPatternDropdownData() {
    return axios.get(API_URL + 'getPatternDropdownData', { headers: authHeader() });
  }

  getProductGroupDropdownData() {
    return axios.get(API_URL + 'getProductGroupDropdownData', { headers: authHeader() });
  }

  getProductTypeDropdownData() {
    return axios.get(API_URL + 'getProductTypeDropdownData', { headers: authHeader() });
  }

  getQualityDropdownData() {
    return axios.get(API_URL + 'getQualityDropdownData', { headers: authHeader() });
  }

  getSleevDropdownData() {
    return axios.get(API_URL + 'getSleevDropdownData', { headers: authHeader() });
  }

  getCategoryDropdownData() {
    return axios.get(API_URL + 'getCategoryDropdownData', { headers: authHeader() });
  }

  saveProductMast(productData) {
    const oParam = {
      headers: authHeader(),
    }
    return axios.post(API_URL + "saveProductMast", productData, oParam)
  }

  editProductMast(productId) {
    const paramConfig = {
      headers: authHeader(),
      params: {
        productId: productId
      },
    }
    return axios.get(API_URL + 'editProductMast', paramConfig);

  }

  getProductDetails(productNm, seasonId) {
    const paramConfig = {
      headers: authHeader(),
      params: {
        seasonId: seasonId,
        productNm: productNm
      },
    }


    return axios.get(API_URL + "getProductDetails", paramConfig)
  }

  getOrderSizeWiseData(seasonId, brand) {
    const paramConfig = {
      headers: authHeader(),
      params: {
        seasonId,
        brand
      },
    }


    return axios.get(API_URL + "getOrderSizeWiseData", paramConfig)
  }


  addProductEmptyJson(seasonId) {
    const paramConfig = {
      headers: authHeader(),
      params: {
        seasonId: seasonId
      },
    }
    return axios.get(API_URL + 'addProductEmptyJson', paramConfig);
  }


  deleteProductMast(productId, statusFlg) {
    const paramConfig = {
      headers: authHeader(),
      params: {
        productId: productId,
        statusFlg: statusFlg
      },
    }

    return axios.get(API_URL + 'deleteProductMast', paramConfig);
  }


  seasonList() {
    const config = {
      headers: authHeader()
    }
    return axios.get(API_URL + 'allSeasonList', config);
  }

  addSeason(seasonData) {
    const oParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "saveSeason", seasonData, oParam)
  }

  editSeason(seasonId) {

    const paramConfig = {
      headers: authHeader(),
      params: {
        seasonId: seasonId
      },
    }
    return axios.get(API_URL + 'editSeason', paramConfig);

  }

  deleteSeason(seasonId) {
    const compConfig = {
      headers: authHeader(),
      params: {
        seasonId: seasonId
      },
    }

    return axios.get(API_URL + 'deleteSeason', compConfig);
  }

  activeDeactiveSeason(seasonId, activeFlg) {
    const compConfig = {
      headers: authHeader(),
      params: {
        seasonId: seasonId,
        activeFlg: activeFlg
      },
    }

    return axios.get(API_URL + 'activeDeactiveSeason', compConfig);
  }


  getSeasonUserList(seasonId) {
    const config = {
      headers: authHeader(),
      params: {
        seasonId: seasonId,
      },
    }
    return axios.get(API_URL + 'getSeasonUserList', config);
  }



  deactiveAllParty() {
    const compConfig = {
      headers: authHeader()
    }

    return axios.get(API_URL + 'deactiveAllParty', compConfig);
  }


  copyOrderBySleev(orderMtId, sleevVal, seasonId) {

    const prodParam = {
      orderMtId: orderMtId,
      sleevVal: sleevVal,
      seasonId: seasonId
    }

    const oParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "copyOrderBySleev", JSON.stringify(prodParam), oParam)

  }

  allPartyReportList() {
    const config = {
      headers: authHeader()
    }
    return axios.get(API_URL + 'allPartyReportList', config);
  }

  getPartyReportList() {
    const config = {
      headers: authHeader()
    }
    return axios.get(API_URL + 'getPartyReportList', config);
  }


  getOrderReportList(orderData) {
    const oParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "getOrderReportList", orderData, oParam)
  }

  addProductInProductSeason(seasonId) {
    const prodParam = {
      seasonId: seasonId
    }
    const oParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "addProductInProductSeason", JSON.stringify(prodParam), oParam)
  }

  deleteAllOrderDt(orderDtArr, orderMtId) {
    const prodParam = {
      orderDtArr: orderDtArr,
      orderMtId: orderMtId
    }
    const oParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "deleteAllOrderDt", JSON.stringify(prodParam), oParam)
  }

  getOrderDtWiseSummary(orderMtId) {
    const compConfig = {
      headers: authHeader(),
      params: {
        orderMtId: orderMtId
      },
    }

    return axios.get(API_URL + 'getOrderDtWiseSummary', compConfig);
  }

  getSelectedSizeList(sizes, orderDtId) {
    const compConfig = {
      headers: authHeader(),
      params: {
        sizes: sizes,
        orderDtId: orderDtId
      },
    }

    return axios.get(API_URL + 'getSelectedSizeList', compConfig);
  }



  seasonUserSave(users, seasonId) {
    const prodParam = {
      users: users,
      seasonId: seasonId
    }
    const oParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "seasonUserSave", JSON.stringify(prodParam), oParam)
  }

  bookingStudioSave(rightsData) {
    const prodParam = {
      rightsData: rightsData,
    }
    const oParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "bookingStudioSave", JSON.stringify(prodParam), oParam)
  }

  deleteSeasonUserRights(rightsId) {
    const compConfig = {
      headers: authHeader(),
      params: {
        rightsId: rightsId
      },
    }

    return axios.get(API_URL + 'deleteSeasonUserRights', compConfig);
  }


  uploadStockStatusExcelFile(formData) {
    const authParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + 'uploadStockStatusExcelFile', formData, authParam);
  }

  isValidMobileNo(mobileNo) {
    const compConfig = {
      headers: authHeader(),
      params: {
        mobileNo: mobileNo
      },
    }

    return axios.get(API_URL + 'isValidMobileNo', compConfig);
  }

  uploadJsonFile(formData) {
    const authParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + 'uploadJsonFile', formData, authParam);
  }

  getclothChildData(productNm, seasonId) {
    const orderConfig = {
      headers: authHeader(),
      params: {
        productNm: productNm,
        seasonId: seasonId
      },
    }

    return axios.get(API_URL + 'getclothChildData', orderConfig);
  }

  getClothPartyWiseChildData(productNm, seasonId) {
    const orderConfig = {
      headers: authHeader(),
      params: {
        productNm: productNm,
        seasonId: seasonId
      },
    }

    return axios.get(API_URL + 'getClothPartyWiseChildData', orderConfig);
  }


  getOrderListForXml(fromOrder, toOrder, selectRadioBtn, companyCode, seasonId) {
    const orderConfig = {
      headers: authHeader(),
      params: {
        fromOrder: fromOrder,
        toOrder: toOrder,
        status: selectRadioBtn,
        companyCode: companyCode,
        seasonId: seasonId

      },
    }

    return axios.get(API_URL + 'getOrderListForXml', orderConfig);

  }

  genarateMultiOrderXml(orderMtIds, companyCode) {
    const orderConfig = {
      headers: authHeader(),
      params: {
        orderMtIds: orderMtIds,
        companyCode: companyCode

      },
    }

    return axios.get(API_URL + 'genarateMultiOrderXml', orderConfig);
  }

  updateOrderPendingFlag(orderMtIds, companyCode) {
    const orderConfig = {
      headers: authHeader(),
      params: {
        orderMtIds: orderMtIds,
        companyCode: companyCode
      },
    }

    return axios.get(API_URL + 'updateOrderPendingFlag', orderConfig);
  }

  saveOrderParty(orderMtId, partyId) {
    const prodParam = {
      orderMtId: orderMtId,
      partyId: partyId
    }
    const oParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "saveOrderParty", JSON.stringify(prodParam), oParam)
  }

  orderLockFromReportFilter(orderArr) {
    const prodParam = {
      orderArr: orderArr
    }
    const oParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + "orderLockFromReportFilter", JSON.stringify(prodParam), oParam)
  }

  partyStockJsonFileUpload(formData) {
    const authParam = {
      headers: authHeader()
    }
    return axios.post(API_URL + 'partyStockJsonFileUpload', formData, authParam);
  }


  getOrderSubbrandList(startDate, endDate, partyCond) {
    const orderConfig = {
      headers: authHeader(),
      params: {
        startDate: startDate,
        endDate: endDate,
        partyCond: partyCond
      },
    }

    return axios.get(API_URL + 'getOrderDetailSummaryPartySubBrandWise', orderConfig);
  }


  saveOrderReadyProductFlg(orderMtId, readyProductFlg) {
    const orderConfig = {
      headers: authHeader(),
      params: {
        orderMtId: orderMtId,
        readyProductFlg: readyProductFlg
      },
    }

    return axios.get(API_URL + 'saveOrderReadyProductFlg', orderConfig);
   }


}

export default new UserService();
