import React, { Component } from 'react';
import userService from "../../services/user.services";
import { toast, ToastContainer } from 'react-toastify';
import AuthService from '../../services/auth.service';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import seasonJson from '../../api/seasonJson';
import { Button, Row } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};



class AddSeason extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fieldvalue: "",
            submitted: false,
            successful: false,
            message: "",
            fromDate: "",
            toDate: "",
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onFromDateChange = this.onFromDateChange.bind(this);
        this.onToDateChange = this.onToDateChange.bind(this);
        this.selectActiveFlg = this.selectActiveFlg.bind(this);
    }

    selectActiveFlg = (e) => {

        let fieldvalue = this.state.fieldvalue;
        fieldvalue["activeFlg"] = e.target.checked;
        this.setState({ fieldvalue });

    }

    seasonListing() {
        this.props.history.push('/seasonMast')
    }

    handleChangeEvent(field, e) {
        let fieldvalue = this.state.fieldvalue;
        fieldvalue[field] = e.target.value;
        this.setState({ fieldvalue });
    }

    componentWillMount() {
        if (parseInt(this.props.match.params.seasonId) > 0) {
            this.editSeason(parseInt(this.props.match.params.seasonId));
        } else {
            this.setState({
                fieldvalue: seasonJson["0"], fromDate: new Date(), toDate: new Date(),
            })

            setTimeout(() => {
                this.state.fieldvalue.pFromDate = this.convert(seasonJson["0"].pFromDate);
                this.state.fieldvalue.pToDate = this.convert(seasonJson["0"].pToDate);
            }, 1500);

        }
    }

    convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("/");
    }

    onFromDateChange(dateVal) {
        let fieldvalue = this.state.fieldvalue;
        fieldvalue["fromDate"] = dateVal;
        fieldvalue["pFromDate"] = this.convert(dateVal);
        this.setState({ fieldvalue, fromDate: dateVal });
    }

    onToDateChange(dateVal) {
        let fieldvalue = this.state.fieldvalue;
        fieldvalue["toDate"] = dateVal;
        fieldvalue["pToDate"] = this.convert(dateVal);
        this.setState({ fieldvalue, toDate: dateVal });
    }

    editSeason(seasonId) {
        userService.editSeason(seasonId).then(response => {
            setTimeout(() => {
                this.setState({
                    fieldvalue: response.data
                })
            }, 500);
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            submitted: true
        });

        userService.addSeason(this.state.fieldvalue).then(
            response => {
                if (response.data['status'] === 'SUCCESS') {
                    toast.success(response.data['message']);
                    this.props.history.push('/seasonMast')
                } else if (response.data['status'] === 'Error') {
                    toast.error(response.data['message']);
                }
            },

        ).catch(error => {

            if (error.response.status === 403) {
                alert('Your Login Session Is Expire ,Please Login ')
                AuthService.logout()
                this.props.history.push('/')

            } else {
                console.log('error ', error)
            }
        })


    }

    render() {

        const { submitted, fieldvalue, fromDate, toDate } = this.state;

        return (
            <div className="container">
                <ToastContainer autoClose={1500} draggable={false} />
                <div className="col-md-12 ">
                    <Row style={{ paddingTop: "10px" }}>
                        <div className="col-md-11">
                            <h3 style={{ "text-align": "left", color: "navy" }}> Season Booking Master </h3>
                        </div>
                        <div className="col-md-1">
                            <Button outline color="secondary" onClick={() => this.seasonListing()}>Listing</Button>
                        </div>
                    </Row>

                    <Form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className={'form-group col-md-6 ' + (submitted && !fieldvalue.seasonName ? ' has-error' : '')}>
                                <label style={{ fontWeight: "bold" }}>Name <span style={{ color: "red" }}>*</span> </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Season Name"
                                    value={this.state.fieldvalue.seasonName}
                                    onChange={this.handleChangeEvent.bind(this, "seasonName")}
                                    autoComplete="off"
                                    validations={[required]} />

                                {submitted && !fieldvalue.seasonName &&
                                    <div className="alert alert-danger" role="alert">
                                        This field is required!
                                    </div>
                                }
                            </div>
                        </div>

                        <div class="row">
                            <div className={'form-group col-md-6 '}>
                                <label style={{ fontWeight: "bold" }}>Between Period <span style={{ color: "red" }}>*</span> </label>
                            </div>
                        </div>

                        <div className="row">
                            <div className={'form-group col-md-3 ' + (submitted && !fieldvalue.fromDate ? ' has-error' : '')}>
                                {<DatePicker
                                    name="fromDate" id="fromDate"
                                    dateFormat="dd/MM/yyyy"
                                    value={this.state.fieldvalue.fromDate}
                                    selected={fromDate}
                                    onChange={this.onFromDateChange}

                                />}

                                {submitted && !fieldvalue.fromDate &&
                                    <div className="alert alert-danger" role="alert">
                                        This field is required!
                                    </div>
                                }
                            </div>

                            <div className={'form-group col-md-3 ' + (submitted && !fieldvalue.toDate ? ' has-error' : '')}>
                                {<DatePicker
                                    name="toDate" id="toDate"
                                    dateFormat="dd/MM/yyyy"
                                    value={this.state.fieldvalue.toDate}
                                    selected={toDate}
                                    onChange={this.onToDateChange}

                                />}

                                {submitted && !fieldvalue.toDate &&
                                    <div className="alert alert-danger" role="alert">
                                        This field is required!
                                    </div>
                                }
                            </div>

                        </div>

                        {/* <div className="row">
                            <div className={'form-group col-md-6 ' + (submitted && !fieldvalue.name ? ' has-error' : '')}>
                                <label className="d-block">Active</label>
                                <Input name="activeFlg" type="checkbox" id="activeFlg"
                                    defaultChecked={this.state.fieldvalue.activeFlg}
                                    checked={this.state.fieldvalue.activeFlg}
                                    onChange={this.selectActiveFlg.bind(this)} />

                                {submitted && !fieldvalue.activeFlg &&
                                    <div className="alert alert-danger" role="alert">
                                        This field is required!
                                    </div>
                                }
                            </div>
                        </div> */}

                        <div className="row">
                            <div className="form-group col-md-12">

                                <button className="btn btn-outline-success" >Save</button>

                            </div>
                        </div>

                    </Form>


                </div >

            </div >
        )
    }

}

export default AddSeason;
